<style></style>
<template>
	<div class="d-flex flex-column">
		<!-- <div class="d-flex flex-row divider-vertical">
			<div class="w-18"></div>
			<div class="d-flex justify-content-center align-items-center fs-18 w-82 bg-primary">{{ FormMSG(1, 'Speed planification') }}</div>
		</div> -->
		<div class="d-flex flex-row" style="background-color: #f3f4f5">
			<div class="d-flex flex-column justify-center align-items-center w-18">
				<div class="content-c1 gap-10">
					<div>
						<MonthSelect :DateDefaultSelect="DateDefaultSelect" @setDateDefaultSelect="setDateDefaultSelect" />
					</div>
					<div>
						<b-button variant="outline-primary" @click="handleClickReference">
							<span class="text-btn">{{ FormMSG(9, 'Preference') }}</span>
						</b-button>
					</div>
					<div class="d-flex flex-wrap mr-4 ml-4 active">
						<treeselect
							:options="mapCode"
							v-model="selected_code"
							:value="selected_code"
							:disable-branch-nodes="true"
							@input="handleChangeCode"
							@select="codeChanged"
						>
							<div slot="value-label" slot-scope="{ node }" :title="node.label">
								<div>{{ setTextCategoriesWithActivities(node.label) }}</div>
							</div>
							<div slot="option-label" slot-scope="{ node }">
								<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
							</div>
						</treeselect>
					</div>
				</div>
			</div>
			<div class="divider-vertical w-82">
				<div class="d-flex align-items-start p-2 flex-column w-100">
					<div v-if="isProduction" class="d-flex flex-row w-100" style="height: 40px">
						<div class="d-flex flex-wrap flex-row w-80 p-2">
							<div class="d-flex flex-wrap p-2 w-75 active">
								<treeselect
									:options="mapCategories"
									v-model="selected_categorie"
									:value="selected_categorie"
									:disable-branch-nodes="true"
									:placeholder="FormMSG(2, 'Select an categories')"
									@input="handleChangeCategories"
									@select="categoriesChanged"
								>
									<div slot="value-label" slot-scope="{ node }" :title="node.label">
										<div>{{ setTextCategoriesWithActivities(node.label) }}</div>
									</div>
									<div slot="option-label" slot-scope="{ node }">
										<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
									</div>
								</treeselect>
							</div>
							<div class="d-flex flex-wrap p-2 w-25 active">
								<treeselect
									:options="mapActivities"
									:value="selected_activity"
									:placeholder="FormMSG(3, 'Select an activities')"
									@input="handleChangeActivities"
									@select="activitiesChanged"
								>
									<div slot="option-label" slot-scope="{ node }">
										<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
									</div>
								</treeselect>
							</div>
						</div>
						<div class="d-flex align-items-start w-20 p-2">
							<div class="w-100 p-2" ref="headerFlagItem">
								<b-button
									@click="onOpenModalToAllDelete"
									:disabled="isRazSelectionProd() ? true : false"
									variant="danger"
									class="btn-custom mr-2"
									:style="`width: ${sizeButton}%`"
									size="sm"
									v-b-tooltip.hover.bottom.html="
										`${!($screen.width > 1540) ? FormMSG(4, 'RAZ selection') + ' ' + countDataToUpdateProd(dataPlanningSelect) : ''}`
									"
								>
									<component :is="getLucideIcon(ICONS.TRASH.name)" color="#FFFFFF" :size="20" />
									<span v-if="$screen.width > 1540" class="text-btn"
										>{{ FormMSG(4, 'RAZ selection') }} {{ isRazSelectionProd() ? '(00)' : countDataToUpdateProd(dataPlanningSelect) }}</span
									>
								</b-button>
							</div>
						</div>
					</div>
					<div class="d-flex flex-row w-100">
						<div class="d-flex flex-wrap flex-row w-80 p-2">
							<template v-if="flags_options_favoris.length === 0">
								<div class="d-flex justify-content-center align-items-center w-100 text-muted fs-14 p-2">
									{{ FormMSG(26, 'No favorite flags selected') }}
								</div>
							</template>
							<template v-else>
								<div class="d-flex flex-wrap p-2 gap-10" v-for="(option, index) of flags_options_favoris" :key="index">
									<flag-items :option="option" @setFlags="setFlags" />
								</div>
							</template>
						</div>
						<div class="d-flex align-items-start w-20 p-2">
							<div class="w-100 p-2">
								<b-button
									:disabled="checkUserAuthorization"
									variant="primary"
									class="btn-custom mr-2"
									:style="`width: ${sizeButton}%`"
									size="sm"
									@click="handleClickAddSelection"
									v-b-tooltip.hover.bottom.html="`${!($screen.width > 1540) ? FormMSG(5, 'Add to selection') : ''}`"
								>
									<slot name="button-label">
										<component :is="getLucideIcon(ICONS.PLUS_CIRCLE_II.name)" :color="ICONS.PLUS_CIRCLE_II.color" :size="20" />
										<span v-if="$screen.width > 1540" class="text-btn">{{ FormMSG(5, 'Add to selection') }}</span>
									</slot>
								</b-button>
							</div>
						</div>
					</div>
					<div class="d-flex flex-row w-100 mb-1">
						<div class="d-flex flex-row w-100">
							<div class="d-flex flex-row align-items-center w-60">
								<div class="ml-3">
									<b-form-checkbox
										size="lg"
										v-model="check_no_favoris"
										:value="true"
										@change="handleFilterNotFavoris"
										aria-describedby="filter-no-favoris"
										:disabled="mapOtherRubrique.length === 1"
									>
									</b-form-checkbox>
								</div>
								<div class="d-flex flex-wrap w-50 active">
									<treeselect
										:options="mapOtherRubrique"
										:value="selected_no_favoris"
										:placeholder="FormMSG(6, 'Select other rubrique')"
										:disabled="mapOtherRubrique.length === 1"
										@input="handleSelectedNoFavoris"
										:multiple="true"
									>
										<div
											slot="value-label"
											slot-scope="{ node }"
											:title="setTextValue(node.label)"
											class="d-flex align-items-center justify-content-center"
											style="height: 12px !important"
										>
											<div style="font-size: 10px !important">{{ setTextValue(node.label) }}</div>
										</div>
										<div slot="option-label" slot-scope="{ node }">
											<div class="treeselect-label" :title="setText(node.label)">{{ setText(node.label) }}</div>
										</div>
									</treeselect>
								</div>
							</div>
							<div class="d-flex flex-row align-items-start justify-content-end w-40">
								<div class="d-flex w-50">
									<div class="w-100 ml-3 mr-2">
										<b-button
											variant="success"
											class="btn-custom mr-2"
											:style="`width: ${sizeButton}%`"
											size="sm"
											v-b-tooltip.hover.bottom.html="
												`${
													!($screen.width > 1540)
														? isProduction
															? FormMSG(27, 'Validate all planning')
															: FormMSG(28, 'Send all planning')
														: ''
												}`
											"
											@click="validateAllPlanning"
										>
											<component :is="getLucideIcon(ICONS.CHECK_SQUARE.name)" color="#FFFFFF" :size="20" />
											<span v-if="$screen.width > 1540" class="text-btn">{{
												isProduction ? FormMSG(27, 'Validate all planning') : FormMSG(28, 'Send all planning')
											}}</span>
										</b-button>
									</div>
								</div>
								<div v-if="isProduction === false" class="d-flex w-50 align-items-start mr-2">
									<div class="w-100 ml-3 mr-2">
										<b-button
											@click="onOpenModalToAllDelete"
											:disabled="countDataToUpdate(dataPlanningSelect) === '(00)' ? true : false"
											variant="danger"
											size="sm"
											class="btn-custom"
											:style="`width: ${sizeButton}%`"
											v-b-tooltip.hover.bottom.html="
												`${!($screen.width > 1540) ? FormMSG(4, 'RAZ selection') + ' ' + countDataToUpdate(dataPlanningSelect) : ''}`
											"
										>
											<component :is="getLucideIcon(ICONS.TRASH.name)" color="#FFFFFF" :size="20" />
											<span v-if="$screen.width > 1540" class="text-btn"
												>{{ FormMSG(4, 'RAZ selection') }} {{ countDataToUpdate(dataPlanningSelect) }}</span
											>
										</b-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal
			v-if="openModalDeleteAll"
			v-model="openModalDeleteAll"
			@hidden="handleCloseAllDeleteModal"
			no-close-on-backdrop
			:title="FormMSG(21, 'Do you want to remove these timesheets')"
		>
			<div class="d-flex flex-column gap-1">
				<div
					v-if="groupByData.length !== 0"
					class="d-flex flex-row align-items-center w-100 border-rd p-2"
					v-for="(item, index) of groupByData"
					:key="index"
				>
					<div class="w-50">
						<label>{{ item.fullName }}</label>
					</div>
					<div class="d-flex flex-column border-lf w-50">
						<div class="text-1" v-for="(children, index) of item.data" :key="index">{{ children.date }}</div>
					</div>
				</div>
			</div>

			<template #modal-footer>
				<div class="d-flex flex-row justify-content-end w-100 gap-1 w-1">
					<div>
						<b-button variant="outline-primary" size="sm" class="border-1" @click="handleCloseAllDeleteModal"> {{ FormMSG(22, 'No') }} </b-button>
					</div>
					<div>
						<b-button variant="outline-danger" size="sm" class="border-1" @click="handleDeleteAllPlanning"> {{ FormMSG(23, 'Yes') }} </b-button>
					</div>
				</div>
			</template>
		</b-modal>
	</div>
</template>
<script>
import { store } from '@/store';
import { removeAttributeTree } from '@/shared/utils';
import FlagItems from '../planning/FlagItems.vue';
import MonthSelect from '../planning/MonthSelect.vue';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import Treeselect from '@riophae/vue-treeselect';
import { deleteTimeCardsTsDay } from '@/cruds/timesheets-efc.crud';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import moment from 'moment';
export default {
	name: 'HeaderFlag',
	components: { MonthSelect, FlagItems, Treeselect },
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		fromComponent: {
			type: String,
			required: false
		},
		dataPlanningSelect: {
			type: Array,
			required: false,
			default: () => []
		},
		dataGlobal: {
			type: Array,
			default: () => [],
			required: false
		},
		DateDefaultSelect: {
			type: Object,
			required: false,
			immediate: true
		},
		favoriteProjectFlagItems: {
			type: Array,
			required: false,
			default: () => []
		},
		isNotFavoriteProjectFlagItems: {
			type: Array,
			required: false,
			default: () => []
		},
		activities: {
			type: Array,
			required: false,
			default: () => []
		},
		categories: {
			type: Array,
			required: false,
			default: () => []
		},
		currentValidationLevel: {
			type: Number,
			required: false,
			default: () => {
				return 0;
			}
		}
	},
	data: () => {
		return {
			selected_activity: null,
			selected_categorie: null,
			selected_code: 1,
			check_no_favoris: false,
			selected_no_favoris: null,
			persist_no_favori_select: [],
			flags_options_favoris: [],
			flags_options_no_favoris: [],
			flags_selected: [],
			dataToDeleteAllPlanning: [],
			activity: null,
			category: null,
			openModalDeleteAll: false,
			payloadCategories: null,
			payloadCode: null,
			payloadActivities: null,
			fromPosition: 'FROM-HEADER'
		};
	},
	created() {},
	computed: {
		sizeButton() {
			let innerWidth = window.innerWidth;
			return innerWidth / 19;
		},
		groupByData() {
			if (this.openModalDeleteAll) {
				return this.dataToDeleteAllPlanning;
			}
		},
		isProduction() {
			return store.canViewGlobalBudget();
		},
		checkUserAuthorization() {
			if (this.isProduction) {
				return this.flags_selected.length !== 0 && this.category !== null && this.activity !== null && this.dataPlanningSelect.length !== 0
					? false
					: true;
			} else {
				return this.flags_selected.length !== 0 && this.dataPlanningSelect.length !== 0 ? false : true;
			}
		},
		mapCode() {
			let options = [
				{ id: 0, label: this.FormMSG(32, 'Per rubrique') },
				{ id: 1, label: this.FormMSG(30, 'Analytical code') }
				// { id: 2, label: this.FormMSG(31, 'Prestations') }
			];
			return options;
		},
		mapCategories() {
			this.categories = removeAttributeTree(this.categories, 'children', null);
			let options = [{ id: 0, label: this.FormMSG(7, 'Select an categories'), isDisabled: true }, ...this.categories];
			return options;
		},
		mapActivities() {
			let activities = this.activities.map((item) => ({ ...item, label: item.name + (item.short.length !== 0 ? `(${item.short})` : ``) }));
			let data = [{ id: 0, label: this.FormMSG(8, 'Select an activities'), isDisabled: true }, ...activities];
			return data;
		},
		mapOtherRubrique() {
			let otherRubrique = this.flags_options_no_favoris.map((item) => ({ ...item, label: item.short + '-' + item.name }));
			let data = [{ id: 0, label: this.FormMSG(6, 'Select other rubrique'), isDisabled: true }, ...otherRubrique];
			return data;
		}
	},
	watch: {
		favoriteProjectFlagItems: {
			handler(newVal) {
				this.flags_options_favoris = newVal.map((item) => ({
					...item,
					code: '#' + item.color,
					text: item.short.toString().toUpperCase()
				}));
				return newVal;
			},
			immediate: true,
			deep: true
		},
		isNotFavoriteProjectFlagItems: {
			handler(newVal) {
				let data = newVal.map((item) => ({
					...item,
					code: '#' + item.color,
					text: item.short.toString().toUpperCase()
				}));
				this.flags_options_no_favoris = data;
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		isRazSelectionNotProd() {
			if (this.currentValidationLevel !== 0) {
				if (this.countDataToUpdate(this.dataPlanningSelect) === '(00)') {
					return true;
				}
				return false;
			}
			return true;
		},
		validateAllPlanning() {
			this.$emit('validateAllPlanning');
		},
		isRazSelectionProd() {
			if (this.currentValidationLevel !== 0) {
				if (this.countDataToUpdateProd(this.dataPlanningSelect) === '(00)') {
					return true;
				}
				return false;
			}
			return true;
		},
		setText(text) {
			if (text !== undefined && text.length < 4) {
				return text + ' ...';
			}
			return text;
		},
		setTextValue(text) {
			if (text !== undefined) {
				if (text.length > 20) {
					return text.substring(0, 17) + ' ...';
				}
				return text.substring(0, 20);
			}
		},
		setTextCategoriesWithActivities(text) {
			if (this.payloadActivities !== null) {
				let short = this.payloadActivities.short;
				let textExact = text.toString().replace('$', short);
				return textExact;
			}
			return text;
		},
		handleCloseAllDeleteModal() {
			this.openModalDeleteAll = false;
			if (this.fromComponent === 'FROM-PLANNING') {
				this.$emit('init-persist-planning');
			}
		},
		onOpenModalToAllDelete() {
			this.openModalDeleteAll = true;
		},
		async handleDeleteAllPlanning(e) {
			this.$emit('on-active-is-loading', true);
			let arraysId = this.dataToDeleteAllPlanning
				.map((item) => item.data)
				.flat()
				.map((item) => +item.id);
			let responses = [];
			for (let id of arraysId) {
				let result = await deleteTimeCardsTsDay(id);
				responses.push(result);
			}
			if (responses.every((response) => response === true)) {
				this.createToastForMobile(this.FormMSG(25, 'Success'), this.FormMSG(24, 'Timesheets item delete successfully'), '', 'success', true);
				this.$emit('refersh-on-delete-not-submitted-all', e);
			}
			this.openModalDeleteAll = false;
			this.$emit('on-active-is-loading', false);
		},
		filterUserId(userInfo, arrays) {
			if (arrays.length !== 0) {
				return arrays
					.filter(
						(item) =>
							+item.user.id === +userInfo.userId &&
							moment(item.date).format('DD/MM/YYYY') === userInfo.date &&
							item.tsDayDetails.length !== 0 &&
							(item.validated === 0 || item.validated === 1 || item.validated === 4 || item.validated === 16)
					)
					.map((item) => ({ date: moment(item.date).format('DD/MM/YYYY'), id: item.id, user: item.user }));
			}
			return [];
		},
		filterUserIdProd(userInfo, arrays) {
			if (arrays.length !== 0) {
				return arrays
					.filter(
						(item) =>
							+item.user.id === +userInfo.userId &&
							moment(item.date).format('DD/MM/YYYY') === userInfo.date &&
							item.validated !== 0 &&
							item.validated !== 2 &&
							item.validated !== 8 &&
							item.tsDayDetails.length !== 0
					)
					.map((item) => ({ ...item, solveStatus: this.isCheckTsDetails(item.tsDayDetails) }))
					.filter((item) => item !== undefined)
					.map((item) => ({ date: moment(item.date).format('DD/MM/YYYY'), id: item.id, user: item.user }));
			}
			return [];
		},
		isCheckTsDetails(item) {
			if (item !== undefined) {
				let data = item.map((item) => {
					if (+item.activity.id === 0 && item.category === 0) {
						return true;
					}
					return false;
				});
				let response = data.some((status) => status === true);
				if (response) {
					return true;
				}
				return false;
			}
			return false;
		},
		countDataToUpdate(_data) {
			this.dataToDeleteAllPlanning = [];
			if (_data !== undefined) {
				let data = _data.map((item) => ({
					date: item.date,
					userId: item.userId,
					fullName: item.fullName,
					data: item.dataAdd !== undefined ? item.dataAdd.tsDays : []
				}));
				let globalUserIdsUnique = [...new Set(data.map((item) => ({ userId: item.userId, date: item.date })))].map((item) => ({
					date: item.date,
					userId: item.userId
				}));
				let filterDataValid = this.dataGlobal.map((item) => item.tsDays).flat();
				let res = globalUserIdsUnique
					.map((item) => ({ ...item, data: this.filterUserId(item, filterDataValid) }))
					.map((item) => ({ ...item, fullName: item.data.length !== 0 ? item.data[0].user.name + ' ' + item.data[0].user.firstName : '' }))
					.filter((item) => item.data.length !== 0);
				let result = res.reduce((accumulator, current) => {
					let existingItem = accumulator.find((item) => item.userId === current.userId && item.fullName === current.fullName);
					if (existingItem) {
						existingItem.data.push(...current.data);
					} else {
						accumulator.push({
							data: [...current.data],
							userId: current.userId,
							fullName: current.fullName
						});
					}
					return accumulator;
				}, []);
				this.dataToDeleteAllPlanning = result;
				let size = result.map((item) => item.data).flat().length;
				return '(' + size.toString().padStart('2', '0') + ')';
			}
			return '(00)';
		},
		countDataToUpdateProd(_data) {
			this.dataToDeleteAllPlanning = [];
			if (_data !== undefined) {
				let data = _data.map((item) => ({
					date: item.date,
					userId: item.userId,
					fullName: item.fullName,
					data: item.dataAdd !== undefined ? item.dataAdd.tsDays : []
				}));
				let globalUserIdsUnique = [...new Set(data.map((item) => ({ userId: item.userId, date: item.date })))].map((item) => ({
					date: item.date,
					userId: item.userId
				}));
				let filterDataValid = this.dataGlobal.map((item) => item.tsDays).flat();
				let res = globalUserIdsUnique
					.map((item) => ({ ...item, data: this.filterUserIdProd(item, filterDataValid) }))
					.map((item) => ({ ...item, fullName: item.data.length !== 0 ? item.data[0].user.name + ' ' + item.data[0].user.firstName : '' }))
					.filter((item) => item.data.length !== 0);
				let result = res.reduce((accumulator, current) => {
					let existingItem = accumulator.find((item) => item.userId === current.userId && item.fullName === current.fullName);
					if (existingItem) {
						existingItem.data.push(...current.data);
					} else {
						accumulator.push({
							data: [...current.data],
							userId: current.userId,
							fullName: current.fullName
						});
					}
					return accumulator;
				}, []);
				this.dataToDeleteAllPlanning = result;
				let size = result.map((item) => item.data).flat().length;
				return '(' + size.toString().padStart('2', '0') + ')';
			}
			return '(00)';
		},
		handleChangeActivites(value) {
			this.activity = value;
		},
		codeChanged(payload) {
			this.payloadCode = { ...payload };
		},
		categoriesChanged(payload) {
			this.payloadCategories = { ...payload };
		},
		activitiesChanged(payload) {
			this.payloadActivities = { ...payload };
		},
		handleChangeCode(value) {
			if (value !== undefined) {
				this.selected_code = value;
				this.$emit('handle-change-affichage', this.selected_code);
			} else {
				setTimeout(() => {
					this.selected_code = 1;
					this.$emit('handle-change-affichage', this.selected_code);
				}, 10);
			}
		},
		handleChangeCategories(value, item) {
			if (value !== undefined) {
				this.category = value;
			} else {
				this.category = null;
			}
		},
		handleChangeActivities(value) {
			if (value !== undefined) {
				this.activity = value;
			} else {
				this.activity = null;
				this.payloadActivities = null;
			}
		},
		setDateDefaultSelect(_date) {
			this.$emit('changeDateDefaultSelect', _date);
		},
		handleClickAddSelection() {
			let itemCategory = this.payloadCategories;
			let data = {
				dataPlanningSelect: this.dataPlanningSelect,
				flags_selected: this.flags_selected,
				activity: +this.activity === 0 ? null : this.activities.find((item) => +item.id === +this.activity),
				category: +this.category === 0 ? null : itemCategory
			};
			this.$emit('on-add-data:selection', data);
		},
		handleClickReference() {
			this.$emit('on-preference:open');
		},
		setFlags(flag) {
			if (flag.isCheck) {
				this.flags_selected = [...this.flags_selected, { ...flag }];
			} else {
				this.flags_selected = this.flags_selected.filter((_flag) => _flag.id !== flag.id);
			}
		},
		handleFilterNotFavoris(noFavoris) {
			if (noFavoris) {
				if (this.selected_no_favoris !== null) {
					let isFind = this.flags_options_no_favoris.find((item) => +item.id === +this.selected_no_favoris);
					if (isFind !== undefined) {
						this.flags_selected = [...this.flags_selected, isFind];
					}
				}
			} else {
				if (this.selected_no_favoris !== null) {
					let result = this.flags_selected.filter((_flag) => +_flag.id !== +this.selected_no_favoris);
					this.flags_selected = result;
				}
			}
		},
		handleSelectedNoFavoris(e) {
			if (e.length !== 0) {
				if (this.persist_no_favori_select.length !== 0) {
					for (let i = 0; i < e.length; i++) {
						if (this.persist_no_favori_select.length < e.length) {
							let data = this.persist_no_favori_select.find((_id) => +_id === +e[i]);
							if (data === undefined) {
								let isFind = this.flags_options_no_favoris.find((item) => +item.id === +e[i]);
								if (isFind !== undefined) {
									this.flags_selected = [...this.flags_selected, isFind];
								}
							}
						} else {
							let res = this.persist_no_favori_select
								.filter((x) => !e.includes(x))
								.concat(e.filter((x) => !this.persist_no_favori_select.includes(x)));
							for (let i = 0; i < res.length; i++) {
								this.flags_selected = this.flags_selected.filter((item) => +item.id !== +res[i]);
							}
						}
					}
					this.persist_no_favori_select = e;
				} else {
					for (let i = 0; i < e.length; i++) {
						let isFind = this.flags_options_no_favoris.find((item) => +item.id === +e[i]);
						if (isFind !== undefined) {
							this.flags_selected = [...this.flags_selected, isFind];
						}
					}
					this.persist_no_favori_select = e;
				}
			} else {
				for (let i = 0; i < this.persist_no_favori_select.length; i++) {
					this.flags_selected = this.flags_selected.filter((item) => item.id !== this.persist_no_favori_select[i]);
				}
				this.persist_no_favori_select = e;
			}
		}
	}
};
</script>
<style lang="scss">
.active {
	.vue-treeselect {
		.vue-treeselect__control {
			height: 26px !important;
			border-radius: 8px !important;
			.vue-treeselect__value-container {
				display: contents;
				.vue-treeselect__placeholder,
				.vue-treeselect__single-value {
					top: -4px;
				}
				.vue-treeselect__multi-value {
					.vue-treeselect__input-container {
						.vue-treeselect__input {
							height: 10px !important;
						}
					}
				}
			}
			.vue-treeselect__x-container {
				margin-right: 2px;
			}
		}
	}
}
</style>
<style scoped lang="scss">
.custom-option {
	display: flex !important;
	height: 60px !important;
	padding: 10px !important;
	font-size: 14px;
	align-items: center;
}
.btn-custom {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}
.text-btn {
	display: flex;
	justify-content: center;
	align-items: start;
	width: 160px;
}
.w-18 {
	width: 18%;
}
.w-82 {
	width: 82%;
}
.w-60 {
	width: 60%;
}
.w-40 {
	width: 40%;
}
.w-20 {
	width: 20%;
}
.w-80 {
	width: 80%;
}

.divider-vertical {
	border-left: 2px solid #ffffff;
}
.gap-10 {
	gap: 10px;
}
.content-c1 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}
.vue-treeselect__control {
	height: 26px !important;
}

.gap-1 {
	gap: 10px;
}
.border-1 {
	border-radius: 5px;
	width: 60px;
}
.border-rd {
	border: 2px solid #dfdfdf;
	border-radius: 3px;
}
.border-lf {
	border-left: 2px solid #dfdfdf;
	padding: 0px 10px;
}
.text-1 {
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 0.01em;
	color: rgba(6, 38, 62, 0.74);
}
</style>
