<template>
	<b-modal
		v-model="isOpen"
		:title="FormMSG(47, 'Preferences')"
		header-class="header-class-modal-doc-package"
		size="lg"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@cancel="handleCancel"
		@hidden="handleCancel"
		@show="handleShow"
		@ok.prevent="handleOk"
	>
		<b-row class="mb-4">
			<b-col class="text-center">
				<b-button-group>
					<b-button :variant="`${!fromRubric ? '' : 'outline-'}primary`" @click="toggleFromRubric">
						{{ FormMSG(27, 'User list') }}
					</b-button>
					<b-button :variant="`${fromRubric ? '' : 'outline-'}primary`" @click="toggleFromRubric">
						{{ FormMSG(28, 'Favorite rubric') }}
					</b-button>
				</b-button-group>
			</b-col>
		</b-row>
		<div style="min-height: 150px">
			<b-collapse :visible="fromRubric === false">
				<div v-if="fromRubric === false">
					<b-row class="mb-2">
						<b-col>
							<b-input-group>
								<b-form-input type="text" v-model="filter" :placeholder="FormMSG(65, 'Type to search')" @keyup.enter="handleKeyUpEnter" />
								<b-input-group-append class="cursor-pointer">
									<b-input-group-text class="btn-search">
										<component :is="getLucideIcon('Search')" color="#FFFFFF" :size="16" :stroke-width="2.5" v-if="filter.length === 0" />
										<component :is="getLucideIcon('X')" color="#FFFFFF" :size="16" :stroke-width="2.5" @click="resetFilter" v-else />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-col>
					</b-row>

					<b-row>
						<b-col id="containerPreferencesTable" ref="containerPreferencesTable">
							<b-table
								v-if="$screen.width >= 992"
								selected-variant="primary"
								hover
								selectable
								select-mode="single"
								responsive="sm"
								ref="preferencesTable"
								id="preferencesTable"
								sticky-header="500px"
								:items="dataList"
								style="text-align: left"
								:fields="tableFields"
								bordered
								striped
								small
								head-variant="dark"
								:empty-text="FormMSG(250, 'No data found')"
								show-empty
								:busy="loadingSubmit"
							>
								<template #table-busy>
									<div><b-spinner small /> {{ FormMSG(815, 'Loading...') }}</div>
								</template>
								<template #cell(showing)="{ item, index }">
									<div>
										<b-form-checkbox
											v-model="item.showing"
											switch
											size="lg"
											:value="true"
											:unchecked-value="false"
											@change="handleChangeShowing($event, item)"
										/>
									</div>
								</template>
							</b-table>
						</b-col>
					</b-row>
				</div>
			</b-collapse>

			<b-collapse :visible="fromRubric === true">
				<div v-if="fromRubric === true">
					<b-row class="mb-2">
						<b-col>
							<b-input-group>
								<b-form-input type="text" v-model="filterRubric" :placeholder="FormMSG(65, 'Type to search')" />
								<b-input-group-append class="cursor-pointer">
									<b-input-group-text class="btn-search">
										<component
											:is="getLucideIcon('Search')"
											color="#FFFFFF"
											:size="16"
											:stroke-width="2.5"
											v-if="filterRubric.length === 0"
										/>
										<component :is="getLucideIcon('X')" color="#FFFFFF" :size="16" :stroke-width="2.5" @click="filterRubric = ''" v-else />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-col>
					</b-row>

					<b-row>
						<b-col>
							<b-table
								v-if="$screen.width >= 992"
								selected-variant="primary"
								hover
								selectable
								select-mode="single"
								responsive="sm"
								ref="documentPackageTable"
								sticky-header="500px"
								:items="flagFavorites"
								style="text-align: left"
								:fields="tableFieldsRubric"
								bordered
								striped
								small
								head-variant="dark"
								:empty-text="FormMSG(250, 'No data found')"
								show-empty
								:filter="filterRubric"
							>
								<template #cell(bookmark)="{ item, index }">
									<b-button variant="custom-transparent" @click="handleClickFavorite(index)">
										<component
											:is="getLucideIcon('Star')"
											:size="20"
											color="#06263e"
											:fill="`${item.bookmark === true ? '#cf960b' : 'white'}`"
										/>
									</b-button>
								</template>
							</b-table>
						</b-col>
					</b-row>
				</div>
			</b-collapse>
		</div>

		<template #modal-footer="{ ok, cancel }">
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button size="md" variant="custom-outline-gray" style="margin-top: 5px" class="w-138-px mr-3" :disabled="loadingSubmit" @click="cancel" block>
					{{ FormMSG(43, 'Cancel') }}
				</b-button>
				<b-button size="md" variant="primary" class="w-138-px" :disabled="loadingSubmit" @click="ok" block>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-if="loadingSubmit" small />
						<div :class="`${loadingSubmit ? 'pl-2' : ''}`" style="margin-top: 1px">{{ FormMSG(42, 'Save') }}</div>
					</div>
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { getProjectUsersTsDaysPlanning } from '@/cruds/timesheets-efc.crud';
import { getProjectFlagItems } from '@/cruds/fimalac.crud';
import { mapGetters, mapActions } from 'vuex';
import { store } from '@/store';

export default {
	name: 'Preferences',

	mixins: [languageMessages, globalMixin],

	props: {
		open: { type: Boolean, default: false, required: false },
		refreshModal: { type: Boolean, default: false, required: false }
	},

	data() {
		return {
			loadingSubmit: false,
			filter: '',
			dataList: [],
			filterRubric: '',
			dataListRubric: [],
			fromRubric: false,

			recordLimit: 50,
			recordOffset: 0,
			alreadyLastData: false,

			usersShowingToStore: {
				toAdd: [],
				toRemove: []
			},

			favoritesToStore: {
				toAdd: [],
				toRemove: []
			},

			loadingTable: false
		};
	},

	watch: {
		refreshModal: {
			async handler(newVal) {
				if (newVal === true) {
					this.recordOffset = 0;
					await this.getUsersNotShowing();
					await this.getFlagFavorites();
					await this.getUsers(false, false);

					this.$emit('preferences:refresh-end');
				}
			},
			deep: true
		}
	},

	computed: {
		...mapGetters({
			usersNotShowing: 'fimalac/usersNotShowing',
			flagFavorites: 'fimalac/flagFavorites'
		}),

		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		tableFields() {
			return [
				{
					key: 'fullName',
					label: this.FormMSG(48, 'Name'),
					class: 'text-left w-40',
					sortable: true
				},
				{
					key: 'functionName',
					label: this.FormMSG(94, 'Function'),
					class: 'text-left w-40',
					formatter: (value, key, item) => {
						return `${item.departmentName} - ${item.functionName}`;
					},
					sortable: true
				},
				{
					key: 'showing',
					label: this.FormMSG(50, 'Showing'),
					class: 'text-center',
					sortable: false
				}
			];
		},
		tableFieldsRubric() {
			return [
				{
					key: 'rubric',
					label: this.FormMSG(125, 'Rubric'),
					class: 'text-left w-40',
					formatter: (value, key, item) => {
						return item.name;
					},
					sortable: true
				},
				{
					key: 'calendarOverview',
					label: this.FormMSG(127, 'Calendar overview'),
					class: 'text-center w-40',
					formatter: (value, key, item) => {
						return item.short;
					},
					sortable: true
				},
				{
					key: 'bookmark',
					label: this.FormMSG(129, 'Favorites'),
					class: 'text-center',
					sortable: false
				}
			];
		}
	},

	async created() {
		await this.getUsersNotShowing();
		await this.getFlagFavorites();
		await this.getUsers();
		// await this.getProjectFlagItems();
	},

	methods: {
		...mapActions({
			addUsersNotShowing: 'fimalac/addUsersNotShowing',
			getUsersNotShowing: 'fimalac/getUsersNotShowing',
			addFlagFavorites: 'fimalac/addFlagFavorites',
			getFlagFavorites: 'fimalac/getFlagFavorites',
			removeFlagFavorites: 'fimalac/removeFlagFavorites',
			saveUsersNotShowing: 'fimalac/saveUsersNotShowing',
			saveFlagFavorites: 'fimalac/saveFlagFavorites',
			resetUsersNotShowing: 'fimalac/resetUsersNotShowing',
			resetFlagFavorites: 'fimalac/resetFlagFavorites'
		}),

		async resetFilter() {
			const actionForLoader = async () => {
				this.recordOffset = 0;
				this.filter = '';
				this.alreadyLastData = false;
				const result = await getProjectUsersTsDaysPlanning({
					recordLimit: this.recordLimit,
					recordOffSet: this.recordOffset,
					keyWord: this.filter
				});

				this.dataList = [];
				result.map((option) => {
					this.dataList.push({
						...option,
						id: +option.id,
						showing: !this.usersNotShowing.includes(+option.id)
					});
				});
			};

			await this.showLoader(actionForLoader, 'containerPreferencesTable');
		},

		async handleKeyUpEnter() {
			const actionForLoader = async () => {
				this.recordOffset = 0;
				this.recordLimit = 50;
				this.alreadyLastData = false;
				const result = await getProjectUsersTsDaysPlanning({
					recordLimit: this.recordLimit,
					recordOffSet: this.recordOffset,
					keyWord: this.filter
				});

				this.dataList = [];
				result.map((option) => {
					this.dataList.push({
						...option,
						id: +option.id,
						showing: !this.usersNotShowing.includes(+option.id)
					});
				});
			};

			await this.showLoader(actionForLoader, 'containerPreferencesTable');
		},

		async handleShow() {
			setTimeout(() => {
				const element = document.querySelector('#containerPreferencesTable .b-table-sticky-header');

				element.addEventListener('scroll', async () => {
					const scrollTop = element.scrollTop;
					const scrollHeight = element.scrollHeight;
					const clientHeight = element.clientHeight;

					if (scrollTop + clientHeight >= scrollHeight) {
						if (this.alreadyLastData === false) {
							this.recordOffset += 1;
							await this.getUsers(true);
						}
					}
				});
			}, 1000);
		},

		handleOk() {
			this.loadingSubmit = true;
			this.saveUsersNotShowing();
			this.saveFlagFavorites();

			this.dataList = this.dataList.map((option) => {
				return {
					...option,
					showing: !this.usersNotShowing.includes(+option.id)
				};
			});
			this.emitEventClose();
			this.$emit('is-success-to-refresh');
			this.loadingSubmit = false;
		},

		handleChangeShowing(payload, user) {
			this.addUsersNotShowing({ userId: user.id, value: payload });
		},

		async getProjectFlagItems() {
			const result = await getProjectFlagItems([4]);

			this.dataListRubric = result.projectFlagItems;
		},
		async getUsers(toPush = false, executeLoader = true) {
			const actionForLoader = async () => {
				if (toPush === false) {
					const result = await getProjectUsersTsDaysPlanning({
						recordLimit: this.recordLimit,
						recordOffSet: this.recordOffset
					});

					this.dataList = [];
					result.map((option) => {
						this.dataList.push({
							...option,
							id: +option.id,
							showing: !this.usersNotShowing.includes(+option.id)
						});
					});
				} else {
					const result = await getProjectUsersTsDaysPlanning({
						recordLimit: this.recordLimit,
						recordOffSet: this.recordOffset,
						keyWord: this.filter
					});

					if (result.length > 0) {
						result.map((option) => {
							const dataToReturn = {
								...option,
								id: +option.id,
								showing: !this.usersNotShowing.includes(+option.id)
							};

							this.dataList.push(dataToReturn);
						});
					} else {
						this.alreadyLastData = true;
					}
				}
			};

			if (executeLoader === true) {
				await this.showLoader(actionForLoader, 'containerPreferencesTable');
			} else {
				await actionForLoader();
			}
		},
		handleClickFavorite(index) {
			const indexFinded = this.flagFavorites[index];
			this.flagFavorites[index].bookmark = !indexFinded.bookmark;

			this.addFlagFavorites({ flagItemId: indexFinded.id, value: this.flagFavorites[index].bookmark });
		},
		toggleFromRubric() {
			this.fromRubric = !this.fromRubric;
		},
		emitEventClose() {
			this.$emit('preferences:close');
		},
		handleCancel() {
			// this.resetUsersNotShowing();
			// this.resetFlagFavorites();
			clearTimeout();
			this.emitEventClose();
		}
	}
};
</script>
