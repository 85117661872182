<template>
	<b-modal
		v-model="isOpen"
		:title="FormMSG(41, 'Advanced edit')"
		header-class="header-class-modal-doc-package"
		size="xl"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@cancel="emitEventClose"
		@hidden="emitEventClose"
		@ok.prevent="handleSubmit"
	>
		<b-row>
			<b-col>
				<b-table
					v-if="$screen.width >= 992"
					selected-variant="primary"
					hover
					selectable
					select-mode="single"
					responsive="sm"
					ref="documentPackageTable"
					:items="dataList"
					style="text-align: left"
					:fields="tableFields"
					bordered
					striped
					small
					head-variant="dark"
					:empty-text="FormMSG(250, 'No data found')"
					show-empty
				>
					<template #cell(amount)="{ item, index }">
						<b-form-group class="mb-0">
							<b-input-group>
								<b-form-input v-model="$v.dataList.$each.$iter[index].amount.$model" :state="!$v.dataList.$each.$iter[index].amount.$error" />
								<b-input-group-append>
									<b-input-group-text>
										<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
							<div v-if="$v.dataList.$each.$iter[index].amount.$error" class="invalid-feedback">
								{{ FormMSG(81, 'Amount is required or must greater 0') }}
							</div>
						</b-form-group>
					</template>
					<template #cell(activity)="{ item, index }">
						<b-form-group class="mb-0">
							<v-select
								v-model="$v.dataList.$each.$iter[index].activity.$model"
								:options="activityOptions"
								:placeholder="FormMSG(153, 'Select a activity ...')"
								:clearable="false"
								:class="{ 'is-invalid': $v.dataList.$each.$iter[index].activity.$error }"
							>
								<template #selected-option="{ name, short }">
									<div>
										{{ `${short} - ${name}` }}
									</div>
								</template>
								<template #option="{ name, short }">
									<div>
										{{ `${short} - ${name}` }}
									</div>
								</template>
							</v-select>
							<div v-if="$v.dataList.$each.$iter[index].activity.$error" class="invalid-feedback">
								{{ FormMSG(82, 'Activity is required') }}
							</div>
						</b-form-group>
					</template>
					<template #cell(category)="{ item, index }">
						<div>
							<div class="tax-item">
								<treeselect
									v-model="$v.dataList.$each.$iter[index].category.$model"
									:multiple="false"
									:options="mapCategories"
									:default-expand-level="1"
									:disable-branch-nodes="true"
									:placeholder="FormMSG(52, 'Select a category ...')"
									open-direction="bottom"
									:clearable="false"
									:class="{ 'is-invalid': $v.dataList.$each.$iter[index].category.$error }"
								>
									<div slot="value-label" slot-scope="{ node }" :title="node.label">
										<div>{{ setTextCategoriesWithActivities(node.label, index) }}</div>
									</div>
									<div slot="option-label" slot-scope="{ node }">
										<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
									</div>
								</treeselect>
							</div>

							<div v-if="$v.dataList.$each.$iter[index].category.$error" class="invalid-feedback">
								{{ FormMSG(83, 'Category is required') }}
							</div>
						</div>
					</template>
				</b-table>
			</b-col>
		</b-row>

		<template #modal-footer="{ ok, cancel }">
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button size="md" variant="custom-outline-gray" style="margin-top: 5px" class="w-138-px mr-3" :disabled="loadingSubmit" @click="cancel" block>
					{{ FormMSG(43, 'Cancel') }}
				</b-button>
				<b-button size="md" variant="primary" class="w-138-px" :disabled="loadingSubmit" @click="ok" block>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-if="loadingSubmit" small />
						<div :class="`${loadingSubmit ? 'pl-2' : ''}`" style="margin-top: 1px">{{ FormMSG(42, 'Save') }}</div>
					</div>
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import CurrencySvg from '@/components/Svg/Currency';
import { validationMixin } from 'vuelidate';
import { required, minValue, numeric } from 'vuelidate/lib/validators';
import { getBudgetHierarchicalCategoryJson } from '@/cruds/budget.crud';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { getProjectFlagItems } from '@/cruds/fimalac.crud';
import { removeAttributeTree } from '@/shared/utils';
import globalMixin from '@/mixins/global.mixin';
import { updTsDayDetails } from '@/cruds/timesheets-efc.crud';

export default {
	name: 'AdvancedEdit',

	mixins: [languageMessages, globalMixin, validationMixin],

	props: {
		open: { type: Boolean, default: false, required: false },
		externalData: { type: Array, default: () => [], required: false },
		externalFlagItems: { type: Array, default: () => [], required: false }
	},

	components: {
		Treeselect,
		CurrencySvg
	},

	data() {
		return {
			loadingSubmit: false,
			dataList: [],

			mapCategories: [],
			activityOptions: []
		};
	},

	validations: {
		dataList: {
			$each: {
				entry: { required },
				amount: { required, minValue: minValue(1), numeric },
				activity: { required },
				category: { required }
			}
		}
	},

	watch: {
		externalFlagItems: {
			handler(newVal) {
				this.dataList = [];
				if (newVal.length > 0) {
					for (let i = 0; i < newVal.length; i++) {
						const element = newVal[i];
						this.dataList.push({
							entry: `${element.projectFlagItem.short} - ${element.projectFlagItem.name}`,
							amount: +element.amount,
							activity: null,
							category: null,
							flagId: +element.flagId,
							value: +element.value,
							flagIdOne: 0,
							valueOne: 0
						});
					}
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		tableFields() {
			return [
				{
					key: 'entry',
					label: this.FormMSG(48, 'Entry'),
					class: 'text-left w-20',
					sortable: true
				},
				{
					key: 'amount',
					label: this.FormMSG(49, 'Amount'),
					class: 'text-left w-20',
					sortable: true
				},
				{
					key: 'category',
					label: this.FormMSG(51, 'Category'),
					class: 'text-left w-30',
					sortable: false
				},
				{
					key: 'activity',
					label: this.FormMSG(50, 'Activity'),
					class: 'text-left w-30',
					sortable: false
				}
			];
		}
	},

	async created() {
		await this.getCategories();
		await this.getActivityFlagItems();
	},

	methods: {
		setTextCategoriesWithActivities(text, index) {
			if (this.dataList[index].activity !== null) {
				let short = this.dataList[index].activity.short;
				let textExact = text.toString().replace('$', short);
				return textExact;
			}
			return text;
		},
		async getActivityFlagItems() {
			const result = await getProjectFlagItems([1]);

			this.activityOptions = result.projectFlagItems;
		},
		async getCategories() {
			this.mapCategories = [];
			await getBudgetHierarchicalCategoryJson(-2, true, null, -1, false).then((result) => {
				this.mapCategories = removeAttributeTree(result, 'children', null);
			});
		},
		async handleSubmit() {
			this.$v.dataList.$touch();
			this.loadingSubmit = true;

			if (this.$v.$invalid) {
				this.loadingSubmit = false;
			} else {
				const input = this.prepareInputDataToSend();

				console.log({ INPUT: input });

				await updTsDayDetails(input);
				this.loadingSubmit = false;
				this.createToastForMobile(this.FormMSG(85, 'Success'), this.FormMSG(86, 'Update successfully!'));
				this.emitEventSubmit();
				this.emitEventClose();
			}
		},
		prepareInputDataToSend() {
			let result = [];
			for (let i = 0; i < this.dataList.length; i++) {
				const option = this.dataList[i];
				for (let j = 0; j < this.externalData.length; j++) {
					const element = this.externalData[j];
					result.push({
						tsDayId: +element.tsDay.id,
						flagIdOne: +option.activity.flagId,
						valueOne: +option.activity.value,
						flagId: +option.flagId,
						value: +option.value,
						amount: +option.amount,
						category: +option.category
					});
				}
			}

			return result;
		},
		emitEventSubmit() {
			this.$emit('advanced-edit:submited');
		},
		emitEventClose() {
			this.$emit('advanced-edit:close');
		}
	}
};
</script>

<style scoped></style>
