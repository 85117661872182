<template>
	<b-modal
		v-model="isOpen"
		:title="FormMSG(173, 'Manage users\'s roles')"
		header-class="header-class-modal-doc-package"
		size="lg"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@ok.prevent="handleOk"
		@show="handleShow"
		@cancel="emitEventClose"
		@hidden="emitEventClose"
	>
		<div style="min-height: 150px">
			<b-row class="mb-2">
				<b-col>
					<b-input-group>
						<b-form-input type="text" v-model="filter" :placeholder="FormMSG(65, 'Type to search')" @keyup.enter="handleKeyUpEnter" />
						<b-input-group-append class="cursor-pointer">
							<b-input-group-text class="btn-search">
								<component :is="getLucideIcon('Search')" color="#FFFFFF" :size="16" :stroke-width="2.5" v-if="filter.length === 0" />
								<component :is="getLucideIcon('X')" color="#FFFFFF" :size="16" :stroke-width="2.5" @click="resetFilter" v-else />
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-col>
			</b-row>

			<b-row>
				<b-col id="containerManageUsersRoles" ref="containerManageUsersRoles">
					<b-table
						v-if="$screen.width >= 992"
						selected-variant="primary"
						hover
						selectable
						select-mode="single"
						responsive="sm"
						ref="preferencesTable"
						id="preferencesTable"
						sticky-header="500px"
						:items="dataList"
						style="text-align: left"
						:fields="tableFields"
						bordered
						striped
						small
						head-variant="dark"
						:empty-text="FormMSG(250, 'No data found')"
						show-empty
					>
						<template #cell(action)="{ item, index }">
							<div>
								<b-form-select v-model="item.role" :options="rolesOptions" @change="handleChangeAction($event, item, index)" />
							</div>
						</template>
					</b-table>
				</b-col>
			</b-row>
		</div>

		<template #modal-footer="{ ok, cancel }">
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button size="md" variant="custom-outline-gray" style="margin-top: 5px" class="w-138-px mr-3" :disabled="loadingSubmit" @click="cancel" block>
					{{ FormMSG(43, 'Cancel') }}
				</b-button>
				<b-button
					size="md"
					variant="primary"
					class="w-138-px"
					:disabled="loadingSubmit || (userSelected.length === 0 && rolesToRemove.length === 0)"
					@click="ok"
					block
				>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-if="loadingSubmit" small />
						<div :class="`${loadingSubmit ? 'pl-2' : ''}`" style="margin-top: 1px">{{ FormMSG(42, 'Save') }}</div>
					</div>
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { getUsersForManageRole } from '@/cruds/users.crud';
import { updateUserEfcRole } from '@/cruds/timesheets-efc.crud';

export default {
	name: 'ManageUsersRoles',

	props: {
		open: { type: Boolean, default: false, required: false },
		refreshModal: { type: Boolean, default: false, required: false }
	},

	mixins: [languageMessages, globalMixin],

	data() {
		return {
			loadingSubmit: false,

			dataList: [],
			filter: '',

			userSelected: [],
			rolesToRemove: [],

			alreadyLastData: false,
			recordLimit: 20,
			recordOffset: 0,

			choiceRoleToShow: null
		};
	},

	watch: {
		refreshModal: {
			async handler(newVal) {
				if (newVal === true) {
					await this.getUsers();

					this.$emit('manage-users-roles:refresh-end');
				}
			},
			deep: true
		}
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		rolesOptions() {
			return [
				{ value: 0, text: this.FormMSG(615, 'Aucun rôle') },

				{ value: 1, text: this.FormMSG(614, 'Régisseur') }
				// { value: 2, text: this.FormMSG(613, 'Chargée de production') }
			];
		},
		tableFields() {
			return [
				{
					key: 'fullName',
					label: this.FormMSG(48, 'Name'),
					formatter: (value, key, item) => {
						return `${item.name} ${item.firstName}`;
					},
					class: 'text-left w-140-px',
					sortable: true
				},
				{
					key: 'functionName',
					label: this.FormMSG(94, 'Function'),
					class: 'text-left w-40',
					formatter: (value, key, item) => {
						return `${item.departmentName} - ${item.functionName}`;
					},
					sortable: true
				},
				{
					key: 'action',
					label: '',
					class: 'text-center w-300-px',
					sortable: true
				}
			];
		}
	},

	async created() {
		await this.getUsers();
	},

	methods: {
		handleChangeAction(payload, item, index) {
			this.deleteUserSelected(item);
			if (payload === 2) {
				if (item.oldRole === 1) {
					this.rolesToRemove.push({
						userId: item.id,
						oldRole: item.oldRole
					});
				}
				this.userSelected.push({
					userId: item.id,
					isProd: true,
					isRegissor: false
				});
			} else if (payload === 1) {
				if (item.oldRole === 2) {
					this.rolesToRemove.push({
						userId: item.id,
						oldRole: item.oldRole
					});
				}
				this.userSelected.push({
					userId: item.id,
					isProd: false,
					isRegissor: true
				});
			} else if (payload === 0) {
				if (item.oldRole === 1 || item.oldRole === 2) {
					this.rolesToRemove.push({
						userId: item.id,
						oldRole: item.oldRole
					});
				}
			}
		},
		deleteUserSelected(item, payload) {
			const findIndex = this.userSelected.findIndex((option) => +option.userId === +item.id);
			if (findIndex > -1) {
				this.userSelected.splice(findIndex, 1);
			}
		},
		async resetFilter() {
			const actionForLoader = async () => {
				this.recordOffset = 0;
				this.filter = '';
				this.alreadyLastData = false;
				const result = await getUsersForManageRole(this.recordLimit, this.recordOffset, this.filter);

				this.userSelected = [];
				this.rolesToRemove = [];
				this.dataList = [];
				result.map((option) => {
					this.dataList.push({
						...option,
						id: +option.id,
						oldRole: option.role
					});
				});
			};

			await this.showLoader(actionForLoader, 'containerManageUsersRoles');
		},
		async handleKeyUpEnter() {
			const actionForLoader = async () => {
				this.recordOffset = 0;
				this.recordLimit = 50;
				this.alreadyLastData = false;
				const result = await getUsersForManageRole(this.recordLimit, this.recordOffset, this.filter);

				this.userSelected = [];
				this.rolesToRemove = [];
				this.dataList = [];
				result.map((option) => {
					this.dataList.push({
						...option,
						id: +option.id,
						oldRole: option.role
					});
				});
			};

			await this.showLoader(actionForLoader, 'containerManageUsersRoles');
		},
		async handleShow() {
			setTimeout(() => {
				const element = document.querySelector('#containerManageUsersRoles .b-table-sticky-header');

				element.addEventListener('scroll', async () => {
					const scrollTop = element.scrollTop;
					const scrollHeight = element.scrollHeight;
					const clientHeight = element.clientHeight;

					if (scrollTop + clientHeight >= scrollHeight) {
						if (this.alreadyLastData === false) {
							this.recordOffset += 1;
							await this.getUsers(true);
						}
					}
				});
			}, 1000);
		},
		async getUsers(toPush = false, executeLoader = true) {
			const actionForLoader = async () => {
				if (toPush === false) {
					const result = await getUsersForManageRole(this.recordLimit, this.recordOffset, this.filter);

					this.userSelected = [];
					this.rolesToRemove = [];
					this.dataList = [];
					result.map((option) => {
						this.dataList.push({
							...option,
							id: +option.id,
							oldRole: option.role
						});
					});
				} else {
					const result = await getUsersForManageRole(this.recordLimit, this.recordOffset, this.filter);

					if (result.length > 0) {
						result.map((option) => {
							const dataToReturn = {
								...option,
								id: +option.id,
								oldRole: option.role
							};

							this.dataList.push(dataToReturn);
						});
					} else {
						this.alreadyLastData = true;
					}
				}
			};

			if (executeLoader === true) {
				await this.showLoader(actionForLoader, 'containerManageUsersRoles');
			} else {
				await actionForLoader();
			}
		},
		async handleOk() {
			this.loadingSubmit = true;
			const resultPrepareData = this.prepareDataToSend();

			if (resultPrepareData.deleteValue.length > 0) {
				await updateUserEfcRole(resultPrepareData.deleteValue, false, false);
			}

			if (resultPrepareData.prod.length > 0) {
				await updateUserEfcRole(resultPrepareData.prod, false, true);
			}

			if (resultPrepareData.regissor.length > 0) {
				await updateUserEfcRole(resultPrepareData.regissor, true, false);
			}

			this.createToastForMobile(this.FormMSG(847, 'Success'), this.FormMSG(848, 'Role change successfully'));

			this.loadingSubmit = false;
			this.emitEventClose();
		},
		prepareDataToSend(type = 'prod') {
			let result = {
				prod: [],
				regissor: [],
				deleteValue: []
			};

			this.userSelected.map((option) => {
				if (option.isProd === true) {
					result.prod.push(option.userId);
				} else if (option.isRegissor === true) {
					result.regissor.push(option.userId);
				}
			});

			this.rolesToRemove.map((option) => {
				result.deleteValue.push(option.userId);
			});

			return result;
		},
		emitEventClose() {
			this.userSelected = [];
			this.rolesToRemove = [];
			this.alreadyLastData = false;
			this.recordLimit = 20;
			this.recordOffset = 0;
			this.getUsers(false, false);

			this.$emit('manage-users-roles:close');
		}
	}
};
</script>
