<style scoped>
.planning {
	cursor: pointer;
}
.gap-1 {
	gap: 10px;
}
</style>
<template>
	<errorContainer :error="error" :warning="warning">
		<custom-loading-planning :loading="isLoading" :label="FormMSG(2, 'Prepare data of fimalac')" />
		<transition name="slide">
			<div class="form">
				<b-row class="animated fadeIn">
					<b-col cols="12" xl="12">
						<b-card no-body>
							<div class="p-4">
								<b-row>
									<b-col>
										<b-alert :show="showTooltipsHelpPlanning" variant="info" dismissible>
											<div class="d-flex mb-2">
												<component :is="getLucideIcon('Info')" />
												<div class="ml-2 fw-700 fs-16">{{ FormMSG(145, 'Planning tool') }}:</div>
											</div>
											<div class="ml-2">
												{{
													FormMSG(
														164,
														'1- By default the tool shows all users of the project, but you can always filtered them by selecting a departement.'
													)
												}}
											</div>
											<div class="ml-2">
												{{ FormMSG(165, '2- To add a new planning(s) (i.e: To activate the button "Add to selection"):') }}
											</div>
											<div v-if="isProduction" class="ml-3">
												{{ FormMSG(166, '- You must select one category, and one activity') }}
											</div>
											<div class="ml-3">
												{{ FormMSG(167, '- You must check one or more PER DIEM &raquo flag(s)') }}
											</div>
											<div class="ml-3">
												{{
													FormMSG(
														168,
														"- And the most important, selecting the planning of user(s) in the table (exactly as Excel fonctionnality's)"
													)
												}}
											</div>
										</b-alert>
									</b-col>
								</b-row>
								<b-row class="mb-2">
									<b-col cols="8">
										<div class="pb-2 mr-1">
											<div class="d-flex flex-row" style="gap: 10px">
												<div class="d-flex flex-row justify-content-start align-items-center gp-5">
													<div class="status-not-submitted"></div>
													<div class="col-form-label">{{ FormMSG(18, 'Not submitted') }}</div>
												</div>
												<div class="d-flex flex-row justify-content-start align-items-center gp-5">
													<div class="status-info"></div>
													<div>{{ FormMSG(13, 'Submitted') }}</div>
												</div>
												<div class="d-flex flex-row justify-content-start align-items-center gp-5">
													<div class="status-pending"></div>
													<div class="col-form-label">{{ FormMSG(19, 'Validated HOD') }}</div>
												</div>
												<div class="d-flex flex-row justify-content-start align-items-center gp-5">
													<div class="status-validated"></div>
													<div class="col-form-label">{{ FormMSG(15, 'Validated') }}</div>
												</div>
												<div class="d-flex flex-row justify-content-start align-items-center gp-5">
													<div class="status-refused"></div>
													<div class="col-form-label">{{ FormMSG(16, 'Refused') }}</div>
												</div>
												<div v-if="isProduction" class="d-flex flex-row justify-content-start align-items-center gp-5">
													<div class="solve-status"></div>
													<div class="col-form-label">{{ FormMSG(20, 'Line to solved') }}</div>
												</div>
											</div>
										</div>
									</b-col>
									<b-col cols="4" class="d-flex flex-row align-items-center justify-content-end">
										<b-button variant="success" class="float-right" @click="showManageUsersRolesDialog = true">
											<component :is="getLucideIcon('UserCog')" :size="20" /> {{ FormMSG(417, "Manage user's roles") }}
										</b-button>
									</b-col>
								</b-row>
								<div>
									<header-planning-fimalac
										@month:Selected="handleSelectedFormFilter"
										:id="timecards_One"
										:set-filter="(item) => setFilter(item)"
										:filter="filter"
										@day:Selected="handleSelectedDay"
										:onSearch="onSearch"
										:selectDate="allDataTimeCards"
										:formChanged="(date, idTable) => formChanged(date, idTable)"
										:formChangedPlanningView="(date, idTable) => formChangedPlanningView(date, idTable)"
										@handleSelectDepartementChange="handleSelectDepartementChange"
										@handleSelectFunctionChange="handleSelectFunctionChange"
										:dataPlanningSelect="dataPlanningSelect"
										:show-modal="showModal"
										:isNavigateInitDate="isNavigateInitDate"
										@setIsNavigateInitDate="setIsNavigateInitDate"
										@resetDataAfterAddUpdate="resetDataAfterAddUpdate"
										@refersh-on-delete-not-submitted-all="handleRefreshData"
										:dataGlobal="TimeCards"
									/>
								</div>
								<div>
									<HeaderFlag
										ref="headerFlag"
										:dataPlanningSelect="dataPlanningSelect"
										:DateDefaultSelect="DateDefaultSelect"
										@changeDateDefaultSelect="changeDateDefaultSelect"
										@on-add-data:selection="handleOnAddDataSelection"
										@on-preference:open="handleOpenPrefrence"
										:favoriteProjectFlagItems="flagFavoritesBookmark"
										:isNotFavoriteProjectFlagItems="flagFavoritesNotBookmark"
										@refersh-on-delete-not-submitted-all="handleRefreshData"
										@close-planning-modal-delete-all="hideModal"
										@on-active-is-loading="onActiveIsLoading"
										@init-persist-planning="handleInitPlanningSelect"
										@validateAllPlanning="validateAllPlanning"
										:currentValidationLevel="currentValidationLevel"
										:fromComponent="fromComponent"
										:activities="activities"
										:categories="categories"
										:dataGlobal="TimeCards"
										@handle-change-affichage="onHandleChangeAffichage"
									>
										<template #button-label>
											<b-spinner v-if="loadingAddTsDay" small />
											<component :is="getLucideIcon(ICONS.PLUS_CIRCLE_II.name)" :color="ICONS.PLUS_CIRCLE_II.color" :size="20" />
											<span v-if="$screen.width > 1540" class="text-btn">{{ FormMSG(156, 'Add to selection') }}</span>
										</template>
									</HeaderFlag>
								</div>
								<div class="divider-horizontal"></div>
								<div class="planning">
									<planning-view-tables
										:id="timecards_One"
										:selectDate="allDataTimesheets"
										:hover="true"
										:currentpage="currentPage"
										:perpage="perPage"
										:hv="hv"
										:tsfields="fieldsTimeCardsPlanning"
										:timesheets="TimeCards"
										@setDataSizeModalPlanning="setDataSizeModalPlanning"
										@sendDataModalPlanning="sendDataModalPlanning"
										@showModalPlanningContextUpdate="showModalPlanningContextUpdate"
										@showModalAdvancedEdition="handleShowModalAdvancedEdition"
										:DateDefaultSelect="DateDefaultSelect"
										@changeDateDefaultSelect="changeDateDefaultSelect"
										@getDataFromPagePlanning="getDataFromPagePlanning"
										@validateTsDays="validateTsDays"
										@validateAllTsDays="validateAllTsDays"
										@deleteItemPlanning="deleteItemPlanning"
										@replacement="replacement"
										@handleRefreshReject="handleRefreshReject"
										@sendForValidationPlanning="sendForValidationPlanning"
										@filter-custom-user="handleCustomFilter"
										@on-open-modal-new-user="handleAddNewUser"
										:open="showAdvancedEditModal"
										:isPersistDataPlanning="isPersistDataPlanning"
										:initCustomFilter="initCustomFilter"
										@reset-init-custom-filter="resetInitCustomFilter"
										:hideContextMenuPlanning="hideContextMenuPlanning"
										@hideContextMenuPlanningInit="handleHideContextMenuPlanning"
										:initPlanningSelect="initPlanningSelect"
										@restore-init-planning-select="handleRestoredinitPlanningSelect"
										@setCurrentValidationLevel="setCurrentValidationLevel"
										@planning-view-tables:edit-location="handlePlanningViewTablesEditLocation"
										:isAnalyTicalCode="isAnalyTicalCode"
										:isPrestations="isPrestations"
										:isPerDiem="isPerDiem"
									/>
								</div>
								<div class="d-flex flex-row w-100 gap-1 p-4">
									<advanced-edit
										:open="showAdvancedEditModal"
										:external-data="listToEdit"
										:external-flag-items="listFlagItemsToEdit"
										@advanced-edit:close="handleAdvancedEditClose"
										@advanced-edit:submited="handleAdvancedEditSubmited"
									/>
									<quick-addition
										:open="showQuickAdditionModal"
										:external-data="dataToAdd"
										@quick-addition:close="handleQuickAdditionClose"
										@quick-addition:submitted="handleQuickAdditionSubmitted"
									/>
									<substitution
										:open="showSubstitutionModal"
										:external-data="editData"
										@substitution:close="handleSubstitutionClose"
										@substitution:submitted="handleSubstitutionSubmitted"
									/>
									<user-advanced-edit
										:open="showUserAdvancedEditModal"
										:external-data="editData"
										:is-regissor="isProduction === false"
										@user-advanced-edit:submitted="handleUserAdvancedEditSubmitted"
										@user-advanced-edit:close="handleUserAdvancedEditClose"
									/>
									<preferences
										:open="showPreferencesModal"
										:refresh-modal="refreshModalPreferences"
										@preferences:close="handlePreferencesClose"
										@preferences:refresh-end="handlePreferencesRefreshEnd"
										@is-success-to-refresh="handleRefreshData"
									/>
									<custom-filter
										:open="openCustomFilter"
										:refresh-modal="refreshModalCustomFilter"
										@custom-filter:success="handleRefreshCustomFilter"
										@custom-filter:close="handleCustomFilterClose"
										@custom-filter:refresh-end="handleCustomFilterRefreshEnd"
									/>

									<invite-new-user
										:open="openModalNewUser"
										@invite-new-user:users-added="handleInviteNewUserUsersAdded"
										@invite-new-user:close="handleInviteNewUserClose"
									/>

									<manage-users-roles
										:open="showManageUsersRolesDialog"
										:refresh-modal="refreshModalManageUsers"
										@manage-users-roles:close="handleManageUsersRolesClose"
										@manage-users-roles:refresh-end="handleManageUsersRolesRefreshEnd"
									/>
									<incomplete
										:open="successModalIncomplete"
										:data="incompleteData"
										@modal-incomplete:close="handleIncompleted"
										:date="DateDefaultSelect"
									/>

									<edit-location
										:open="showModalEditLocation"
										:data-for-edit="dataForEditLocation"
										@edit-location:close="handleEditLocationClose"
										@edit-location:add-success="handleEditLocationAddSuccess"
									/>
								</div>
							</div>
						</b-card>
					</b-col>
				</b-row>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import { store } from '@/store';
import HeaderPlanningFimalac from '@/modules/fimalac/components/HeaderFimalac/HeaderPlanningFimalac';
import { getBudgetHierarchicalCategoryJson } from '@/cruds/budget.crud';
import HeaderFlag from '@/modules/fimalac/components/HeaderFimalac/HeaderFlag';
import CustomLoadingPlanning from '@/components/Plannings/Filter/CustomLoadingPlanning';
import PlanningViewTables from '@/modules/fimalac/components/planning/PlanningViewTables.vue';
import AdvancedEdit from '@/modules/fimalac/components/Modal/AdvancedEdit.vue';
import QuickAddition from '@/modules/fimalac/components/Modal/QuickAddition.vue';
import Substitution from '@/modules/fimalac/components/Modal/Substitution.vue';
import UserAdvancedEdit from '@/modules/fimalac/components/Modal/UserAdvancedEdit.vue';
import Preferences from '@/modules/fimalac/components/Modal/Preferences.vue';
import { NAME_CARDS_TIMESHEET_EFC, ACTION_EFC_TIMESHEET } from '@/utils/utils';
import { GetEncodingValidation } from '@/cruds/users.crud';
import { getDepartments } from '@/cruds/department.crud';
import { rendCurrency } from '~helpers';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { getProjectUsersTsDaysPlanning, validateTsDays, deleteTimeCardsTsDay } from '@/cruds/timesheets-efc.crud';
import { getProjectLocationList, getProjectFlagItems } from '@/cruds/fimalac.crud';
import moment from 'moment';
import _ from 'lodash';
import { getUsersAndFlagsValue } from '@/cruds/flag.crud';
import { addTsDaysUsersForPlanning } from '@/cruds/timesheet.crud';
import { submitSelectedTimesheet } from '@/cruds/timesheet.crud';
import { mapGetters, mapActions } from 'vuex';
import CustomFilter from '@/modules/fimalac/components/Modal/CustomFilter.vue';
import InviteNewUser from '@/modules/fimalac/components/Modal/InviteNewUser.vue';
import ManageUsersRoles from '@/modules/fimalac/components/Modal/ManageUsersRoles.vue';
import Incomplete from '@/modules/fimalac/components/Modal/Incomplete.vue';
import EditLocation from '@/modules/fimalac/components/Modal/EditLocation.vue';

function preparePropsToSend(newList, flag_items, category, activity, flag_in_db, result = []) {
	if (newList.length === 0) {
		return result;
	}

	let checkIfExistInResult = false;

	if (result.length > 0) {
		result.map((option) => {
			if (option.userId === newList[0].userId) {
				checkIfExistInResult = true;
				option.dates.push(newList[0].date);
			}
			return {
				...option
			};
		});
	}

	if (checkIfExistInResult === false) {
		for (let i = 0; i < flag_items.length; i++) {
			const userFinded = _.find(flag_in_db, (option) => +option.id === +newList[0].userId);
			const amountFinded = _.find(userFinded.projectFlagItem, (option) => +option.id === +flag_items[i].id);

			result.push({
				userId: newList[0].userId,
				fullName: newList[0].fullName,
				departmentName: newList[0].dataAdd.departmentName,
				functionName: newList[0].dataAdd.functionName,
				amount: amountFinded.amount,
				category,
				activity,
				flagItem: {
					...flag_items[i],
					amount: amountFinded.amount
				},
				dates: [newList[0].date]
			});
		}
	}

	newList.splice(0, 1);
	return preparePropsToSend(newList, flag_items, category, activity, flag_in_db, result);
}

function preparePropsToSendForEdit(dateSelected, arrayData, userInfo, iteration = 0, result = {}) {
	if (iteration === arrayData.length) {
		return result;
	}

	if (dateSelected === moment(new Date(arrayData[iteration].date)).format('DD/MM/YYYY')) {
		result = _.assign(result, {
			data: {
				userInfo,
				date: dateSelected,
				tsDay: arrayData[iteration]
			},
			flagItems: arrayData[iteration].tsDayDetails
		});

		return result;
	}

	return preparePropsToSendForEdit(dateSelected, arrayData, userInfo, iteration + 1, result);
}

function prepareListFlagItemsForEdit(globalList, newList, iterationNewList = 0) {
	if (iterationNewList === newList.length) {
		return globalList;
	}

	if (globalList.findIndex((option) => +option.projectFlagItem.id === +newList[iterationNewList].projectFlagItem.id) === -1) {
		globalList.push(newList[iterationNewList]);
	}

	return prepareListFlagItemsForEdit(globalList, newList, iterationNewList + 1);
}

export default {
	name: 'Fimalac',

	components: {
		UserAdvancedEdit,
		AdvancedEdit,
		QuickAddition,
		Substitution,
		Preferences,
		PlanningViewTables,
		CustomLoadingPlanning,
		HeaderFlag,
		HeaderPlanningFimalac,
		CustomFilter,
		InviteNewUser,
		ManageUsersRoles,
		Incomplete,
		EditLocation
	},
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	data() {
		const { timecards_One } = NAME_CARDS_TIMESHEET_EFC;
		const { addData, updateData, deleteData } = ACTION_EFC_TIMESHEET;
		return {
			error: {},
			warning: '',
			showAdvancedEditModal: false,
			showQuickAdditionModal: false,
			showSubstitutionModal: false,
			showUserAdvancedEditModal: false,
			addData,
			updateData,
			deleteData,
			timecards_One,
			allDataTimesheets: [],
			currentPage: 1,
			perPage: 0,
			hv: 'dark',
			DateDefaultSelect: {
				value: moment(Date.now()).format('MM/YYYY'),
				text: moment(Date.now()).format('MMMM YYYY')
			},
			headerTabPlanning: [],
			rendCurrency,
			recordOffSet: 0,
			recordLimit: 20,
			recordLimitPerDate: 10,
			recordOffSetPerDate: 0,
			allDepartment: [],
			TimeCards: [],
			allDataTimeCards: [],
			departmentAutorization: [],
			isPersistDataPlanning: false,
			valueToSearch: null,
			isLoading: false,
			departmentPersist: {
				id: null,
				value: null,
				message: null
			},
			functionPersist: {
				id: null,
				value: null,
				message: null
			},
			useFormInModal: true,
			dataPlanningSelect: [],
			planningToSend: [],
			editData: {},
			isActionCrud: '',
			isRefresh: false,
			Open: false,
			isNavigateInitDate: false,
			filter: '',
			showPreferencesModal: false,
			dataToAdd: [],
			favoriteProjectFlagItems: [],
			isNotFavoriteProjectFlagItems: [],
			activities: [],
			categories: [],

			listToEdit: [],
			listFlagItemsToEdit: [],
			loadingAddTsDay: false,
			openDeleteModalPlanningSelect: false,
			showTooltipsHelpPlanning: false,
			orderByName: false,
			orderByFunction: false,
			openCustomFilter: false,
			openModalNewUser: false,
			initCustomFilter: false,
			hideContextMenuPlanning: false,
			refreshModalPreferences: false,
			initPlanningSelect: false,
			fromComponent: '',
			currentValidationLevel: 0,
			showManageUsersRolesDialog: false,
			customFilterValue: 0,

			refreshModalManageUsers: false,
			refreshModalCustomFilter: false,
			successModalIncomplete: false,
			incompleteData: [],
			isPerDiem: false,
			isAnalyTicalCode: true,
			isPrestations: false,
			dataForEditLocation: {},
			showModalEditLocation: false
		};
	},
	computed: {
		...mapGetters({
			flagFavoritesBookmark: 'fimalac/flagFavoritesBookmark',
			flagFavoritesNotBookmark: 'fimalac/flagFavoritesNotBookmark',
			usersNotShowing: 'fimalac/usersNotShowing',
			functionOrdersFiltered: 'fimalac/functionOrdersFiltered'
		}),

		canManageCrewList() {
			return store.canManageCrewList();
		},

		isProduction() {
			return store.canViewGlobalBudget();
		},
		fieldsTimeCardsPlanning() {
			return this.headerTabPlanning;
		},
		rendConfirmModalTxt() {
			return {
				title: 'Please Confirm',
				okTitle: 'YES',
				cancelTitle: 'NO'
			};
		}
	},
	async created() {
		await this.getFunctionOrders();
		this.getActivities();
		this.getCustomCategories();
		this.reloadData();
		this.toggleShowTooltipsForPlanningHelp();
		this.getFlagFavorites();
	},
	methods: {
		...mapActions({
			getFlagFavorites: 'fimalac/getFlagFavorites',
			getUsersNotShowing: 'fimalac/getUsersNotShowing',
			getFunctionOrders: 'fimalac/getFunctionOrders'
		}),
		onHandleChangeAffichage(value) {
			if (value === 1) {
				this.isAnalyTicalCode = true;
				this.isPrestations = false;
				this.isPerDiem = false;
			}
			if (value === 2) {
				this.isAnalyTicalCode = false;
				this.isPrestations = true;
				this.isPerDiem = false;
			}
			if (value === 0) {
				this.isAnalyTicalCode = false;
				this.isPrestations = false;
				this.isPerDiem = true;
			}
		},
		handleIncompleted() {
			this.successModalIncomplete = false;
			this.incompleteData = [];
		},
		async handleRefresHeader() {
			const langCurrent = this.currentLang;
			const startDate = '01/' + this.DateDefaultSelect.value;
			let dateHeader = [...(await this.getLocationList(startDate))].map((item) => {
				let key = moment(item.date).format('DD/MM/YYYY');
				let formattedDate = moment(item.date).locale(`${langCurrent}`).format('DD ddd');
				return {
					id: item.id,
					key: key,
					label: formattedDate,
					name: item.setName,
					locations: item,
					sortable: true
				};
			});
			this.headerTabPlanning = [
				{
					key: 'crew_member',
					label: 'Member of team',
					sortable: true
				},
				...dateHeader
			];
		},
		handleEditLocationAddSuccess() {
			this.handleRefresHeader();
			// this.handleRefreshData();
		},

		handleEditLocationClose() {
			this.dataForEditLocation = {};
			this.showModalEditLocation = false;
		},

		handlePlanningViewTablesEditLocation(payload) {
			this.dataForEditLocation = payload;
			this.showModalEditLocation = true;
		},

		handleCustomFilterRefreshEnd() {
			this.refreshModalCustomFilter = false;
		},

		handleManageUsersRolesRefreshEnd() {
			this.refreshModalManageUsers = false;
		},
		setCurrentValidationLevel(value) {
			this.currentValidationLevel = value;
		},

		handleManageUsersRolesClose() {
			this.showManageUsersRolesDialog = false;
		},

		async handlePreferencesRefreshEnd() {
			this.refreshModalPreferences = false;
		},

		async handleInviteNewUserUsersAdded() {
			await this.handleRefreshReject();

			this.refreshModalPreferences = true;
			this.refreshModalManageUsers = true;
			this.refreshModalCustomFilter = true;
		},

		handleInviteNewUserClose() {
			this.openModalNewUser = false;
		},

		handleCustomFilterClose() {
			this.openCustomFilter = false;
		},

		toggleShowTooltipsForPlanningHelp() {
			if (this.$cookies.isKey('ck_suggest_planning_fimalac')) {
				this.showTooltipsHelpPlanning = false;
			} else {
				this.$cookies.set('ck_suggest_planning_fimalac', true, '5d');
				this.showTooltipsHelpPlanning = true;
			}
		},
		onActiveIsLoading(e) {
			this.isLoading = e;
			this.hideContextMenuPlanning = true;
		},
		handleHideContextMenuPlanning(e) {
			this.hideContextMenuPlanning = e;
		},
		handleRefreshReject() {
			this.handleRefreshData();
			this.hideContextMenuPlanning = true;
		},
		handleOpenPrefrence() {
			this.showPreferencesModal = true;
		},
		async handleAdvancedEditSubmited() {
			this.listToEdit = [];
			this.listFlagItemsToEdit = [];

			await this.handleRefreshData();
		},
		async handleSubstitutionSubmitted() {
			await this.handleRefreshData();
			this.hideContextMenuPlanning = true;
		},
		replacement(dataPlanning) {
			let dataToEdit = {
				...dataPlanning[0].data,
				location: {
					...dataPlanning[0].locations
				},
				tsDay: {
					...dataPlanning[0].data.tsDays[0],
					user: {
						id: dataPlanning[0].userId
					}
				}
			};

			delete dataToEdit.tsDays;
			this.editData = dataToEdit;
			this.showSubstitutionModal = true;
		},
		async handleShowModalAdvancedEdition(itemPlanning) {
			let preparePropsToSendForEdit = null;
			this.listToEdit = [];
			this.listFlagItemsToEdit = [];

			for (let i = 0; i < itemPlanning.length; i++) {
				const element = itemPlanning[i];
				const userInfo = {
					userId: element.userId,
					fullName: element.fullName,
					location: element.locations
				};
				if (element.tsDay !== undefined) {
					preparePropsToSendForEdit = {
						data: {
							userInfo,
							date: element.date,
							tsDay: element.tsDay
						},
						flagItems: element.tsDay.tsDayDetails
					};
				}

				// const resultPreparePropsToSendForEdit = preparePropsToSendForEdit(element.date, element.dataAdd.tsDays, userInfo);

				if (preparePropsToSendForEdit.flagItems && preparePropsToSendForEdit.flagItems.length > 0) {
					this.listFlagItemsToEdit = prepareListFlagItemsForEdit(this.listFlagItemsToEdit, preparePropsToSendForEdit.flagItems);
				}

				if (itemPlanning.length === 1) {
					const flagInDb = await this.getUsersAndFlagsValue([{ userId: element.userId }], this.listFlagItemsToEdit, true);

					this.listFlagItemsToEdit = this.listFlagItemsToEdit.map((flagItemToEdit) => {
						const userFinded = _.find(flagInDb, (option) => +option.id === +element.userId);

						const amountFinded = _.find(userFinded.projectFlagItem, (option) => +option.id === +flagItemToEdit.projectFlagItem.id);

						return {
							...flagItemToEdit,
							amount: amountFinded.amount,
							projectFlagItem: {
								...flagItemToEdit.projectFlagItem,
								amount: amountFinded.amount
							}
						};
					});
				}

				this.listToEdit.push(preparePropsToSendForEdit.data);
			}
			this.isActionCrud = this.addData;
			this.showAdvancedEditModal = true;
		},
		handleInitPlanningSelect() {
			this.initPlanningSelect = true;
			this.fromComponent = '';
		},
		handleRestoredinitPlanningSelect() {
			this.initPlanningSelect = false;
		},
		async handleQuickAdditionSubmitted() {
			await this.handleRefreshData();
		},
		async handleOnAddDataSelection({ dataPlanningSelect, flags_selected, category, activity }) {
			const datasPlanning = [..._.cloneDeep(dataPlanningSelect)].filter((item) => item.tsDay === undefined);
			const flagInDb = await this.getUsersAndFlagsValue(datasPlanning, flags_selected);

			this.dataToAdd = await preparePropsToSend(datasPlanning, flags_selected, category, activity, flagInDb);

			if (this.isProduction) {
				this.showQuickAdditionModal = true;
			} else {
				this.loadingAddTsDay = true;
				const resultPrepareDataToSend = this.prepareDataToAddByManager();
				this.dataPlanningSelect = [];
				await addTsDaysUsersForPlanning(resultPrepareDataToSend)
					.then(async (result) => {
						this.createToastForMobile(this.FormMSG(81, 'Success'), this.FormMSG(82, 'Data added successfully!'));

						await this.handleRefreshData();
						this.loadingAddTsDay = false;
					})
					.catch((error) => {
						this.loadingAddTsDay = false;
						console.log({ 'ADD SELECTION ERROR': error });
					});
			}
		},
		prepareDataToAddByManager() {
			let dataToSend = [];
			for (let i = 0; i < this.dataToAdd.length; i++) {
				const option = this.dataToAdd[i];
				for (let j = 0; j < option.dates.length; j++) {
					const element = option.dates[j];
					const findIndex = dataToSend.findIndex((dT) => {
						return +dT.userId === +option.userId && dT.date === element.split('/').reverse().join('-');
					});
					if (findIndex === -1) {
						dataToSend = _.concat(dataToSend, {
							userId: +option.userId,
							date: element.split('/').reverse().join('-'),
							allowances: [
								{
									flagId: +option.flagItem.flagId,
									value: +option.flagItem.value,
									paycode: +option.flagItem.paycode
								}
							]
						});
					} else {
						dataToSend[findIndex].allowances.push({
							flagId: +option.flagItem.flagId,
							value: +option.flagItem.value,
							paycode: +option.flagItem.paycode
						});
					}
				}
			}

			return dataToSend;
		},
		async getUsersAndFlagsValue(dataPlanningSelect, flags_selected, findInParent = false) {
			let usersId = [];
			dataPlanningSelect.map((option) => {
				usersId.push(+option.userId);
			});

			let flagsId = [];
			flags_selected.map((option) => {
				if (findInParent === true) {
					flagsId.push(+option.projectFlagItem.id);
				} else {
					flagsId.push(+option.id);
				}
			});
			return await getUsersAndFlagsValue(usersId, flagsId);
		},
		async handleUserAdvancedEditSubmitted() {
			await this.handleRefreshData();
		},
		async getCustomCategories() {
			this.categories = [];
			let result = await getBudgetHierarchicalCategoryJson(-2, true, null, -1, false);
			this.categories = result;
		},
		async getActivities() {
			let type = [1];
			let sortByBookMark = false;
			let flagItems = await getProjectFlagItems(type, sortByBookMark);
			this.activities = flagItems.projectFlagItems;
		},
		async handleQuickAdditionSubmited() {
			await this.handleRefreshData();
		},
		handleAddNewUser(e) {
			this.openModalNewUser = e;
		},
		handlePreferencesClose() {
			this.showPreferencesModal = false;
		},
		handleUserAdvancedEditClose() {
			this.showUserAdvancedEditModal = false;
			this.hideContextMenuPlanning = true;
		},
		handleClickShowSubstitutionModal() {
			this.showSubstitutionModal = true;
		},
		handleSubstitutionClose() {
			this.showSubstitutionModal = false;
		},
		handleQuickAdditionClose() {
			this.showQuickAdditionModal = false;
		},
		handleAdvancedEditClose() {
			this.showAdvancedEditModal = false;
			this.hideContextMenuPlanning = true;
		},
		handleClickShowEdiModal() {
			this.isActionCrud = this.addData;
			this.showAdvancedEditModal = true;
		},
		async reloadData() {
			let result = await GetEncodingValidation(store.getCurrentProjectConfig().id, store.userID());
			if (result !== undefined) {
				this.departmentAutorization = result;
			}
			this.allDepartment = await getDepartments(false, false);
			await this.getDataInit();
		},
		resetDataAfterAddUpdate() {
			this.dataPlanningSelect = [];
			this.planningToSend = [];
		},
		setFilterName(name, value) {
			let obj = {};
			obj[name] = value;
			return { ...obj, projectId: +store.projectID(), activateCrewAccessRestriction: true };
		},
		async getProjectUsersTsDaysPlanningDataInitial() {
			let usersNotShowing = this.usersNotShowing;
			let Filter = {
				...this.setFilterName('startDate', moment(`01/${this.DateDefaultSelect.value}`).format('YYYY-DD-MM') + 'T00:00:00Z'),
				recordLimit: this.recordLimit,
				recordOffSet: this.recordOffSet,
				department: this.departmentPersist.value,
				userFunction: this.functionPersist.value,
				keyWord: this.valueToSearch,
				orderByName: this.orderByName,
				orderByFunction: this.orderByFunction
			};
			delete Filter.activateCrewAccessRestriction;
			let FunctionValueDep = [];
			if (this.customFilterValue === 3) {
				FunctionValueDep = this.functionOrdersFiltered;
			} else {
				FunctionValueDep = [];
			}
			let data = [...(await getProjectUsersTsDaysPlanning(Filter, FunctionValueDep))].map((item) => ({ ...item, isSelected: false }));
			let result = data;
			for (let i = 0; i < usersNotShowing.length; i++) {
				let data = result;
				let response = data.filter((item) => +item.id !== +usersNotShowing[i]);
				result = response;
			}
			return result;
		},
		async getLocationList(startDate) {
			let locationStartDate = moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD') + 'T00:00:00Z';
			let locationList = await getProjectLocationList(locationStartDate);
			return locationList;
		},
		resetInitCustomFilter(bool, value) {
			this.initCustomFilter = bool;
			this.customFilterValue = value;
		},
		handleRefreshCustomFilter() {
			this.customFilterValue = 3;
			this.formChangedPlanningView(this.DateDefaultSelect, this.timecards_One);
		},
		handleCustomFilter(value) {
			if (value === 1) {
				this.orderByName = true;
				this.orderByFunction = false;
				this.customFilterValue = 1;
				return this.formChangedPlanningView(this.DateDefaultSelect, this.timecards_One);
			}
			if (value === 2) {
				this.orderByName = false;
				this.orderByFunction = true;
				this.customFilterValue = 2;
				return this.formChangedPlanningView(this.DateDefaultSelect, this.timecards_One);
			}
			if (value === 3) {
				this.openCustomFilter = true;
			} else {
				this.orderByName = false;
				this.orderByFunction = false;
				this.customFilterValue = 0;
				return this.formChangedPlanningView(this.DateDefaultSelect, this.timecards_One);
			}
		},
		async getDataInit() {
			this.isLoading = true;
			let data = [...(await this.getProjectUsersTsDaysPlanningDataInitial())];
			const langCurrent = this.currentLang;
			const startDate = '01/' + this.DateDefaultSelect.value;
			let dateHeader = [...(await this.getLocationList(startDate))].map((item) => {
				let key = moment(item.date).format('DD/MM/YYYY');
				let formattedDate = moment(item.date).locale(`${langCurrent}`).format('DD ddd');
				return {
					id: item.id,
					key: key,
					label: formattedDate,
					name: item.setName,
					locations: item,
					sortable: true
				};
			});
			this.headerTabPlanning = [
				{
					key: 'crew_member',
					label: 'Member of team',
					sortable: true
				},
				...dateHeader
			];
			this.TimeCards = data;
			this.allDataTimeCards = data;
			this.isLoading = false;
		},
		async formChanged(_date, idTable) {
			if (this.isRefresh === false) {
				if (idTable === this.timecards_One) {
					let DATA = this.allDataTimeCards;
					if (_date.value !== 0) {
						this.miniSpinnerLoadingTimeCards = true;
						this.TimeCards = await this.getProjectUserTsDaysDataInitial();
						this.miniSpinnerLoadingTimeCards = false;
					}
				}
			} else {
				let resultTimeCardsDATA = await this.getProjectUserTsDaysDataInitial();
				this.allDataTimeCards = resultTimeCardsDATA;
				let DATA = this.allDataTimeCards;
				this.TimeCards = DATA.filter((item) => {
					if (moment(item.date).format('MM/YYYY').includes(_date.value)) {
						return true;
					}
					return false;
				});
				this.isRefresh = false;
			}
		},
		handleSelectedFormFilter(data) {
			this.recordLimitPerDate = 10;
			this.recordOffSetPerDate = 0;
			this.DateDefaultSelect = data.e;
			this.formChanged(data.e, data.id);
		},
		setDataSizeModalPlanning(dataPlanning) {
			this.dataPlanningSelect = dataPlanning;
			this.planningToSend = dataPlanning;
			if (this.editData !== null) {
				this.isActionCrud = this.updateData;
			} else {
				this.isActionCrud = this.addData;
			}
		},
		sendDataModalPlanning(dataPlanning) {
			this.dataPlanningSelect = dataPlanning;
			this.planningToSend = dataPlanning;
			if (this.editData !== null) {
				this.isActionCrud = this.updateData;
			} else {
				this.isActionCrud = this.addData;
			}
		},
		changeDateDefaultSelect(item) {
			this.dataPlanningSelect = [];
			this.planningToSend = [];
			if (this.departmentPersist.value === null && this.functionPersist.value === null) {
				this.DateDefaultSelect = item;
				this.formChangedPlanningView(this.DateDefaultSelect, this.timecards_One);
			} else {
				this.DateDefaultSelect = item;
				this.formChangedPlanningView(this.DateDefaultSelect, this.timecards_One);
				setTimeout(() => {
					this.handleSelectDepartementChange(this.departmentPersist);
				}, 1000);
			}
		},
		showModalPlanningContextUpdate(dataPlanning) {
			if (dataPlanning.length !== 0) {
				this.isActionCrud = this.updateData;
				this.dataPlanningSelect = dataPlanning;
				this.planningToSend = dataPlanning;
				let dataToEdit = {
					...dataPlanning[0].data,
					location: {
						...dataPlanning[0].locations
					},
					tsDay: {
						...dataPlanning[0].data.tsDays[0],
						user: {
							id: dataPlanning[0].userId
						}
					}
				};
				delete dataToEdit.tsDays;
				this.editData = dataToEdit;

				// this.Open = true;
				this.showUserAdvancedEditModal = true;
			} else {
				this.dataPlanningSelect = dataPlanning;
				this.planningToSend = dataPlanning;
				this.editData = {};
				this.Open = false;
			}
		},
		async sendForValidationPlanning(item) {
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(238, 'Please confirm that you want to send all unsent timesheets to the validation'), {
					title: this.rendConfirmModalTxt.title,
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'primary',
					okTitle: this.rendConfirmModalTxt.okTitle,
					cancelTitle: this.rendConfirmModalTxt.cancelTitle,
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (value) => {
					if (value) {
						let arrays = [];
						this.isLoading = true;
						for (let i = 0; i < item.length; i++) {
							let id = +item[i].id;
							let result = await submitSelectedTimesheet(id);
							arrays.push(result);
						}
						if (arrays.every((item) => item === true)) {
							let strTitle = this.FormMSG(9, 'Success');
							this.createToastForMobile(
								strTitle,
								this.FormMSG(12, 'All timesheets have been submitted for validation send'),
								'',
								'success',
								true
							);
							this.handleRefreshData();
							this.hideContextMenuPlanning = true;
						} else {
							let strTitle = this.FormMSG(239, 'Warning');
							this.createToastForMobile(
								strTitle,
								this.FormMSG(240, 'Check the timesheet(s) because there is an timesheets that has not been to sent for validation'),
								'',
								'success',
								true
							);
							this.handleRefreshData();
						}
						this.isLoading = false;
					}
				})
				.catch((err) => console.log(err));
		},
		validateAllPlanning() {
			let dateValue = '01/' + this.DateDefaultSelect.value;
			let date = moment(dateValue, 'DD/MM/YYYY').format('YYYY-MM-DD') + 'T00:00:00';
			let isProd = this.FormMSG(243, 'valid');
			let regiss = this.FormMSG(244, 'send');
			let message = this.isProduction
				? `${this.FormMSG(241, 'Please confirm that you want to')} ${isProd} ${this.FormMSG(242, 'all the timesheet in planning')}`
				: `${this.FormMSG(241, 'Please confirm that you want to')} ${regiss} ${this.FormMSG(242, 'all the timesheet in planning')}`;
			this.$bvModal
				.msgBoxConfirm(message, {
					title: this.rendConfirmModalTxt.title,
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'primary',
					okTitle: this.rendConfirmModalTxt.okTitle,
					cancelTitle: this.rendConfirmModalTxt.cancelTitle,
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (value) => {
					if (value) {
						let result = await validateTsDays(null, date);
						this.incompleteData = result;
						if (this.incompleteData.length !== 0) {
							this.successModal = false;
							this.successModalIncomplete = true;
						} else {
							let strTitle = this.FormMSG(9, 'Success');
							let message = this.isProduction
								? this.FormMSG(245, 'All timesheets have been validated')
								: this.FormMSG(246, 'All timesheets have been submitted for validation send');
							this.createToastForMobile(strTitle, message, '', 'success', true);
							this.successModalIncomplete = false;
						}
						this.handleRefreshData();
						this.hideContextMenuPlanning = true;
					}
				})
				.catch((err) => console.log(err));
		},
		async validateTsDays(item) {
			let id = +item[0].id;
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(238, 'Please confirm that you want to valid this timesheets'), {
					title: this.rendConfirmModalTxt.title,
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'primary',
					okTitle: this.rendConfirmModalTxt.okTitle,
					cancelTitle: this.rendConfirmModalTxt.cancelTitle,
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (value) => {
					if (value) {
						let result = await validateTsDays(id, null);
						if (result !== null) {
							this.successModal = true;
							this.handleRefreshData();
							this.hideContextMenuPlanning = true;
						}
					}
				})
				.catch((err) => console.log(err));
		},
		async validateAllTsDays(item) {
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(238, 'Please confirm that you want to valid this timesheets'), {
					title: this.rendConfirmModalTxt.title,
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'primary',
					okTitle: this.rendConfirmModalTxt.okTitle,
					cancelTitle: this.rendConfirmModalTxt.cancelTitle,
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (value) => {
					if (value) {
						let result = [];
						for (let i = 0; i < item.length; i++) {
							let res = await validateTsDays(item[i]);
							result.push(res);
						}
						this.successModal = true;
						this.handleRefreshData();
						this.hideContextMenuPlanning = true;
					}
				})
				.catch((err) => console.log(err));
		},
		async formChangedPlanningView(_date, idTable) {
			this.isLoading = true;
			const langCurrent = this.currentLang;
			const startDate = '01/' + this.DateDefaultSelect.value;
			let dateHeader = [...(await this.getLocationList(startDate))].map((item) => {
				let key = moment(item.date).format('DD/MM/YYYY');
				let formattedDate = moment(item.date).locale(`${langCurrent}`).format('DD ddd');
				return {
					id: item.id,
					key: key,
					label: formattedDate,
					name: item.setName,
					locations: item,
					sortable: true
				};
			});
			this.headerTabPlanning = [
				{
					key: 'crew_member',
					label: 'Crew member',
					sortable: true
				},
				...dateHeader
			];
			this.DateDefaultSelect = _date;
			let arrays = [];
			for (let i = 0; i <= this.recordOffSet; i++) {
				let Filter = {
					...this.setFilterName('startDate', moment(`01/${this.DateDefaultSelect.value}`).format('YYYY-DD-MM') + 'T00:00:00Z'),
					recordLimit: this.recordLimit,
					recordOffSet: i,
					department: this.departmentPersist.value,
					userFunction: this.functionPersist.value,
					keyWord: this.valueToSearch,
					orderByName: this.orderByName,
					orderByFunction: this.orderByFunction
				};
				let FunctionValueDep = [];
				if (this.customFilterValue === 3) {
					FunctionValueDep = this.functionOrdersFiltered;
				} else {
					FunctionValueDep = [];
				}
				let result = [...(await getProjectUsersTsDaysPlanning(Filter, FunctionValueDep))].map((item) => ({ ...item, isSelected: false }));
				arrays.push(result);
			}
			let data = arrays.flat(Infinity);
			let usersNotShowing = this.usersNotShowing;
			let result = data;
			for (let i = 0; i < usersNotShowing.length; i++) {
				let data = result;
				let response = data.filter((item) => +item.id !== +usersNotShowing[i]);
				result = response;
			}
			this.allDataTimeCards = result;
			this.TimeCards = result;
			this.isLoading = false;
		},
		setIsNavigateInitDate(boolean) {
			this.isNavigateInitDate = boolean;
		},
		showModal() {
			this.isActionCrud = this.addData;
			this.Open = true;
		},
		hideModal() {
			this.Open = false;
			this.dataPlanningSelect = [];
			this.planningToSend = [];
			this.editData = {};
			this.isActionCrud = '';
			this.isPersistDataPlanning = false;
			this.openDeleteModalPlanningSelect = false;
		},
		async handleRefreshData() {
			let arrays = [];
			for (let i = 0; i <= this.recordOffSet; i++) {
				let Filter = {
					...this.setFilterName('startDate', moment(`01/${this.DateDefaultSelect.value}`).format('YYYY-DD-MM') + 'T00:00:00Z'),
					recordLimit: this.recordLimit,
					recordOffSet: i,
					department: this.departmentPersist.value,
					userFunction: this.functionPersist.value,
					keyWord: this.valueToSearch,
					orderByName: this.orderByName,
					orderByFunction: this.orderByFunction
				};
				let FunctionValueDep = [];
				if (this.customFilterValue === 3) {
					FunctionValueDep = this.functionOrdersFiltered;
				} else {
					FunctionValueDep = [];
				}
				let result = [...(await getProjectUsersTsDaysPlanning(Filter, FunctionValueDep))].map((item) => ({ ...item, isSelected: false }));
				arrays.push(result);
			}
			let data = arrays.flat(Infinity);
			let usersNotShowing = this.usersNotShowing;
			let result = data;
			for (let i = 0; i < usersNotShowing.length; i++) {
				let response = result.filter((item) => +item.id !== +usersNotShowing[i]);
				result = response;
			}
			this.TimeCards = result;
			this.hideModal();
		},
		async getDataFromPagePlanning(recordOffSet) {
			this.recordOffSet += recordOffSet;
			let data = [...(await this.getProjectUsersTsDaysPlanningDataInitial())];
			let nextDataTimeCards = [...this.TimeCards, ...data];
			let nextDataAllTimeCards = [...this.allDataTimeCards, ...data];
			this.TimeCards = nextDataTimeCards;
			this.allDataTimeCards = nextDataAllTimeCards;
		},
		deleteItemPlanning(item) {
			if (this.dataPlanningSelect.length > 1) {
				this.$refs['headerFlag'].onOpenModalToAllDelete();
				this.fromComponent = 'FROM-PLANNING';
				return true;
			} else {
				let _id = +item[0].tsDays[0].id;
				let txt = 'Please confirm that you want delete this timesheet';
				let result = this.$bvModal
					.msgBoxConfirm(txt, {
						title: txt,
						size: 'sm',
						buttonSize: 'sm',
						okVariant: 'danger',
						okTitle: 'ok',
						cancelTitle: 'Cancel',
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false
					})
					.then(async (v) => {
						if (!v) return;
						else {
							let result = await deleteTimeCardsTsDay(_id);
							result &&
								this.createToastForMobile(
									this.FormMSG(81, 'Success'),
									this.FormMSG(90, 'Timesheets item delete successfully'),
									'',
									'success',
									true
								);
							this.hideContextMenuPlanning = true;
							return (
								result &&
								(this.TimeCards = this.TimeCards.map((data) => {
									let res = {
										...data,
										tsDays: data.tsDays.filter((item) => +item.id !== _id)
									};
									return res;
								}))
							);
						}
					})
					.catch(() => {
						return false;
					});
				return result;
			}
		},
		async handleSelectDepartementChange(department) {
			this.dataPlanningSelect = [];
			this.planningToSend = [];
			this.departmentPersist = department;
			this.functionPersist = {
				...this.functionPersist,
				id: null,
				value: null,
				message: null
			};
			let usersNotShowing = this.usersNotShowing;
			if (this.departmentPersist.value !== null) {
				let arrays = [];
				for (let i = 0; i <= this.recordOffSet; i++) {
					let Filter = {
						...this.setFilterName('startDate', moment(`01/${this.DateDefaultSelect.value}`).format('YYYY-DD-MM') + 'T00:00:00Z'),
						recordLimit: this.recordLimit,
						recordOffSet: i,
						department: this.departmentPersist.value,
						userFunction: this.functionPersist.value,
						keyWord: this.valueToSearch,
						orderByName: this.orderByName,
						orderByFunction: this.orderByFunction
					};
					let FunctionValueDep = [];
					if (this.customFilterValue === 3) {
						FunctionValueDep = this.functionOrdersFiltered;
					} else {
						FunctionValueDep = [];
					}
					let result = [...(await getProjectUsersTsDaysPlanning(Filter, FunctionValueDep))].map((item) => ({ ...item, isSelected: false }));
					arrays.push(result);
				}
				let data = arrays.flat(Infinity);
				let result = data;
				for (let i = 0; i < usersNotShowing.length; i++) {
					let data = result;
					let response = data.filter((item) => +item.id !== +usersNotShowing[i]);
					result = response;
				}
				this.allDataTimeCards = result;
				this.TimeCards = result;
			} else {
				this.isLoading = true;
				let arrays = [];
				for (let i = 0; i <= this.recordOffSet; i++) {
					let Filter = {
						...this.setFilterName('startDate', moment(`01/${this.DateDefaultSelect.value}`).format('YYYY-DD-MM') + 'T00:00:00Z'),
						recordLimit: this.recordLimit,
						recordOffSet: i,
						department: this.departmentPersist.value,
						userFunction: this.functionPersist.value,
						keyWord: this.valueToSearch,
						orderByName: this.orderByName,
						orderByFunction: this.orderByFunction
					};
					let FunctionValueDep = [];
					if (this.customFilterValue === 3) {
						FunctionValueDep = this.functionOrdersFiltered;
					} else {
						FunctionValueDep = [];
					}
					let result = [...(await getProjectUsersTsDaysPlanning(Filter, FunctionValueDep))].map((item) => ({ ...item, isSelected: false }));
					arrays.push(result);
				}
				let data = arrays.flat(Infinity);
				let result = data;
				for (let i = 0; i < usersNotShowing.length; i++) {
					let data = result;
					let response = data.filter((item) => +item.id !== +usersNotShowing[i]);
					result = response;
				}
				this.allDataTimeCards = data;
				this.TimeCards = data;
				this.isLoading = false;
			}
		},
		async handleSelectFunctionChange(functionFilter) {
			this.dataPlanningSelect = [];
			this.planningToSend = [];
			this.functionPersist = functionFilter;
			let usersNotShowing = this.usersNotShowing;
			if (this.functionPersist.value !== null) {
				let arrays = [];
				for (let i = 0; i <= this.recordOffSet; i++) {
					let Filter = {
						...this.setFilterName('startDate', moment(`01/${this.DateDefaultSelect.value}`).format('YYYY-DD-MM') + 'T00:00:00Z'),
						recordLimit: this.recordLimit,
						recordOffSet: i,
						department: this.departmentPersist.value,
						userFunction: this.functionPersist.value,
						keyWord: this.valueToSearch,
						orderByName: this.orderByName,
						orderByFunction: this.orderByFunction
					};
					let FunctionValueDep = [];
					if (this.customFilterValue === 3) {
						FunctionValueDep = this.functionOrdersFiltered;
					} else {
						FunctionValueDep = [];
					}
					let result = [...(await getProjectUsersTsDaysPlanning(Filter, FunctionValueDep))].map((item) => ({ ...item, isSelected: false }));
					arrays.push(result);
				}
				let data = arrays.flat(Infinity);
				let result = data;
				for (let i = 0; i < usersNotShowing.length; i++) {
					let data = result;
					let response = data.filter((item) => +item.id !== +usersNotShowing[i]);
					result = response;
				}
				this.allDataTimeCards = result;
				this.TimeCards = result;
			} else {
				this.isLoading = true;
				let arrays = [];
				for (let i = 0; i <= this.recordOffSet; i++) {
					let Filter = {
						...this.setFilterName('startDate', moment(`01/${this.DateDefaultSelect.value}`).format('YYYY-DD-MM') + 'T00:00:00Z'),
						recordLimit: this.recordLimit,
						recordOffSet: i,
						department: this.departmentPersist.value,
						userFunction: this.functionPersist.value,
						keyWord: this.valueToSearch,
						orderByName: this.orderByName,
						orderByFunction: this.orderByFunction
					};
					let FunctionValueDep = [];
					if (this.customFilterValue === 3) {
						FunctionValueDep = this.functionOrdersFiltered;
					} else {
						FunctionValueDep = [];
					}
					let result = [...(await getProjectUsersTsDaysPlanning(Filter, FunctionValueDep))].map((item) => ({ ...item, isSelected: false }));
					arrays.push(result);
				}
				let data = arrays.flat(Infinity);
				let result = data;
				for (let i = 0; i < usersNotShowing.length; i++) {
					let data = result;
					let response = data.filter((item) => +item.id !== +usersNotShowing[i]);
					result = response;
				}
				this.allDataTimeCards = data;
				this.TimeCards = data;
				this.isLoading = false;
			}
		},
		async setFilter(item) {
			this.TimeCards = [];
			this.allDataTimeCards = [];
			this.planningToSend = [];
			this.dataPlanningSelect = [];
			this.isLoading = true;
			let arrays = [];
			for (let i = 0; i <= this.recordOffSet; i++) {
				let Filter = {
					...this.setFilterName('startDate', moment(`01/${this.DateDefaultSelect.value}`).format('YYYY-DD-MM') + 'T00:00:00Z'),
					recordLimit: this.recordLimit,
					recordOffSet: i,
					department: this.departmentPersist.value,
					userFunction: this.functionPersist.value,
					keyWord: this.valueToSearch,
					orderByName: this.orderByName,
					orderByFunction: this.orderByFunction
				};
				delete Filter.activateCrewAccessRestriction;
				let FunctionValueDep = [];
				if (this.customFilterValue === 3) {
					FunctionValueDep = this.functionOrdersFiltered;
				} else {
					FunctionValueDep = [];
				}
				let result = [...(await getProjectUsersTsDaysPlanning(Filter, FunctionValueDep))].map((item) => ({ ...item, isSelected: false }));
				arrays.push(result);
			}
			let data = arrays.flat(Infinity);
			let result = data;
			for (let i = 0; i < usersNotShowing.length; i++) {
				let data = result;
				let response = data.filter((item) => +item.id !== +usersNotShowing[i]);
				result = response;
			}
			let _dateSplit = this.DateDefaultSelect.value.split`/`;
			let month = +_dateSplit[0];
			let year = +_dateSplit[1];
			const nbDay = new Date(year, month, 0).getDate();
			let dateHeader = [];
			const langCurrent = this.currentLang;
			for (let n = 1; n <= nbDay; n++) {
				let formattedDate = moment(n.toString().padStart('2', '0') + '/' + this.DateDefaultSelect.value, 'DD/MM/YYYY')
					.locale(`${langCurrent}`)
					.format('DD ddd');
				dateHeader.push({
					key: `${n.toString().padStart('2', '0') + '/' + this.DateDefaultSelect.value}`,
					label: formattedDate,
					sortable: true
				});
			}
			this.headerTabPlanning = [
				{
					key: 'crew_member',
					label: this.FormMSG(1, 'Crew member'),
					sortable: true
				},
				...dateHeader
			];
			this.TimeCards = result;
			this.allDataTimeCards = result;
			this.isLoading = false;
		},
		async handleSelectedDay(data) {
			let result = [];
			if (data == -1) {
				result = [
					...(await getProjectOnlyUserTsDay({
						startDate: moment(this.DateDefaultSelect.value, 'MM/YYYY').startOf('month').format('YYYY-MM-DD') + 'T00:00:00Z',
						endDate: moment(this.DateDefaultSelect.value, 'MM/YYYY').endOf('month').format('YYYY-MM-DD') + 'T00:00:00Z'
					}))
				].map((item) => ({
					...item,
					crew_member: item.fullName,
					validatedText: this.GetTextFromMenuNumberAndMenuValue(1008, item.status),
					data: [{ ...item, crew_member: item.fullName }]
				}));
				this.TimeCards = result;
			} else {
				result = [
					...(await getProjectOnlyUserTsDay({
						startDate: moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD') + 'T00:00:00Z',
						endDate: moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD') + 'T00:00:00Z'
					}))
				].map((item) => ({
					...item,
					crew_member: item.fullName,
					validatedText: this.GetTextFromMenuNumberAndMenuValue(1008, item.status),
					data: [{ ...item, crew_member: item.fullName }]
				}));
				this.TimeCards = result;
			}
		},
		async onSearch(searchValue, action, idTable) {
			let usersNotShowing = this.usersNotShowing;
			this.filter = searchValue;
			this.valueToSearch = searchValue.replace(/[\s]+/gi, '%');
			if (idTable === this.timecards_One) {
				let DATA = this.allDataTimeCards;
				if (searchValue.length === 0) {
					this.TimeCards = DATA;
				} else {
					if (action) {
						this.TimeCards = DATA;
					} else {
						let arrays = [];
						for (let i = 0; i <= this.recordOffSet; i++) {
							let Filter = {
								...this.setFilterName('startDate', moment(`01/${this.DateDefaultSelect.value}`).format('YYYY-DD-MM') + 'T00:00:00Z'),
								recordLimit: this.recordLimit,
								recordOffSet: i,
								department: this.departmentPersist.value,
								userFunction: this.functionPersist.value,
								keyWord: this.valueToSearch,
								orderByName: this.orderByName,
								orderByFunction: this.orderByFunction
							};
							let FunctionValueDep = [];
							if (this.customFilterValue === 3) {
								FunctionValueDep = this.functionOrdersFiltered;
							} else {
								FunctionValueDep = [];
							}
							let result = [...(await getProjectUsersTsDaysPlanning(Filter, FunctionValueDep))].map((item) => ({ ...item, isSelected: false }));
							arrays.push(result);
						}
						let data = arrays.flat(Infinity);
						let result = data;
						for (let i = 0; i < usersNotShowing.length; i++) {
							let data = result;
							let response = data.filter((item) => +item.id !== +usersNotShowing[i]);
							result = response;
						}
						this.TimeCards = result;
					}
				}
			}
		}
	}
};
</script>
<style scoped>
@keyframes sonar {
	0% {
		transform: scale(0.6);
		opacity: 1;
	}
	100% {
		transform: scale(1.2);
		opacity: 0;
	}
}
@keyframes pulse {
	0% {
		transform: scale(1);
	}
	20% {
		transform: scale(0.9);
	}
	50% {
		transform: scale(1);
	}
	80% {
		transform: scale(0.6);
	}
	100% {
		transform: scale(1);
	}
}
.divider-horizontal {
	border-bottom: 2px solid #ffffff;
}
.status-not-submitted {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #fcede9;
	border: 1px solid #ea4e2c;
	animation: pulse 1.5s infinite;
}
.status-not-submitted:after {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #ea4e2c;
	animation: sonar 1.5s infinite;
}
.status-info {
	width: 10px;
	height: 10px;
	border-radius: 2px;
	background-color: #e7f2ff;
	border: 1px solid #00008b;
}
.status-pending {
	width: 10px;
	height: 10px;
	border-radius: 2px;
	background-color: #fbf5e5;
	border: 1px solid #cd9a16;
}
.status-validated {
	width: 10px;
	height: 10px;
	border-radius: 2px;
	background-color: #ecf6e8;
	border: 1px solid #006400;
}
.status-refused {
	width: 10px;
	height: 10px;
	border-radius: 2px;
	background-color: #ffb0b0;
	border: 1px solid #980000;
}
.status-sent {
	width: 10px;
	height: 10px;
	border-radius: 2px;
	background-color: #d5d6d7;
	border: 1px solid #687887;
}
.solve-status {
	width: 40px;
	height: 8px;
	border-radius: 4px;
	background-color: #ea4e2c;
	border: 2px solid #ea4e2c;
}
.gp-5 {
	gap: 5px;
}
</style>
