var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: _vm.titleCaption,
        "header-class": "header-class-modal-doc-package",
        size: "md",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
      on: {
        cancel: _vm.emitEventClose,
        hidden: _vm.emitEventClose,
        show: _vm.handleShow,
        ok: function ($event) {
          $event.preventDefault()
          return _vm.emitEventClose.apply(null, arguments)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ ok, cancel }) {
            return [
              _c("div", [
                _vm.addDecor === true
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "w-100 d-flex justify-content-end align-items-center",
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "w-138-px mr-3",
                            attrs: {
                              size: "md",
                              variant: "custom-outline-gray",
                              disabled: _vm.loadingSubmit,
                              block: "",
                            },
                            on: { click: cancel },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(135, "Cancel")) +
                                "\n\t\t\t\t"
                            ),
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "w-138-px",
                            staticStyle: { "margin-top": "5px" },
                            attrs: {
                              size: "md",
                              variant: "primary",
                              disabled: _vm.loadingSubmit,
                              block: "",
                            },
                            on: { click: _vm.handleClickSave },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(136, "Save")) +
                                "\n\t\t\t\t"
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "w-100 d-flex justify-content-end align-items-center",
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "w-138-px mr-3",
                            attrs: {
                              size: "md",
                              variant: "danger",
                              disabled: _vm.loadingSubmit,
                              block: "",
                            },
                            on: { click: ok },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(125, "OFF")) +
                                "\n\t\t\t\t"
                            ),
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "w-138-px",
                            staticStyle: { "margin-top": "5px" },
                            attrs: {
                              size: "md",
                              variant: "custom-outline-gray",
                              disabled: _vm.loadingSubmit,
                              block: "",
                            },
                            on: { click: cancel },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(128, "Close")) +
                                "\n\t\t\t\t"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
              ]),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "b-collapse",
        { attrs: { visible: _vm.addDecor === false } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(215, "Decors") } },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c("v-select", {
                            staticClass: "grow-flex-auto",
                            attrs: {
                              id: "supplier-selector",
                              options: _vm.locationList,
                              "close-on-select": "",
                              label: "setName",
                              reduce: (option) => option.id,
                              placeholder: _vm.FormMSG(
                                452,
                                "Add or select a decor"
                              ),
                              clearable: false,
                            },
                            model: {
                              value: _vm.locationIdSelected,
                              callback: function ($$v) {
                                _vm.locationIdSelected = $$v
                              },
                              expression: "locationIdSelected",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row icon-group-border-append",
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.top",
                                      modifiers: { hover: true, top: true },
                                    },
                                  ],
                                  staticClass:
                                    "cursor-pointer d-inline-flex justify-content-center align-items-center",
                                  style: `width: 34px`,
                                  attrs: {
                                    title: _vm.FormMSG(157, "Add new decor"),
                                  },
                                  on: { click: _vm.handleClickAddNewDecor },
                                },
                                [
                                  _c(_vm.getLucideIcon("PlusCircle"), {
                                    tag: "component",
                                    attrs: {
                                      color: "#00A09C",
                                      size: 18,
                                      "stroke-width": 2.25,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.addDecor === true } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.FormMSG(198, "Name of decor"),
                state: !_vm.$v.decorName.$error,
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  placeholder: _vm.FormMSG(199, "Enter name"),
                  state: !_vm.$v.decorName.$error,
                },
                model: {
                  value: _vm.$v.decorName.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.decorName, "$model", $$v)
                  },
                  expression: "$v.decorName.$model",
                },
              }),
              _vm.$v.decorName.$error
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.FormMSG(487, "Decor name is required")) +
                        "\n\t\t\t"
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }