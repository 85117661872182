<template>
	<b-modal
		v-model="isOpen"
		:title="FormMSG(14, 'Advanced edit')"
		header-class="header-class-modal-doc-package"
		size="xl"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@cancel="emitEventClose"
		@hidden="emitEventClose"
		@ok.prevent="handleClickSubmit"
	>
		<div class="form container-layout">
			<b-row>
				<b-col>
					<fieldset class="my-3 border-groove-blue-streak" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-blue-streak">
							{{ FormMSG(132, 'Selection') }}
						</legend>
						<div class="pt-3 pb-3">
							<b-row>
								<b-col>
									<div class="fs-14 fw-700">
										{{ userSelected.fullName }} - {{ userSelected.tsDay.date | formatDate('dddd DD MMMM YYYY') }}
										<span v-if="+userSelected.location.id > 0">({{ userSelected.location.setName }})</span>
									</div>
								</b-col>
							</b-row>
						</div>
					</fieldset>
				</b-col>
			</b-row>

			<b-row class="mt-2">
				<b-col>
					<fieldset class="my-3 border-groove-blue-streak" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-blue-streak">
							{{ FormMSG(145, 'Work detail') }}
						</legend>
						<div class="pt-3 pb-3">
							<b-row>
								<b-col>
									<b-table
										v-if="$screen.width >= 992"
										selected-variant="primary"
										hover
										selectable
										select-mode="single"
										responsive="sm"
										ref="documentPackageTable"
										:items="dataList"
										style="text-align: left"
										:fields="tableFields"
										bordered
										striped
										small
										head-variant="dark"
										:empty-text="FormMSG(250, 'No data found')"
										show-empty
									>
										<template #cell(rubric)="{ item, index }">
											<div>
												<div v-if="item.isEdit === false">
													<div v-if="typeof item.rubric === 'number'">
														{{ item.flagItemName }}
													</div>
													<div v-if="typeof item.rubric === 'object'">
														{{ item.rubric.short }} {{ item.rubric.value }}H - {{ item.rubric.name }}
													</div>
												</div>
												<div v-if="item.isEdit === true">
													<b-form-group class="mb-0">
														<v-select
															v-model="$v.dataList.$each.$iter[index].rubric.$model"
															:options="flagItemsOptions"
															:placeholder="FormMSG(87, 'Please select')"
															:class="{ 'is-invalid': $v.dataList.$each.$iter[index].rubric.$error }"
															:clearable="false"
															:reduce="(option) => +option.id"
															@option:selected="handleOptionSelected($event, index)"
														>
															<template #option="{ short, name, value }">
																<div>
																	<span v-if="short && short !== ''">{{ short }} {{ value }}H - </span> {{ name }}
																</div>
															</template>
															<template #selected-option="{ short, name, value }">
																<div>
																	<span v-if="short && short !== ''">{{ short }} {{ value }}H - </span> {{ name }}
																</div>
															</template>
														</v-select>
														<div v-if="$v.dataList.$each.$iter[index].rubric.$error" class="invalid-feedback">
															{{ FormMSG(89, 'Rubric is required') }}
														</div>
													</b-form-group>
												</div>
											</div>
										</template>
										<template #cell(amount)="{ item, index }">
											<div>
												<div v-if="item.isEdit === false">
													{{ getCurrency(item.amount) }}
												</div>
												<div v-if="item.isEdit === true">
													<b-form-group class="mb-0">
														<b-input-group>
															<b-form-input
																v-model="$v.dataList.$each.$iter[index].amount.$model"
																:state="!$v.dataList.$each.$iter[index].amount.$error"
															/>
															<b-input-group-append>
																<b-input-group-text>
																	<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
																</b-input-group-text>
															</b-input-group-append>
														</b-input-group>
														<div v-if="$v.dataList.$each.$iter[index].amount.$error" class="invalid-feedback">
															{{ FormMSG(81, 'Amount is required or must greater 0') }}
														</div>
													</b-form-group>
												</div>
											</div>
										</template>
										<template #cell(activity)="{ item, index }">
											<div>
												<div v-if="item.isEdit === false">
													<div v-if="item.activity !== null">{{ item.activity.short }} - {{ item.activity.name }}</div>
												</div>
												<div v-if="item.isEdit === true">
													<b-form-group class="mb-0">
														<v-select
															v-model="$v.dataList.$each.$iter[index].activity.$model"
															:options="activityOptions"
															:placeholder="FormMSG(98, 'Select a activity ...')"
															:clearable="false"
															:class="{ 'is-invalid': $v.dataList.$each.$iter[index].activity.$error }"
														>
															<template #selected-option="{ name, short }">
																<div>
																	{{ `${short} - ${name}` }}
																</div>
															</template>
															<template #option="{ name, short }">
																<div>
																	{{ `${short} - ${name}` }}
																</div>
															</template>
														</v-select>
														<div v-if="$v.dataList.$each.$iter[index].activity.$error" class="invalid-feedback">
															{{ FormMSG(82, 'Activity is required') }}
														</div>
													</b-form-group>
												</div>
											</div>
										</template>
										<template #cell(category)="{ item, index }">
											<div>
												<div v-if="item.isEdit === false">
													{{ setTextCategoriesWithActivities(item.labelCategory, index) }}
												</div>
												<div v-if="item.isEdit === true">
													<div class="tax-item">
														<treeselect
															v-model="$v.dataList.$each.$iter[index].category.$model"
															:multiple="false"
															:options="mapCategories"
															:default-expand-level="1"
															:disable-branch-nodes="true"
															:placeholder="FormMSG(52, 'Select a category ...')"
															open-direction="bottom"
															:clearable="false"
															:class="{ 'is-invalid': $v.dataList.$each.$iter[index].category.$error }"
															@select="handleSelectedCategory($event, index)"
														>
															<div slot="value-label" slot-scope="{ node }" :title="node.label">
																<div>{{ setTextCategoriesWithActivities(node.label, index) }}</div>
															</div>
															<div slot="option-label" slot-scope="{ node }">
																<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
															</div>
														</treeselect>
													</div>

													<div v-if="$v.dataList.$each.$iter[index].category.$error" class="invalid-feedback">
														{{ FormMSG(89, 'Please select a category') }}
													</div>
												</div>
											</div>
										</template>
										<template #cell(edit)="{ item, index }">
											<div>
												<div v-if="item.isEdit === false" class="pt-1">
													<b-button variant="custom-transparent" class="text-color-blue-streak" @click="handleClickToEdit(index)">
														<component :is="getLucideIcon('Edit')" :size="18" />
													</b-button>
												</div>
												<div v-if="item.isEdit === true">
													<div v-if="isRegissor === true">
														<b-button variant="custom-transparent" @click="handleClickEdit(index)">
															<div class="text-success">
																<component :is="getLucideIcon('CheckSquare')" :size="18" />
															</div>
														</b-button>
													</div>
													<div v-else-if="isRegissor === false">
														<div v-if="$v.dataList.$each.$iter[index].$invalid === false" class="pt-1">
															<b-button variant="custom-transparent" @click="handleClickEdit(index)">
																<div class="text-success">
																	<component :is="getLucideIcon('CheckSquare')" :size="18" />
																</div>
															</b-button>
														</div>
														<div v-if="$v.dataList.$each.$iter[index].$invalid === true" class="pt-1">
															<b-button
																variant="custom-transparent"
																disabled
																v-b-tooltip.hover
																:title="FormMSG(181, 'Some fields are mandatory')"
															>
																<div class="text-success">
																	<component :is="getLucideIcon('CheckSquare')" :size="18" />
																</div>
															</b-button>
														</div>
													</div>
												</div>
											</div>
										</template>
										<template #cell(remove)="{ item, index }">
											<div>
												<div class="pt-1">
													<b-button variant="custom-transparent" size="sm" @click="handleClickRemove(index)">
														<div class="text-danger">
															<component :is="getLucideIcon('Trash2')" :size="18" />
														</div>
													</b-button>
												</div>
											</div>
										</template>
									</b-table>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="3" offset="9">
									<b-button variant="primary" block size="sm" @click="handleClickAddRubric">
										<component :is="getLucideIcon('PlusCircle')" :size="16" /> {{ FormMSG(73, 'Add a rubric') }}
									</b-button>
								</b-col>
							</b-row>
						</div>
					</fieldset>
				</b-col>
			</b-row>
		</div>

		<template #modal-footer="{ ok, cancel }">
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button size="md" variant="custom-outline-gray" style="margin-top: 5px" class="w-138-px mr-3" :disabled="loadingSubmit" @click="cancel" block>
					{{ FormMSG(100, 'Close') }}
				</b-button>
				<b-button size="md" variant="primary" class="w-138-px" :disabled="loadingSubmit" @click="ok" block>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-if="loadingSubmit" small />
						<div :class="`${loadingSubmit ? 'pl-2' : ''}`" style="margin-top: 1px">{{ FormMSG(101, 'Save') }}</div>
					</div>
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import CurrencySvg from '@/components/Svg/Currency';
import { getProjectFlagItems } from '@/cruds/fimalac.crud';
import { validationMixin } from 'vuelidate';
import { required, minValue, decimal } from 'vuelidate/lib/validators';
import { getBudgetHierarchicalCategoryJson } from '@/cruds/budget.crud';
import { removeAttributeTree } from '@/shared/utils';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import _ from 'lodash';
import { rendCurrency } from '~helpers';
import { getUsersAndFlagsValue } from '@/cruds/flag.crud';
import { updTsDayDetails } from '@/cruds/timesheets-efc.crud';

export default {
	name: 'UserAdvancedEdit',

	mixins: [languageMessages, globalMixin, validationMixin],

	components: {
		Treeselect,
		CurrencySvg,
		DepartmentSelector: () => ({
			component: import('@/components/DepartmentsSelection')
		})
	},

	props: {
		open: { type: Boolean, default: false, required: false },
		externalData: { type: Object, default: () => {}, required: false },
		isRegissor: { type: Boolean, default: false, required: false }
	},

	data() {
		return {
			userSelected: {
				tsDay: {
					date: new Date()
				},
				location: {
					id: 0
				}
			},
			dataList: [],
			newRubricsIndex: [],
			newRubricsCategory: {},
			loadingSubmit: false,
			mapCategories: [],

			flagItemsOptions: [],
			activityOptions: []
		};
	},

	validations: {
		dataList: {
			$each: {
				rubric: { required },
				amount: { required, minValue: minValue(1), decimal },
				activity: { required },
				category: { required }
			}
		}
	},

	watch: {
		externalData: {
			handler(newVal) {
				this.dataList = [];
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.userSelected = newVal;

					for (let i = 0; i < newVal.tsDay.tsDayDetails.length; i++) {
						const element = newVal.tsDay.tsDayDetails[i];
						if (element.type !== 1) {
							this.dataList.push({
								id: +element.id,
								type: +element.type,
								flagId: +element.flagId,
								rubric: +element.projectFlagItem.id,
								flagItemFlagId: +element.projectFlagItem.flagId,
								flagItemValue: +element.projectFlagItem.value,
								value: +element.value,
								amount: +element.amount,
								category: +element.category,
								typeOne: +element.typeOne,
								paycode: +element.paycode,
								activity: +element.activity.id > 0 ? element.activity : null,
								labelCategory: element.costCenter,
								flagItemName: element.projectFlagItem.short + ' - ' + element.projectFlagItem.name,
								activityName: +element.activity.id > 0 ? `${element.activity.short} - ${element.activity.name}` : '',
								isEdit: false
							});
						}
					}
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		tableFields() {
			let result = [
				{
					key: 'rubric',
					label: this.FormMSG(59, 'Rubric'),
					class: this.isRegissor === false ? 'text-left w-20' : 'text-left',
					sortable: true
				},
				{
					key: 'amount',
					label: this.FormMSG(49, 'Amount'),
					class: 'text-left w-20',
					sortable: true
				},
				{
					key: 'category',
					label: this.FormMSG(51, 'Category'),
					class: 'text-left w-20 form',
					sortable: true
				},
				{
					key: 'activity',
					label: this.FormMSG(50, 'Activity'),
					class: 'text-left w-20',
					sortable: true
				},
				{
					key: 'edit',
					label: this.FormMSG(58, 'Edit'),
					class: this.isRegissor === false ? 'text-center w-20' : 'text-center w-125-px',
					sortable: false
				},
				{
					key: 'remove',
					label: this.FormMSG(61, 'Remove'),
					class: this.isRegissor === false ? 'text-center w-20' : 'text-center w-125-px',
					sortable: false
				}
			];

			if (this.isRegissor === true) {
				result.splice(1, 3);
			}

			return result;
		}
	},

	async created() {
		await this.getCategories(-2, 1);
		await this.getProjectFlagItems();
		await this.getActivityFlagItems();
	},

	methods: {
		setTextCategoriesWithActivities(text, index) {
			if (this.dataList[index].activity !== null) {
				let short = this.dataList[index].activity.short;
				let textExact = text.toString().replace('$', short);
				return textExact;
			}
			return text;
		},
		handleSelectedCategory(payload, index) {
			this.dataList[index].labelCategory = payload.label;
		},
		getCurrency(value) {
			return rendCurrency(parseFloat(value));
		},
		async handleOptionSelected(payload, index) {
			let usersId = [+this.userSelected.id];
			let flagsId = [+payload.id];

			let result = await getUsersAndFlagsValue(usersId, flagsId);

			this.dataList[index].amount = result[0].projectFlagItem[0].amount;
			this.dataList[index].flagItemFlagId = +payload.flagId;
			this.dataList[index].flagItemValue = +payload.value;
			this.dataList[index].flagItemName = payload.short + ' - ' + payload.name;
			// this.dataList.$each.$iter[index].amount.$model = payload.amount;
		},

		async getActivityFlagItems() {
			const result = await getProjectFlagItems([1]);

			this.activityOptions = result.projectFlagItems;
		},
		async getProjectFlagItems() {
			const result = await getProjectFlagItems([4]);

			this.flagItemsOptions = result.projectFlagItems;
		},
		async getCategories() {
			this.mapCategories = [];
			await getBudgetHierarchicalCategoryJson(-2, true, null, -1, false).then((result) => {
				this.mapCategories = removeAttributeTree(result, 'children', null);
			});
		},
		handleClickToEdit(index) {
			this.dataList[index].isEdit = true;
		},
		handleClickEdit(index) {
			if (this.isRegissor === false) {
				if (this.$v.dataList.$each.$iter[index].$invalid === false) {
					const action = () => {
						if (this.newRubricsIndex.includes(index)) {
							this.dataList[index].isEdit = false;
						} else {
							this.dataList[index].isEdit = false;
							this.createToastForMobile(this.FormMSG(110, 'Success'), this.FormMSG(111, 'Update successfully!'));
						}
					};

					this.confirmModal(action, this.FormMSG(112, 'Are you sure ?'));
				}
			} else if (this.isRegissor === true) {
				const action = () => {
					if (this.newRubricsIndex.includes(index)) {
						this.dataList[index].isEdit = false;
					} else {
						this.dataList[index].isEdit = false;
						this.createToastForMobile(this.FormMSG(110, 'Success'), this.FormMSG(111, 'Update successfully!'));
					}
				};

				this.confirmModal(action, this.FormMSG(112, 'Are you sure ?'));
			}
		},
		handleClickRemove(index) {
			const action = () => {
				if (this.newRubricsIndex.includes(index)) {
					this.newRubricsIndex.splice(index, 1);
					this.dataList.splice(index, 1);
				} else {
					this.dataList.splice(index, 1);
					this.createToastForMobile(this.FormMSG(110, 'Success'), this.FormMSG(114, 'Data remove successfully!'));
				}
			};

			this.confirmModal(action, this.FormMSG(112, 'Are you sure ?'));
		},
		handleClickAddRubric() {
			const newRubric = {
				id: 0,
				rubric: null,
				amount: 0,
				activity: null,
				category: null,
				isEdit: true,
				labelCategory: ''
			};

			this.dataList.push(newRubric);
			this.newRubricsIndex.push(this.dataList.length - 1);
		},
		async handleClickSubmit() {
			let validate = true;
			if (this.isRegissor === false) {
				this.$v.$touch();
				if (this.$v.$invalid) {
					this.createToastForMobile(this.FormMSG(421, 'Error'), this.FormMSG(422, 'Many fields are mandatory!'), '', 'danger');
				} else {
					const input = this.prepareInputDataToSend();
					await updTsDayDetails(input, validate);
					this.createToastForMobile(this.FormMSG(110, 'Success'), this.FormMSG(111, 'Update successfully!'));
					this.$emit('user-advanced-edit:submitted');
					this.emitEventClose();
				}
			} else {
				const input = this.prepareInputDataToSend();

				await updTsDayDetails(input, validate);
				this.createToastForMobile(this.FormMSG(110, 'Success'), this.FormMSG(111, 'Update successfully!'));
				this.$emit('user-advanced-edit:submitted');
				this.emitEventClose();
			}
		},
		prepareInputDataToSend() {
			let result = [];

			this.dataList.map((option) => {
				if (this.isRegissor === false) {
					result.push({
						tsDayId: +this.externalData.tsDay.id,
						flagIdOne: +option.activity.flagId,
						valueOne: +option.activity.value,
						flagId: +option.flagItemFlagId,
						value: +option.flagItemValue,
						amount: +option.amount,
						category: +option.category,
						typeOne: +option.typeOne
					});
				} else if (this.isRegissor === true) {
					result.push({
						tsDayId: +this.externalData.tsDay.id,
						flagId: +option.flagItemFlagId,
						value: +option.flagItemValue,
						paycode: option.paycode === NaN ? null : +option.paycode,
						flagIdOne: option.activity !== null ? +option.activity.flagId : null,
						valueOne: option.activity !== null ? +option.activity.value : null,
						amount: +option.amount,
						category: +option.category,
						typeOne: +option.typeOne
					});
				}
			});

			return result;
		},
		emitEventClose() {
			this.resetData();
			this.$emit('user-advanced-edit:close');
		},
		resetData() {
			this.userSelected = {
				tsDay: {
					date: new Date()
				},
				location: {
					id: 0
				}
			};
			this.dataList = [];
			this.newRubricsIndex = [];
			this.loadingSubmit = false;
		}
	}
};
</script>
