var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column" },
    [
      _c(
        "div",
        {
          staticClass: "d-flex flex-row",
          staticStyle: { "background-color": "#f3f4f5" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column justify-center align-items-center w-18",
            },
            [
              _c("div", { staticClass: "content-c1 gap-10" }, [
                _c(
                  "div",
                  [
                    _c("MonthSelect", {
                      attrs: { DateDefaultSelect: _vm.DateDefaultSelect },
                      on: { setDateDefaultSelect: _vm.setDateDefaultSelect },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "outline-primary" },
                        on: { click: _vm.handleClickReference },
                      },
                      [
                        _c("span", { staticClass: "text-btn" }, [
                          _vm._v(_vm._s(_vm.FormMSG(9, "Preference"))),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex flex-wrap mr-4 ml-4 active" },
                  [
                    _c("treeselect", {
                      attrs: {
                        options: _vm.mapCode,
                        value: _vm.selected_code,
                        "disable-branch-nodes": true,
                      },
                      on: {
                        input: _vm.handleChangeCode,
                        select: _vm.codeChanged,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "value-label",
                          fn: function ({ node }) {
                            return _c("div", { attrs: { title: node.label } }, [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.setTextCategoriesWithActivities(
                                      node.label
                                    )
                                  )
                                ),
                              ]),
                            ])
                          },
                        },
                        {
                          key: "option-label",
                          fn: function ({ node }) {
                            return _c("div", {}, [
                              _c(
                                "div",
                                {
                                  staticClass: "treeselect-label",
                                  attrs: { title: node.label },
                                },
                                [_vm._v(_vm._s(node.label))]
                              ),
                            ])
                          },
                        },
                      ]),
                      model: {
                        value: _vm.selected_code,
                        callback: function ($$v) {
                          _vm.selected_code = $$v
                        },
                        expression: "selected_code",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c("div", { staticClass: "divider-vertical w-82" }, [
            _c(
              "div",
              { staticClass: "d-flex align-items-start p-2 flex-column w-100" },
              [
                _vm.isProduction
                  ? _c(
                      "div",
                      {
                        staticClass: "d-flex flex-row w-100",
                        staticStyle: { height: "40px" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex flex-wrap flex-row w-80 p-2" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "d-flex flex-wrap p-2 w-75 active",
                              },
                              [
                                _c("treeselect", {
                                  attrs: {
                                    options: _vm.mapCategories,
                                    value: _vm.selected_categorie,
                                    "disable-branch-nodes": true,
                                    placeholder: _vm.FormMSG(
                                      2,
                                      "Select an categories"
                                    ),
                                  },
                                  on: {
                                    input: _vm.handleChangeCategories,
                                    select: _vm.categoriesChanged,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "value-label",
                                        fn: function ({ node }) {
                                          return _c(
                                            "div",
                                            { attrs: { title: node.label } },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.setTextCategoriesWithActivities(
                                                      node.label
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          )
                                        },
                                      },
                                      {
                                        key: "option-label",
                                        fn: function ({ node }) {
                                          return _c("div", {}, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "treeselect-label",
                                                attrs: { title: node.label },
                                              },
                                              [_vm._v(_vm._s(node.label))]
                                            ),
                                          ])
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2397312271
                                  ),
                                  model: {
                                    value: _vm.selected_categorie,
                                    callback: function ($$v) {
                                      _vm.selected_categorie = $$v
                                    },
                                    expression: "selected_categorie",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "d-flex flex-wrap p-2 w-25 active",
                              },
                              [
                                _c("treeselect", {
                                  attrs: {
                                    options: _vm.mapActivities,
                                    value: _vm.selected_activity,
                                    placeholder: _vm.FormMSG(
                                      3,
                                      "Select an activities"
                                    ),
                                  },
                                  on: {
                                    input: _vm.handleChangeActivities,
                                    select: _vm.activitiesChanged,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "option-label",
                                        fn: function ({ node }) {
                                          return _c("div", {}, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "treeselect-label",
                                                attrs: { title: node.label },
                                              },
                                              [_vm._v(_vm._s(node.label))]
                                            ),
                                          ])
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2657349975
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-start w-20 p-2" },
                          [
                            _c(
                              "div",
                              {
                                ref: "headerFlagItem",
                                staticClass: "w-100 p-2",
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName:
                                          "v-b-tooltip.hover.bottom.html",
                                        value: `${
                                          !(_vm.$screen.width > 1540)
                                            ? _vm.FormMSG(4, "RAZ selection") +
                                              " " +
                                              _vm.countDataToUpdateProd(
                                                _vm.dataPlanningSelect
                                              )
                                            : ""
                                        }`,
                                        expression:
                                          "\n\t\t\t\t\t\t\t\t\t`${!($screen.width > 1540) ? FormMSG(4, 'RAZ selection') + ' ' + countDataToUpdateProd(dataPlanningSelect) : ''}`\n\t\t\t\t\t\t\t\t",
                                        modifiers: {
                                          hover: true,
                                          bottom: true,
                                          html: true,
                                        },
                                      },
                                    ],
                                    staticClass: "btn-custom mr-2",
                                    style: `width: ${_vm.sizeButton}%`,
                                    attrs: {
                                      disabled: _vm.isRazSelectionProd()
                                        ? true
                                        : false,
                                      variant: "danger",
                                      size: "sm",
                                    },
                                    on: { click: _vm.onOpenModalToAllDelete },
                                  },
                                  [
                                    _c(
                                      _vm.getLucideIcon(_vm.ICONS.TRASH.name),
                                      {
                                        tag: "component",
                                        attrs: { color: "#FFFFFF", size: 20 },
                                      }
                                    ),
                                    _vm.$screen.width > 1540
                                      ? _c(
                                          "span",
                                          { staticClass: "text-btn" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(4, "RAZ selection")
                                              ) +
                                                " " +
                                                _vm._s(
                                                  _vm.isRazSelectionProd()
                                                    ? "(00)"
                                                    : _vm.countDataToUpdateProd(
                                                        _vm.dataPlanningSelect
                                                      )
                                                )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "d-flex flex-row w-100" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-wrap flex-row w-80 p-2" },
                    [
                      _vm.flags_options_favoris.length === 0
                        ? [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-center align-items-center w-100 text-muted fs-14 p-2",
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(
                                        26,
                                        "No favorite flags selected"
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ]
                        : _vm._l(
                            _vm.flags_options_favoris,
                            function (option, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "d-flex flex-wrap p-2 gap-10",
                                },
                                [
                                  _c("flag-items", {
                                    attrs: { option: option },
                                    on: { setFlags: _vm.setFlags },
                                  }),
                                ],
                                1
                              )
                            }
                          ),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-start w-20 p-2" },
                    [
                      _c(
                        "div",
                        { staticClass: "w-100 p-2" },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.bottom.html",
                                  value: `${
                                    !(_vm.$screen.width > 1540)
                                      ? _vm.FormMSG(5, "Add to selection")
                                      : ""
                                  }`,
                                  expression:
                                    "`${!($screen.width > 1540) ? FormMSG(5, 'Add to selection') : ''}`",
                                  modifiers: {
                                    hover: true,
                                    bottom: true,
                                    html: true,
                                  },
                                },
                              ],
                              staticClass: "btn-custom mr-2",
                              style: `width: ${_vm.sizeButton}%`,
                              attrs: {
                                disabled: _vm.checkUserAuthorization,
                                variant: "primary",
                                size: "sm",
                              },
                              on: { click: _vm.handleClickAddSelection },
                            },
                            [
                              _vm._t("button-label", function () {
                                return [
                                  _c(
                                    _vm.getLucideIcon(
                                      _vm.ICONS.PLUS_CIRCLE_II.name
                                    ),
                                    {
                                      tag: "component",
                                      attrs: {
                                        color: _vm.ICONS.PLUS_CIRCLE_II.color,
                                        size: 20,
                                      },
                                    }
                                  ),
                                  _vm.$screen.width > 1540
                                    ? _c("span", { staticClass: "text-btn" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.FormMSG(5, "Add to selection")
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "d-flex flex-row w-100 mb-1" }, [
                  _c("div", { staticClass: "d-flex flex-row w-100" }, [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex flex-row align-items-center w-60",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "ml-3" },
                          [
                            _c("b-form-checkbox", {
                              attrs: {
                                size: "lg",
                                value: true,
                                "aria-describedby": "filter-no-favoris",
                                disabled: _vm.mapOtherRubrique.length === 1,
                              },
                              on: { change: _vm.handleFilterNotFavoris },
                              model: {
                                value: _vm.check_no_favoris,
                                callback: function ($$v) {
                                  _vm.check_no_favoris = $$v
                                },
                                expression: "check_no_favoris",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex flex-wrap w-50 active" },
                          [
                            _c("treeselect", {
                              attrs: {
                                options: _vm.mapOtherRubrique,
                                value: _vm.selected_no_favoris,
                                placeholder: _vm.FormMSG(
                                  6,
                                  "Select other rubrique"
                                ),
                                disabled: _vm.mapOtherRubrique.length === 1,
                                multiple: true,
                              },
                              on: { input: _vm.handleSelectedNoFavoris },
                              scopedSlots: _vm._u([
                                {
                                  key: "value-label",
                                  fn: function ({ node }) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center justify-content-center",
                                        staticStyle: {
                                          height: "12px !important",
                                        },
                                        attrs: {
                                          title: _vm.setTextValue(node.label),
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-size": "10px !important",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.setTextValue(node.label)
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  },
                                },
                                {
                                  key: "option-label",
                                  fn: function ({ node }) {
                                    return _c("div", {}, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "treeselect-label",
                                          attrs: {
                                            title: _vm.setText(node.label),
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.setText(node.label))
                                          ),
                                        ]
                                      ),
                                    ])
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row align-items-start justify-content-end w-40",
                      },
                      [
                        _c("div", { staticClass: "d-flex w-50" }, [
                          _c(
                            "div",
                            { staticClass: "w-100 ml-3 mr-2" },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.bottom.html",
                                      value: `${
                                        !(_vm.$screen.width > 1540)
                                          ? _vm.isProduction
                                            ? _vm.FormMSG(
                                                27,
                                                "Validate all planning"
                                              )
                                            : _vm.FormMSG(
                                                28,
                                                "Send all planning"
                                              )
                                          : ""
                                      }`,
                                      expression:
                                        "\n\t\t\t\t\t\t\t\t\t\t\t`${\n\t\t\t\t\t\t\t\t\t\t\t\t!($screen.width > 1540)\n\t\t\t\t\t\t\t\t\t\t\t\t\t? isProduction\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t? FormMSG(27, 'Validate all planning')\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t: FormMSG(28, 'Send all planning')\n\t\t\t\t\t\t\t\t\t\t\t\t\t: ''\n\t\t\t\t\t\t\t\t\t\t\t}`\n\t\t\t\t\t\t\t\t\t\t",
                                      modifiers: {
                                        hover: true,
                                        bottom: true,
                                        html: true,
                                      },
                                    },
                                  ],
                                  staticClass: "btn-custom mr-2",
                                  style: `width: ${_vm.sizeButton}%`,
                                  attrs: { variant: "success", size: "sm" },
                                  on: { click: _vm.validateAllPlanning },
                                },
                                [
                                  _c(
                                    _vm.getLucideIcon(
                                      _vm.ICONS.CHECK_SQUARE.name
                                    ),
                                    {
                                      tag: "component",
                                      attrs: { color: "#FFFFFF", size: 20 },
                                    }
                                  ),
                                  _vm.$screen.width > 1540
                                    ? _c("span", { staticClass: "text-btn" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.isProduction
                                              ? _vm.FormMSG(
                                                  27,
                                                  "Validate all planning"
                                                )
                                              : _vm.FormMSG(
                                                  28,
                                                  "Send all planning"
                                                )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm.isProduction === false
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex w-50 align-items-start mr-2",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "w-100 ml-3 mr-2" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName:
                                              "v-b-tooltip.hover.bottom.html",
                                            value: `${
                                              !(_vm.$screen.width > 1540)
                                                ? _vm.FormMSG(
                                                    4,
                                                    "RAZ selection"
                                                  ) +
                                                  " " +
                                                  _vm.countDataToUpdate(
                                                    _vm.dataPlanningSelect
                                                  )
                                                : ""
                                            }`,
                                            expression:
                                              "\n\t\t\t\t\t\t\t\t\t\t\t`${!($screen.width > 1540) ? FormMSG(4, 'RAZ selection') + ' ' + countDataToUpdate(dataPlanningSelect) : ''}`\n\t\t\t\t\t\t\t\t\t\t",
                                            modifiers: {
                                              hover: true,
                                              bottom: true,
                                              html: true,
                                            },
                                          },
                                        ],
                                        staticClass: "btn-custom",
                                        style: `width: ${_vm.sizeButton}%`,
                                        attrs: {
                                          disabled:
                                            _vm.countDataToUpdate(
                                              _vm.dataPlanningSelect
                                            ) === "(00)"
                                              ? true
                                              : false,
                                          variant: "danger",
                                          size: "sm",
                                        },
                                        on: {
                                          click: _vm.onOpenModalToAllDelete,
                                        },
                                      },
                                      [
                                        _c(
                                          _vm.getLucideIcon(
                                            _vm.ICONS.TRASH.name
                                          ),
                                          {
                                            tag: "component",
                                            attrs: {
                                              color: "#FFFFFF",
                                              size: 20,
                                            },
                                          }
                                        ),
                                        _vm.$screen.width > 1540
                                          ? _c(
                                              "span",
                                              { staticClass: "text-btn" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      4,
                                                      "RAZ selection"
                                                    )
                                                  ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.countDataToUpdate(
                                                        _vm.dataPlanningSelect
                                                      )
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]
      ),
      _vm.openModalDeleteAll
        ? _c(
            "b-modal",
            {
              attrs: {
                "no-close-on-backdrop": "",
                title: _vm.FormMSG(
                  21,
                  "Do you want to remove these timesheets"
                ),
              },
              on: { hidden: _vm.handleCloseAllDeleteModal },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modal-footer",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row justify-content-end w-100 gap-1 w-1",
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "border-1",
                                    attrs: {
                                      variant: "outline-primary",
                                      size: "sm",
                                    },
                                    on: {
                                      click: _vm.handleCloseAllDeleteModal,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.FormMSG(22, "No")) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "border-1",
                                    attrs: {
                                      variant: "outline-danger",
                                      size: "sm",
                                    },
                                    on: { click: _vm.handleDeleteAllPlanning },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.FormMSG(23, "Yes")) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                142716676
              ),
              model: {
                value: _vm.openModalDeleteAll,
                callback: function ($$v) {
                  _vm.openModalDeleteAll = $$v
                },
                expression: "openModalDeleteAll",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex flex-column gap-1" },
                _vm._l(_vm.groupByData, function (item, index) {
                  return _vm.groupByData.length !== 0
                    ? _c(
                        "div",
                        {
                          key: index,
                          staticClass:
                            "d-flex flex-row align-items-center w-100 border-rd p-2",
                        },
                        [
                          _c("div", { staticClass: "w-50" }, [
                            _c("label", [_vm._v(_vm._s(item.fullName))]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "d-flex flex-column border-lf w-50",
                            },
                            _vm._l(item.data, function (children, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "text-1" },
                                [_vm._v(_vm._s(children.date))]
                              )
                            }),
                            0
                          ),
                        ]
                      )
                    : _vm._e()
                }),
                0
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }