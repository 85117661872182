<template>
	<b-modal
		v-model="isOpen"
		:title="FormMSG(59, 'Quick addition')"
		header-class="header-class-modal-doc-package"
		size="xl"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@cancel="emitEventClose"
		@hidden="emitEventClose"
		@ok.prevent="handleSubmit"
	>
		<div class="container-field form">
			<b-row class="mb-3">
				<b-col cols="4">
					<div class="d-flex text-color-rhapsody-in-blue">
						<div class="fs-16 fw-700">{{ FormMSG(541, 'Category : ') }}</div>
						<div class="fs-16 ml-3">
							{{ categorySelected && categorySelected.label ? setTextCategoriesWithActivities(categorySelected.label) : '' }}
						</div>
					</div>
				</b-col>
				<b-col cols="4">
					<div class="d-flex text-color-rhapsody-in-blue">
						<div class="fs-16 fw-700">{{ FormMSG(581, 'Activity : ') }}</div>
						<div class="fs-16 ml-3">{{ activitySelected ? activitySelected.short + ' - ' + activitySelected.name : '' }}</div>
					</div>
				</b-col>
			</b-row>

			<b-row>
				<b-col>
					<b-table
						v-if="$screen.width >= 992"
						selected-variant="primary"
						hover
						selectable
						select-mode="single"
						responsive="sm"
						ref="documentPackageTable"
						sticky-header="500px"
						:items="dataList"
						style="text-align: left"
						:fields="tableFields"
						bordered
						striped
						small
						head-variant="dark"
						:empty-text="FormMSG(250, 'No data found')"
						show-empty
					>
						<template #cell(amount)="{ item, index }">
							<b-form-group v-if="$v.dataList.$each.$iter[index]" class="mb-0">
								<b-input-group>
									<b-form-input
										v-model="$v.dataList.$each.$iter[index].amount.$model"
										:state="!$v.dataList.$each.$iter[index].amount.$error"
									/>
									<b-input-group-append>
										<b-input-group-text>
											<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
								<div v-if="$v.dataList.$each.$iter[index].amount.$error" class="invalid-feedback">
									{{ FormMSG(181, 'Amount is required or must greater 0') }}
								</div>
							</b-form-group>
						</template>
					</b-table>
				</b-col>
			</b-row>
		</div>

		<template #modal-footer="{ ok, cancel }">
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button size="md" variant="custom-outline-gray" style="margin-top: 5px" class="w-138-px mr-3" :disabled="loadingSubmit" @click="cancel" block>
					{{ FormMSG(43, 'Cancel') }}
				</b-button>
				<b-button size="md" variant="primary" class="w-138-px" :disabled="loadingSubmit" @click="ok" block>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-if="loadingSubmit" small />
						<div :class="`${loadingSubmit ? 'pl-2' : ''}`" style="margin-top: 1px">{{ FormMSG(42, 'Save') }}</div>
					</div>
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { validationMixin } from 'vuelidate';
import { required, minValue, decimal } from 'vuelidate/lib/validators';
import CurrencySvg from '@/components/Svg/Currency';
import _ from 'lodash';
import { addTsDaysUsersForPlanning } from '@/cruds/timesheet.crud';

export default {
	name: 'QuickAddition',

	mixins: [languageMessages, globalMixin, validationMixin],

	props: {
		open: { type: Boolean, default: false, required: false },
		externalData: { type: Array, default: () => [], required: true }
	},

	components: {
		CurrencySvg
	},

	data() {
		return {
			loadingSubmit: false,
			dataList: [],

			resultPrepareDataToSend: [],
			categorySelected: {
				label: ''
			},
			activitySelected: ''
		};
	},

	watch: {
		externalData: {
			handler(newVal) {
				this.dataList = [];
				if (newVal.length > 0) {
					this.categorySelected = newVal[0].category;
					this.activitySelected = newVal[0].activity;
					this.dataList = newVal;
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		tableFields() {
			return [
				{
					key: 'fullName',
					label: this.FormMSG(48, 'Name'),
					class: 'text-left w-20',
					sortable: true
				},
				{
					key: 'functionName',
					label: this.FormMSG(168, 'Function'),
					class: 'text-left w-20',
					sortable: true
				},
				{
					key: 'flagItemInfo',
					label: this.FormMSG(53, 'Section'),
					class: 'text-left w-30',
					formatter: (value, key, item) => {
						return `${item.flagItem.short} - ${item.flagItem.name}`;
					},
					sortable: false
				},
				{
					key: 'amount',
					label: this.FormMSG(51, 'Amount'),
					class: 'text-left w-30',
					formatter: (value, key, item) => {
						return 0;
					},
					sortable: false
				}
			];
		}
	},

	methods: {
		setTextCategoriesWithActivities(text) {
			if (this.activitySelected !== null) {
				let short = this.activitySelected.short;
				let textExact = text.toString().replace('$', short);
				return textExact;
			}
			return text;
		},
		async handleSubmit() {
			this.$v.dataList.$touch();
			this.loadingSubmit = true;

			if (this.$v.$invalid) {
				this.loadingSubmit = false;
			} else {
				const resultPrepareDataToSend = this.prepareDataToSend();

				await addTsDaysUsersForPlanning(resultPrepareDataToSend)
					.then((result) => {
						this.createToastForMobile(this.FormMSG(81, 'Success'), this.FormMSG(82, 'Data added successfully!'));

						this.loadingSubmit = false;
						this.emitEventSubmit();
						this.emitEventClose();
					})
					.catch((error) => {
						this.loadingSubmit = false;
						console.log({ QUICK_EDITION_ERROR: error });
					});
			}
		},
		prepareDataToSend() {
			let dataToSend = [];
			for (let i = 0; i < this.dataList.length; i++) {
				const option = this.dataList[i];
				for (let j = 0; j < option.dates.length; j++) {
					const element = option.dates[j];
					const findIndex = dataToSend.findIndex((dT) => {
						return +dT.userId === +option.userId && dT.date === element.split('/').reverse().join('-');
					});
					if (findIndex === -1) {
						dataToSend = _.concat(dataToSend, {
							userId: +option.userId,
							date: element.split('/').reverse().join('-'),
							category: +option.category.id,
							flagItemId: +option.activity.id,
							allowances: [
								{
									flagId: +option.flagItem.flagId,
									amount: +option.amount,
									value: +option.flagItem.value,
									category: +option.flagItem.category,
									paycode: +option.flagItem.paycode
								}
							]
						});
					} else {
						dataToSend[findIndex].allowances.push({
							flagId: +option.flagItem.flagId,
							amount: +option.amount,
							value: +option.flagItem.value,
							category: +option.flagItem.category,
							paycode: +option.flagItem.paycode
						});
					}
				}
			}

			return dataToSend;
		},
		emitEventSubmit() {
			this.$emit('quick-addition:submitted');
		},
		emitEventClose() {
			this.dataList = [];
			this.resultPrepareDataToSend = [];
			this.categorySelected = {
				label: ''
			};

			this.$emit('quick-addition:close');
		}
	},

	validations: {
		dataList: {
			$each: {
				amount: { required, minValue: minValue(1), decimal }
			}
		}
	}
};
</script>

<style scoped></style>
