<template>
	<b-modal
		v-model="isOpen"
		:title="titleCaption"
		header-class="header-class-modal-doc-package"
		size="md"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@cancel="emitEventClose"
		@hidden="emitEventClose"
		@show="handleShow"
		@ok.prevent="emitEventClose"
	>
		<b-collapse :visible="addDecor === false">
			<b-row>
				<b-col>
					<b-form-group :label="FormMSG(215, 'Decors')">
						<div class="d-flex">
							<v-select
								id="supplier-selector"
								v-model="locationIdSelected"
								:options="locationList"
								close-on-select
								label="setName"
								:reduce="(option) => option.id"
								:placeholder="FormMSG(452, 'Add or select a decor')"
								class="grow-flex-auto"
								:clearable="false"
							>
							</v-select>
							<div class="d-flex flex-row icon-group-border-append">
								<div
									:style="`width: 34px`"
									class="cursor-pointer d-inline-flex justify-content-center align-items-center"
									v-b-tooltip.hover.top
									:title="FormMSG(157, 'Add new decor')"
									@click="handleClickAddNewDecor"
								>
									<component :is="getLucideIcon('PlusCircle')" color="#00A09C" :size="18" :stroke-width="2.25" />
								</div>
							</div>
						</div>
					</b-form-group>
				</b-col>
			</b-row>
		</b-collapse>
		<b-collapse :visible="addDecor === true">
			<b-form-group :label="FormMSG(198, 'Name of decor')" :state="!$v.decorName.$error">
				<b-form-input v-model="$v.decorName.$model" :placeholder="FormMSG(199, 'Enter name')" :state="!$v.decorName.$error" />
				<div v-if="$v.decorName.$error" class="invalid-feedback">
					{{ FormMSG(487, 'Decor name is required') }}
				</div>
			</b-form-group>
		</b-collapse>

		<template #modal-footer="{ ok, cancel }">
			<div>
				<div v-if="addDecor === true" class="w-100 d-flex justify-content-end align-items-center">
					<b-button size="md" variant="custom-outline-gray" class="w-138-px mr-3" :disabled="loadingSubmit" @click="cancel" block>
						{{ FormMSG(135, 'Cancel') }}
					</b-button>
					<b-button size="md" variant="primary" style="margin-top: 5px" class="w-138-px" :disabled="loadingSubmit" @click="handleClickSave" block>
						{{ FormMSG(136, 'Save') }}
					</b-button>
				</div>
				<div v-else class="w-100 d-flex justify-content-end align-items-center">
					<b-button size="md" variant="danger" class="w-138-px mr-3" :disabled="loadingSubmit" @click="ok" block>
						{{ FormMSG(125, 'OFF') }}
					</b-button>
					<b-button size="md" variant="custom-outline-gray" style="margin-top: 5px" class="w-138-px" :disabled="loadingSubmit" @click="cancel" block>
						{{ FormMSG(128, 'Close') }}
					</b-button>
				</div>
			</div>
		</template>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import { newLocation, updateLocation } from '@/cruds/locations.crud';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
	name: 'EditLocation',

	mixins: [languageMessages, globalMixin, validationMixin],

	props: {
		open: { type: Boolean, default: false, required: true },
		dataForEdit: { type: Object, default: () => ({}), required: true }
	},

	data() {
		return {
			loadingSubmit: false,
			locationIdSelected: 0,

			locationList: [],
			addDecor: false,
			decorName: ''
		};
	},

	validations: {
		decorName: { required }
	},

	computed: {
		...mapGetters({
			projectLocationsList: 'locations/projectLocationsList'
		}),

		titleCaption() {
			if (JSON.stringify(this.dataForEdit) !== '{}') {
				return this.FormMSG(59, 'Manage decor') + ' (' + moment(new Date(this.dataForEdit.locations.date)).format('YYYY-MM-DD') + ')';
			}
		},

		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		}
	},

	watch: {
		dataForEdit: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}') {
					this.locationIdSelected = +newVal.locations.id === 0 ? null : newVal.locations.id;
				}
			},
			immediate: true,
			deep: true
		},
		projectLocationsList: {
			handler(newVal) {
				this.locationList = _.cloneDeep(newVal);
			},
			deep: true
		}
	},

	methods: {
		...mapActions({
			getProjectLocationList: 'locations/getProjectLocationList'
		}),

		async handleClickSave() {
			this.$v.$touch();

			if (this.$v.$invalid) return;

			this.loadingSubmit = true;
			const resultNewLocation = await newLocation();
			const date = moment(new Date(this.dataForEdit.locations.date)).format('YYYY-MM-DD') + 'T00:00:00Z';
			const resultUpdLocation = await updateLocation(resultNewLocation.id, {
				setName: this.decorName,
				getInDate: date,
				getOutDate: date,
				locationDates: {
					date
				}
			});

			this.$emit('edit-location:add-success');
			this.createToastForMobile(this.FormMSG(451, 'Success'), this.FormMSG(452, 'Decor added successfully!'));

			this.locationList.push(resultUpdLocation);

			this.$nextTick(() => {
				this.locationIdSelected = resultUpdLocation.id;
			});

			this.addDecor = false;
			this.loadingSubmit = false;

			this.emitEventClose();
		},

		handleClickAddNewDecor() {
			this.addDecor = true;
		},

		async handleShow() {
			await this.getProjectLocationList();
		},
		emitEventClose() {
			this.locationIdSelected = 0;
			this.addDecor = false;

			this.$emit('edit-location:close');
		}
	}
};
</script>
