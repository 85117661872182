var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.error, warning: _vm.warning } },
    [
      _c("custom-loading-planning", {
        attrs: {
          loading: _vm.isLoading,
          label: _vm.FormMSG(2, "Prepare data of fimalac"),
        },
      }),
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          { staticClass: "form" },
          [
            _c(
              "b-row",
              { staticClass: "animated fadeIn" },
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12", xl: "12" } },
                  [
                    _c("b-card", { attrs: { "no-body": "" } }, [
                      _c(
                        "div",
                        { staticClass: "p-4" },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-alert",
                                    {
                                      attrs: {
                                        show: _vm.showTooltipsHelpPlanning,
                                        variant: "info",
                                        dismissible: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex mb-2" },
                                        [
                                          _c(_vm.getLucideIcon("Info"), {
                                            tag: "component",
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "ml-2 fw-700 fs-16",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    145,
                                                    "Planning tool"
                                                  )
                                                ) + ":"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "ml-2" }, [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(
                                                164,
                                                "1- By default the tool shows all users of the project, but you can always filtered them by selecting a departement."
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]),
                                      _c("div", { staticClass: "ml-2" }, [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(
                                                165,
                                                '2- To add a new planning(s) (i.e: To activate the button "Add to selection"):'
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]),
                                      _vm.isProduction
                                        ? _c("div", { staticClass: "ml-3" }, [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    166,
                                                    "- You must select one category, and one activity"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ])
                                        : _vm._e(),
                                      _c("div", { staticClass: "ml-3" }, [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(
                                                167,
                                                "- You must check one or more PER DIEM » flag(s)"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]),
                                      _c("div", { staticClass: "ml-3" }, [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(
                                                168,
                                                "- And the most important, selecting the planning of user(s) in the table (exactly as Excel fonctionnality's)"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mb-2" },
                            [
                              _c("b-col", { attrs: { cols: "8" } }, [
                                _c("div", { staticClass: "pb-2 mr-1" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex flex-row",
                                      staticStyle: { gap: "10px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-row justify-content-start align-items-center gp-5",
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "status-not-submitted",
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "col-form-label" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    18,
                                                    "Not submitted"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-row justify-content-start align-items-center gp-5",
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "status-info",
                                          }),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(13, "Submitted")
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-row justify-content-start align-items-center gp-5",
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "status-pending",
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "col-form-label" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    19,
                                                    "Validated HOD"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-row justify-content-start align-items-center gp-5",
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "status-validated",
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "col-form-label" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(15, "Validated")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-row justify-content-start align-items-center gp-5",
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "status-refused",
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "col-form-label" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(16, "Refused")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm.isProduction
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-row justify-content-start align-items-center gp-5",
                                            },
                                            [
                                              _c("div", {
                                                staticClass: "solve-status",
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "col-form-label",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        20,
                                                        "Line to solved"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "d-flex flex-row align-items-center justify-content-end",
                                  attrs: { cols: "4" },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "float-right",
                                      attrs: { variant: "success" },
                                      on: {
                                        click: function ($event) {
                                          _vm.showManageUsersRolesDialog = true
                                        },
                                      },
                                    },
                                    [
                                      _c(_vm.getLucideIcon("UserCog"), {
                                        tag: "component",
                                        attrs: { size: 20 },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.FormMSG(
                                              417,
                                              "Manage user's roles"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("header-planning-fimalac", {
                                attrs: {
                                  id: _vm.timecards_One,
                                  "set-filter": (item) => _vm.setFilter(item),
                                  filter: _vm.filter,
                                  onSearch: _vm.onSearch,
                                  selectDate: _vm.allDataTimeCards,
                                  formChanged: (date, idTable) =>
                                    _vm.formChanged(date, idTable),
                                  formChangedPlanningView: (date, idTable) =>
                                    _vm.formChangedPlanningView(date, idTable),
                                  dataPlanningSelect: _vm.dataPlanningSelect,
                                  "show-modal": _vm.showModal,
                                  isNavigateInitDate: _vm.isNavigateInitDate,
                                  dataGlobal: _vm.TimeCards,
                                },
                                on: {
                                  "month:Selected":
                                    _vm.handleSelectedFormFilter,
                                  "day:Selected": _vm.handleSelectedDay,
                                  handleSelectDepartementChange:
                                    _vm.handleSelectDepartementChange,
                                  handleSelectFunctionChange:
                                    _vm.handleSelectFunctionChange,
                                  setIsNavigateInitDate:
                                    _vm.setIsNavigateInitDate,
                                  resetDataAfterAddUpdate:
                                    _vm.resetDataAfterAddUpdate,
                                  "refersh-on-delete-not-submitted-all":
                                    _vm.handleRefreshData,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("HeaderFlag", {
                                ref: "headerFlag",
                                attrs: {
                                  dataPlanningSelect: _vm.dataPlanningSelect,
                                  DateDefaultSelect: _vm.DateDefaultSelect,
                                  favoriteProjectFlagItems:
                                    _vm.flagFavoritesBookmark,
                                  isNotFavoriteProjectFlagItems:
                                    _vm.flagFavoritesNotBookmark,
                                  currentValidationLevel:
                                    _vm.currentValidationLevel,
                                  fromComponent: _vm.fromComponent,
                                  activities: _vm.activities,
                                  categories: _vm.categories,
                                  dataGlobal: _vm.TimeCards,
                                },
                                on: {
                                  changeDateDefaultSelect:
                                    _vm.changeDateDefaultSelect,
                                  "on-add-data:selection":
                                    _vm.handleOnAddDataSelection,
                                  "on-preference:open": _vm.handleOpenPrefrence,
                                  "refersh-on-delete-not-submitted-all":
                                    _vm.handleRefreshData,
                                  "close-planning-modal-delete-all":
                                    _vm.hideModal,
                                  "on-active-is-loading": _vm.onActiveIsLoading,
                                  "init-persist-planning":
                                    _vm.handleInitPlanningSelect,
                                  validateAllPlanning: _vm.validateAllPlanning,
                                  "handle-change-affichage":
                                    _vm.onHandleChangeAffichage,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "button-label",
                                    fn: function () {
                                      return [
                                        _vm.loadingAddTsDay
                                          ? _c("b-spinner", {
                                              attrs: { small: "" },
                                            })
                                          : _vm._e(),
                                        _c(
                                          _vm.getLucideIcon(
                                            _vm.ICONS.PLUS_CIRCLE_II.name
                                          ),
                                          {
                                            tag: "component",
                                            attrs: {
                                              color:
                                                _vm.ICONS.PLUS_CIRCLE_II.color,
                                              size: 20,
                                            },
                                          }
                                        ),
                                        _vm.$screen.width > 1540
                                          ? _c(
                                              "span",
                                              { staticClass: "text-btn" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      156,
                                                      "Add to selection"
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "divider-horizontal" }),
                          _c(
                            "div",
                            { staticClass: "planning" },
                            [
                              _c("planning-view-tables", {
                                attrs: {
                                  id: _vm.timecards_One,
                                  selectDate: _vm.allDataTimesheets,
                                  hover: true,
                                  currentpage: _vm.currentPage,
                                  perpage: _vm.perPage,
                                  hv: _vm.hv,
                                  tsfields: _vm.fieldsTimeCardsPlanning,
                                  timesheets: _vm.TimeCards,
                                  DateDefaultSelect: _vm.DateDefaultSelect,
                                  open: _vm.showAdvancedEditModal,
                                  isPersistDataPlanning:
                                    _vm.isPersistDataPlanning,
                                  initCustomFilter: _vm.initCustomFilter,
                                  hideContextMenuPlanning:
                                    _vm.hideContextMenuPlanning,
                                  initPlanningSelect: _vm.initPlanningSelect,
                                  isAnalyTicalCode: _vm.isAnalyTicalCode,
                                  isPrestations: _vm.isPrestations,
                                  isPerDiem: _vm.isPerDiem,
                                },
                                on: {
                                  setDataSizeModalPlanning:
                                    _vm.setDataSizeModalPlanning,
                                  sendDataModalPlanning:
                                    _vm.sendDataModalPlanning,
                                  showModalPlanningContextUpdate:
                                    _vm.showModalPlanningContextUpdate,
                                  showModalAdvancedEdition:
                                    _vm.handleShowModalAdvancedEdition,
                                  changeDateDefaultSelect:
                                    _vm.changeDateDefaultSelect,
                                  getDataFromPagePlanning:
                                    _vm.getDataFromPagePlanning,
                                  validateTsDays: _vm.validateTsDays,
                                  validateAllTsDays: _vm.validateAllTsDays,
                                  deleteItemPlanning: _vm.deleteItemPlanning,
                                  replacement: _vm.replacement,
                                  handleRefreshReject: _vm.handleRefreshReject,
                                  sendForValidationPlanning:
                                    _vm.sendForValidationPlanning,
                                  "filter-custom-user": _vm.handleCustomFilter,
                                  "on-open-modal-new-user":
                                    _vm.handleAddNewUser,
                                  "reset-init-custom-filter":
                                    _vm.resetInitCustomFilter,
                                  hideContextMenuPlanningInit:
                                    _vm.handleHideContextMenuPlanning,
                                  "restore-init-planning-select":
                                    _vm.handleRestoredinitPlanningSelect,
                                  setCurrentValidationLevel:
                                    _vm.setCurrentValidationLevel,
                                  "planning-view-tables:edit-location":
                                    _vm.handlePlanningViewTablesEditLocation,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row w-100 gap-1 p-4" },
                            [
                              _c("advanced-edit", {
                                attrs: {
                                  open: _vm.showAdvancedEditModal,
                                  "external-data": _vm.listToEdit,
                                  "external-flag-items":
                                    _vm.listFlagItemsToEdit,
                                },
                                on: {
                                  "advanced-edit:close":
                                    _vm.handleAdvancedEditClose,
                                  "advanced-edit:submited":
                                    _vm.handleAdvancedEditSubmited,
                                },
                              }),
                              _c("quick-addition", {
                                attrs: {
                                  open: _vm.showQuickAdditionModal,
                                  "external-data": _vm.dataToAdd,
                                },
                                on: {
                                  "quick-addition:close":
                                    _vm.handleQuickAdditionClose,
                                  "quick-addition:submitted":
                                    _vm.handleQuickAdditionSubmitted,
                                },
                              }),
                              _c("substitution", {
                                attrs: {
                                  open: _vm.showSubstitutionModal,
                                  "external-data": _vm.editData,
                                },
                                on: {
                                  "substitution:close":
                                    _vm.handleSubstitutionClose,
                                  "substitution:submitted":
                                    _vm.handleSubstitutionSubmitted,
                                },
                              }),
                              _c("user-advanced-edit", {
                                attrs: {
                                  open: _vm.showUserAdvancedEditModal,
                                  "external-data": _vm.editData,
                                  "is-regissor": _vm.isProduction === false,
                                },
                                on: {
                                  "user-advanced-edit:submitted":
                                    _vm.handleUserAdvancedEditSubmitted,
                                  "user-advanced-edit:close":
                                    _vm.handleUserAdvancedEditClose,
                                },
                              }),
                              _c("preferences", {
                                attrs: {
                                  open: _vm.showPreferencesModal,
                                  "refresh-modal": _vm.refreshModalPreferences,
                                },
                                on: {
                                  "preferences:close":
                                    _vm.handlePreferencesClose,
                                  "preferences:refresh-end":
                                    _vm.handlePreferencesRefreshEnd,
                                  "is-success-to-refresh":
                                    _vm.handleRefreshData,
                                },
                              }),
                              _c("custom-filter", {
                                attrs: {
                                  open: _vm.openCustomFilter,
                                  "refresh-modal": _vm.refreshModalCustomFilter,
                                },
                                on: {
                                  "custom-filter:success":
                                    _vm.handleRefreshCustomFilter,
                                  "custom-filter:close":
                                    _vm.handleCustomFilterClose,
                                  "custom-filter:refresh-end":
                                    _vm.handleCustomFilterRefreshEnd,
                                },
                              }),
                              _c("invite-new-user", {
                                attrs: { open: _vm.openModalNewUser },
                                on: {
                                  "invite-new-user:users-added":
                                    _vm.handleInviteNewUserUsersAdded,
                                  "invite-new-user:close":
                                    _vm.handleInviteNewUserClose,
                                },
                              }),
                              _c("manage-users-roles", {
                                attrs: {
                                  open: _vm.showManageUsersRolesDialog,
                                  "refresh-modal": _vm.refreshModalManageUsers,
                                },
                                on: {
                                  "manage-users-roles:close":
                                    _vm.handleManageUsersRolesClose,
                                  "manage-users-roles:refresh-end":
                                    _vm.handleManageUsersRolesRefreshEnd,
                                },
                              }),
                              _c("incomplete", {
                                attrs: {
                                  open: _vm.successModalIncomplete,
                                  data: _vm.incompleteData,
                                  date: _vm.DateDefaultSelect,
                                },
                                on: {
                                  "modal-incomplete:close":
                                    _vm.handleIncompleted,
                                },
                              }),
                              _c("edit-location", {
                                attrs: {
                                  open: _vm.showModalEditLocation,
                                  "data-for-edit": _vm.dataForEditLocation,
                                },
                                on: {
                                  "edit-location:close":
                                    _vm.handleEditLocationClose,
                                  "edit-location:add-success":
                                    _vm.handleEditLocationAddSuccess,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }