var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "w-100" },
        [
          _vm.tsfields.length !== 0
            ? _c(
                "b-table-simple",
                {
                  ref: "my-table",
                  style: { "min-height": _vm.minHeight + "px" },
                  attrs: {
                    small: "",
                    "caption-top": "",
                    responsive: "",
                    "sticky-header": `${_vm.getHeaderHeight}px`,
                    "sticky-column": "first",
                  },
                },
                [
                  _c(
                    "b-thead",
                    [
                      _c(
                        "b-tr",
                        [
                          _c(
                            "b-th",
                            {
                              staticClass: "text-center middle",
                              staticStyle: {
                                position: "sticky",
                                left: "0",
                                "z-index": "3",
                                "background-color": "#00a09c",
                                border: "1px solid #dee2e6",
                                color: "#ffff",
                              },
                            },
                            [
                              _c("div", { staticClass: "w-100" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-row align-items-center justify-content-center h-50 gap-10",
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.FormMSG(87, "Member of team")
                                        )
                                      ),
                                    ]),
                                    _c("div", [
                                      _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName:
                                                "v-b-tooltip.hover.bottom.html",
                                              value: `${_vm.FormMSG(
                                                100,
                                                "Add new user"
                                              )}`,
                                              expression:
                                                "`${FormMSG(100, 'Add new user')}`",
                                              modifiers: {
                                                hover: true,
                                                bottom: true,
                                                html: true,
                                              },
                                            },
                                          ],
                                          staticClass:
                                            "btn-transparent text-color-rhapsody-in-blue",
                                          on: { click: _vm.handleAddNewUser },
                                        },
                                        [
                                          _c(
                                            _vm.getLucideIcon(
                                              _vm.ICONS.PLUS_CIRCLE_USER.name
                                            ),
                                            {
                                              tag: "component",
                                              attrs: {
                                                color:
                                                  _vm.ICONS.CHEVRONS_LEFT.color,
                                                size: 18,
                                              },
                                            }
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-row align-items-center justify-content-center h-50",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "active-filter-custom",
                                        staticStyle: { width: "150px" },
                                      },
                                      [
                                        _c("treeselect", {
                                          attrs: {
                                            options: _vm.mapCustomFilter,
                                            value: _vm.selectedFilter,
                                            placeholder: _vm.FormMSG(
                                              102,
                                              "Select filter"
                                            ),
                                          },
                                          on: { input: _vm.handleChangeFilter },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "value-label",
                                                fn: function ({ node }) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      attrs: {
                                                        title: node.label,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "treeselect-label",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.showLimitText(
                                                                node.label
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                },
                                              },
                                              {
                                                key: "option-label",
                                                fn: function ({ node }) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-items-center justify-content-center",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "label-select treeselect-label",
                                                          attrs: {
                                                            title: node.label,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(node.label)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2091592026
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _vm._l(_vm.tsfields.slice(1), function (item) {
                            return _c(
                              "b-th",
                              {
                                key: _vm.generateSecureId(`${item.key}`),
                                staticClass: "text-center middle",
                                staticStyle: {
                                  "background-color": "#00a09c",
                                  border: "1px solid #dee2e6",
                                  color: "#ffff",
                                },
                              },
                              [
                                _c("div", { staticStyle: { width: "150px" } }, [
                                  _c("div", [_vm._v(_vm._s(item.label))]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-row justify-content-center cursor-active",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName:
                                                "v-b-tooltip.hover.bottom.html",
                                              value:
                                                _vm.handleShowLocationTooltip(
                                                  item
                                                ),
                                              expression:
                                                "handleShowLocationTooltip(item)",
                                              modifiers: {
                                                hover: true,
                                                bottom: true,
                                                html: true,
                                              },
                                            },
                                          ],
                                          staticClass: "header-flag-status",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.setTimeLocation(item))
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "show-edit-flag",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleOpenFlagHeader(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                _vm.getLucideIcon(
                                                  _vm.ICONS.CHECK_SQUARE.name
                                                ),
                                                {
                                                  tag: "component",
                                                  attrs: {
                                                    color: "#06263e",
                                                    size: 16,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          }),
                          _vm.isProduction
                            ? _c(
                                "b-th",
                                {
                                  staticClass: "text-center middle",
                                  staticStyle: {
                                    position: "sticky",
                                    right: "0",
                                    "z-index": "3",
                                    "background-color": "#00a09c",
                                    border: "1px solid #dee2e6",
                                    color: "#ffff",
                                  },
                                },
                                [
                                  _vm.showVerified
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-column justify-content-center transition-collapse-rt-lf",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-row justify-content-center",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.FormMSG(99, "Somme"))
                                              ),
                                            ]
                                          ),
                                          _vm.showVerified
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-column justify-content-center",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-row justify-content-center",
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn-transparent text-color-rhapsody-in-blue",
                                                          on: {
                                                            click:
                                                              _vm.handleShowContentVerified,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            _vm.getLucideIcon(
                                                              _vm.ICONS
                                                                .CHEVRONS_RIGHT
                                                                .name
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  _vm.ICONS
                                                                    .CHEVRONS_RIGHT
                                                                    .color,
                                                                size: 16,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row justify-content-center",
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn-transparent text-color-rhapsody-in-blue",
                                                      on: {
                                                        click:
                                                          _vm.handleShowContentVerified,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          _vm.ICONS
                                                            .CHEVRONS_LEFT.name
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            color:
                                                              _vm.ICONS
                                                                .CHEVRONS_LEFT
                                                                .color,
                                                            size: 16,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-column transition-collapse-lf-rt",
                                          staticStyle: {
                                            display: "flex",
                                            "background-color": "#78807a",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-row justify-content-start",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn-transparent text-color-rhapsody-in-blue",
                                                  on: {
                                                    click:
                                                      _vm.handleShowContentVerified,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      _vm.ICONS.CHEVRONS_LEFT
                                                        .name
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color:
                                                          _vm.ICONS
                                                            .CHEVRONS_LEFT
                                                            .color,
                                                        size: 16,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-tbody",
                    {
                      ref: "tableRef",
                      staticClass: "table-body",
                      on: {
                        mousedown: _vm.startScrollingLeft,
                        mouseup: _vm.stopScrollingLeft,
                      },
                    },
                    [
                      _vm.setAllTimesheets.length === 0
                        ? _c(
                            "b-tr",
                            [
                              _c(
                                "b-td",
                                {
                                  staticClass:
                                    "text-center border-left init-background",
                                  staticStyle: {
                                    border:
                                      "0.005rem solid rgba(226, 230, 235, 0.85)",
                                    height: "50px",
                                  },
                                  attrs: {
                                    colspan:
                                      _vm.tsfields.length === 0
                                        ? 2
                                        : _vm.tsfields.length + 1,
                                  },
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "text-muted fs-14 w-100" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(2, "No data available")
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(_vm.setAllTimesheets, function (item) {
                        return _vm.setAllTimesheets.length !== 0
                          ? _c(
                              "b-tr",
                              {
                                key: _vm.generateSecureId(`${Math.random()}`),
                                staticStyle: { background: "#f3f4f5" },
                              },
                              [
                                _c(
                                  "b-td",
                                  {
                                    staticClass: "sticky-column-body-left",
                                    staticStyle: {
                                      border:
                                        "0.005rem solid rgba(226, 230, 235, 0.85)",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-row justify-content-start align-items-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-column justify-content-start p-1",
                                            staticStyle: {
                                              width: "150px",
                                              height: "60px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-bold fs-14",
                                              },
                                              [_vm._v(_vm._s(item.fullName))]
                                            ),
                                            _c("div", [
                                              _vm._v(_vm._s(item.functionName)),
                                            ]),
                                          ]
                                        ),
                                        _vm.isProduction
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-column justify-content-start align-items-center pd-1",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-row w-100 show-hover",
                                                  },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "b-tooltip",
                                                            rawName:
                                                              "v-b-tooltip.hover.bottom.html",
                                                            value: `${_vm.FormMSG(
                                                              153,
                                                              "Valid all"
                                                            )}`,
                                                            expression:
                                                              "`${FormMSG(153, 'Valid all')}`",
                                                            modifiers: {
                                                              hover: true,
                                                              bottom: true,
                                                              html: true,
                                                            },
                                                          },
                                                        ],
                                                        staticClass:
                                                          "btn bg-transparent border-0",
                                                        attrs: {
                                                          disabled:
                                                            _vm.checkValidatedAll(
                                                              item
                                                            ),
                                                          size: "sm",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.validateAllTsDays(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            _vm.ICONS
                                                              .CHECK_SQUARE.name
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                _vm.ICONS
                                                                  .CHECK_SQUARE
                                                                  .color,
                                                              size: 16,
                                                              "stroke-width": 2.25,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.isProduction === false
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-column justify-content-start align-items-center pd-1",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-row w-100 show-hover",
                                                  },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "b-tooltip",
                                                            rawName:
                                                              "v-b-tooltip.hover.bottom.html",
                                                            value: `${_vm.FormMSG(
                                                              13,
                                                              "Send all for validation"
                                                            )}`,
                                                            expression:
                                                              "`${FormMSG(13, 'Send all for validation')}`",
                                                            modifiers: {
                                                              hover: true,
                                                              bottom: true,
                                                              html: true,
                                                            },
                                                          },
                                                        ],
                                                        staticClass:
                                                          "btn bg-transparent border-0",
                                                        attrs: {
                                                          disabled:
                                                            _vm.checkNotSubmitted(
                                                              item
                                                            ),
                                                          size: "sm",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.sendAllForValidation(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            _vm.ICONS.SEND.name
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                _vm.ICONS.SEND
                                                                  .color,
                                                              size: 16,
                                                              "stroke-width": 2.25,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._l(_vm.tsfields.slice(1), function (data) {
                                  return _c(
                                    "b-td",
                                    {
                                      key: _vm.generateSecureId(`${data.key}`),
                                      class: _vm.checkCell(
                                        data.key,
                                        item.tsDays
                                      )
                                        ? "init-background hover-not-empty"
                                        : "edit-background hover-is-empty",
                                      staticStyle: {
                                        border:
                                          "0.005rem solid rgba(226, 230, 235, 0.85)",
                                      },
                                      attrs: {
                                        id: _vm.generateSecureId(
                                          `${
                                            item.id +
                                            item.fullName +
                                            item.departmentName +
                                            item.functionName +
                                            data.key
                                          }`
                                        ),
                                      },
                                      on: {
                                        contextmenu: function ($event) {
                                          $event.preventDefault()
                                          _vm.checkCell(
                                            data.key,
                                            item.tsDays
                                          ) && _vm.isSelectMultiple === false
                                            ? _vm.showContextMenuOne(
                                                $event,
                                                item,
                                                _vm.getDataOnCell(
                                                  data.key,
                                                  item,
                                                  item.id
                                                )
                                              )
                                            : _vm.showContextMenuMultiple(
                                                $event,
                                                item,
                                                _vm.getDataOnCell(
                                                  data.key,
                                                  item,
                                                  item.id
                                                )
                                              )
                                        },
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return ((e) =>
                                            _vm.handleAllActionPlanning(
                                              e,
                                              item,
                                              data.key,
                                              data
                                            )).apply(null, arguments)
                                        },
                                        mousedown: (e) => _vm.startDrag(e),
                                        mouseover: (e) =>
                                          _vm.selectCell(
                                            e,
                                            item,
                                            data.key,
                                            data
                                          ),
                                        mouseup: (e) => _vm.endDrag(e),
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-column content-item-cell-fixed",
                                          class: {
                                            "content-item-cell-is-prod":
                                              _vm.isProduction &&
                                              _vm.checkCell(
                                                data.key,
                                                item.tsDays
                                              ) &&
                                              _vm.isShowBarStatus(
                                                data.key,
                                                item.tsDays
                                              ),
                                          },
                                        },
                                        [
                                          _vm.checkCell(data.key, item.tsDays)
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-column justify-content-center",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-row",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-row justify-content-start ml-1 mr-1 w-50",
                                                        },
                                                        [
                                                          _vm.isRest(
                                                            _vm
                                                              .fimalacCellData(
                                                                data.key,
                                                                item.tsDays
                                                              )
                                                              .slice(2)
                                                          ) !== "+0"
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "b-tooltip",
                                                                      rawName:
                                                                        "v-b-tooltip.hover.left.html",
                                                                      value:
                                                                        _vm.tooltipContentOvertime(
                                                                          data.key,
                                                                          item.tsDays
                                                                        ),
                                                                      expression:
                                                                        "tooltipContentOvertime(data.key, item.tsDays)",
                                                                      modifiers:
                                                                        {
                                                                          hover: true,
                                                                          left: true,
                                                                          html: true,
                                                                        },
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "mr-1",
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "12px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "badge badge-info",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.isRest(
                                                                            _vm
                                                                              .fimalacCellData(
                                                                                data.key,
                                                                                item.tsDays
                                                                              )
                                                                              .slice(
                                                                                2
                                                                              )
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-row justify-content-end w-50",
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "b-tooltip",
                                                                  rawName:
                                                                    "v-b-tooltip.hover.left.html",
                                                                  value: `${_vm.getLabelStatus(
                                                                    _vm.getStatus(
                                                                      data.key,
                                                                      item.tsDays
                                                                    )
                                                                  )}`,
                                                                  expression:
                                                                    "`${getLabelStatus(getStatus(data.key, item.tsDays))}`",
                                                                  modifiers: {
                                                                    hover: true,
                                                                    left: true,
                                                                    html: true,
                                                                  },
                                                                },
                                                              ],
                                                              staticClass:
                                                                "btn-transparent text-color-rhapsody-in-blue",
                                                            },
                                                            [
                                                              _c("div", {
                                                                class: `status-${_vm.getStatusColor(
                                                                  _vm.getStatus(
                                                                    data.key,
                                                                    item.tsDays
                                                                  )
                                                                )}`,
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "0.7rem",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.isPerDiem
                                                    ? _c("div", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column mt-1 pr-1 pl-1",
                                                            staticStyle: {
                                                              gap: "3px",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm
                                                              .fimalacCellData(
                                                                data.key,
                                                                item.tsDays
                                                              )
                                                              .slice(0, 2),
                                                            function (
                                                              item,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: index,
                                                                  staticClass:
                                                                    "d-flex justify-content-center align-items-center pr-1 pl-1 text-black",
                                                                  style: `background-color: ${item.color}; border-radius: 4px;height: 20px;font-size: 10px`,
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      style: `color: ${
                                                                        _vm.isColorLight(
                                                                          item.color
                                                                        )
                                                                          ? "black"
                                                                          : "white"
                                                                      }; font-weight: bold`,
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.getValue(
                                                                            item
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm.isAnalyTicalCode
                                                    ? _c("div", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column mt-1 pr-1 pl-1",
                                                            staticStyle: {
                                                              gap: "3px",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm
                                                              .fimalacCellData(
                                                                data.key,
                                                                item.tsDays
                                                              )
                                                              .slice(0, 2),
                                                            function (
                                                              item,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "b-tooltip",
                                                                      rawName:
                                                                        "v-b-tooltip.hover.left.html",
                                                                      value: `${_vm.getAnaliticalLabel(
                                                                        item
                                                                      )}`,
                                                                      expression:
                                                                        "`${getAnaliticalLabel(item)}`",
                                                                      modifiers:
                                                                        {
                                                                          hover: true,
                                                                          left: true,
                                                                          html: true,
                                                                        },
                                                                    },
                                                                  ],
                                                                  key: index,
                                                                  staticClass:
                                                                    "d-flex justify-content-center align-items-center pr-1 pl-1 text-black",
                                                                  style: `background-color: ${item.color}; border-radius: 4px;height: 20px;font-size: 10px`,
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      style: `color: ${
                                                                        _vm.isColorLight(
                                                                          item.color
                                                                        )
                                                                          ? "black"
                                                                          : "white"
                                                                      }; font-weight: bold`,
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.getAnalitical(
                                                                            item
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm.isPrestations
                                                    ? _c("div", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column mt-1 pr-1 pl-1",
                                                            staticStyle: {
                                                              gap: "3px",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm
                                                              .fimalacCellData(
                                                                data.key,
                                                                item.tsDays
                                                              )
                                                              .slice(0, 2),
                                                            function (
                                                              item,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "b-tooltip",
                                                                      rawName:
                                                                        "v-b-tooltip.hover.left.html",
                                                                      value: `${_vm.getPrestationsLabel(
                                                                        item
                                                                      )}`,
                                                                      expression:
                                                                        "`${getPrestationsLabel(item)}`",
                                                                      modifiers:
                                                                        {
                                                                          hover: true,
                                                                          left: true,
                                                                          html: true,
                                                                        },
                                                                    },
                                                                  ],
                                                                  key: index,
                                                                  staticClass:
                                                                    "d-flex justify-content-center align-items-center pr-1 pl-1 text-black",
                                                                  style: `background-color: ${item.color}; border-radius: 4px;height: 20px;font-size: 10px`,
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      style: `color: ${
                                                                        _vm.isColorLight(
                                                                          item.color
                                                                        )
                                                                          ? "black"
                                                                          : "white"
                                                                      }; font-weight: bold;`,
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.getPrestations(
                                                                            item
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                _vm.isProduction && _vm.showVerified
                                  ? _c(
                                      "b-td",
                                      {
                                        staticClass:
                                          "sticky-column-body-right transition-collapse-rt-lf",
                                        staticStyle: {
                                          background: "rgb(202, 208, 215)",
                                          width: "14px",
                                          height: "76px",
                                          border:
                                            "0.005rem solid rgba(226, 230, 235, 0.85)",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-row justify-content-center align-items-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-column justify-content-center align-items-center pd-1",
                                                staticStyle: {
                                                  width: "100px",
                                                  height: "70px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-row justify-content-center align-items-center w-100 show-hover",
                                                  },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "b-tooltip",
                                                            rawName:
                                                              "v-b-tooltip.hover.left.html",
                                                            value:
                                                              _vm.isProduction &&
                                                              _vm.handleShowVerifiedTooltip(
                                                                item
                                                              ),
                                                            expression:
                                                              "isProduction && handleShowVerifiedTooltip(item)",
                                                            modifiers: {
                                                              hover: true,
                                                              left: true,
                                                              html: true,
                                                            },
                                                          },
                                                        ],
                                                        staticClass:
                                                          "btn bg-transparent border-0",
                                                        attrs: {
                                                          disabled:
                                                            _vm.checkSomme(
                                                              _vm.totalSomme(
                                                                item
                                                              )
                                                            ),
                                                          size: "sm",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            _vm.ICONS.INFO.name
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                _vm.ICONS.INFO
                                                                  .color,
                                                              size: 16,
                                                              "stroke-width": 2.25,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold fs-14 mt-1",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.totalSomme(item)
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.isProduction && _vm.showVerified === false
                                  ? _c(
                                      "b-td",
                                      {
                                        staticClass:
                                          "sticky-column-body-right-init transition-collapse-lf-rt",
                                        staticStyle: {
                                          background: "#cad0d7",
                                          width: "14px",
                                          height: "76px",
                                          "border-radius": "8px 0px 0px 8px",
                                          border:
                                            "0.005rem solid rgba(226, 230, 235, 0.85)",
                                          "margin-left": "22px",
                                        },
                                        on: {
                                          click: _vm.handleShowContentVerified,
                                        },
                                      },
                                      [
                                        _c("div", {
                                          staticClass:
                                            "d-flex flex-row justify-content-center align-items-center",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e()
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row justify-content-center align-items-center card-gray text-muted fs-14 w-100",
                },
                [
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(this.FormMSG(2, "No data available")) +
                      "\n\t\t"
                  ),
                ]
              ),
          _vm.tsfields.length !== 0 && _vm.isBusy
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-center align-items-center",
                  staticStyle: { height: "20px", gap: "5px" },
                },
                [
                  _c("div", { staticClass: "loading" }, [
                    _vm._v(_vm._s(this.FormMSG(9, "Loading"))),
                  ]),
                  _c("div", { staticClass: "bars-5" }),
                ]
              )
            : _vm.tsfields.length !== 0
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row justify-content-center align-items-center",
                  staticStyle: { height: "20px" },
                },
                [
                  _c("div", { staticClass: "end-of-list" }, [
                    _vm._v(_vm._s(this.FormMSG(10, "End of list"))),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.isProduction && _vm.showMenu
            ? _c(
                "div",
                {
                  ref: "contextMenu",
                  staticClass: "context-menu",
                  style: { top: _vm.menuY + "px", left: _vm.menuX + "px" },
                  attrs: { id: "ContextMenu" },
                },
                [
                  _vm.isShowSolveAndUpdate(this.selectedItem) &&
                  _vm.isShowMenuItem(this.selectedItem)
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center pd-1 menu-context",
                          on: {
                            click: function ($event) {
                              return _vm.onMenuItemClick(1)
                            },
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn bg-transparent border-0",
                              attrs: { size: "sm" },
                            },
                            [
                              _c(
                                _vm.getLucideIcon(_vm.ICONS.CHECK_CIRCLE.name),
                                {
                                  tag: "component",
                                  attrs: {
                                    color: _vm.ICONS.CHECK_CIRCLE.color,
                                    size: 16,
                                    "stroke-width": 2.25,
                                  },
                                }
                              ),
                            ],
                            1
                          ),
                          _c("div", [_vm._v(_vm._s(_vm.FormMSG(22, "Solve")))]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isValidated(this.selectedItem) &&
                  !_vm.isShowSolveAndUpdate(this.selectedItem)
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center pd-1 menu-context",
                          on: {
                            click: function ($event) {
                              return _vm.onMenuItemClick(2)
                            },
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn bg-transparent border-0",
                              attrs: { size: "sm" },
                            },
                            [
                              _c(_vm.getLucideIcon(_vm.ICONS.PENCIL.name), {
                                tag: "component",
                                attrs: {
                                  color: _vm.ICONS.PENCIL.color,
                                  size: 16,
                                  "stroke-width": 2.25,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", [
                            _vm._v(_vm._s(this.FormMSG(7, "Update"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isValidated(this.selectedItem) &&
                  _vm.isShowSolveAndUpdate(this.selectedItem) &&
                  _vm.currentValidationLevel !== 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center pd-1 menu-context",
                          on: {
                            click: function ($event) {
                              _vm.isValid
                                ? _vm.onMenuItemClick(2.1)
                                : _vm.isReject
                                ? _vm.onMenuItemClick(2.2)
                                : () => []
                            },
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn bg-transparent border-0",
                              attrs: { size: "sm" },
                            },
                            [
                              _vm.isValid
                                ? _c(
                                    _vm.getLucideIcon(
                                      _vm.ICONS.CHECK_SQUARE.name
                                    ),
                                    {
                                      tag: "component",
                                      attrs: {
                                        color: _vm.ICONS.CHECK_SQUARE.color,
                                        size: 16,
                                        "stroke-width": 2.25,
                                      },
                                    }
                                  )
                                : _vm._e(),
                              _vm.isReject
                                ? _c(
                                    _vm.getLucideIcon(_vm.ICONS.X_CIRCLE.name),
                                    {
                                      tag: "component",
                                      attrs: {
                                        color: _vm.ICONS.X_CIRCLE.color,
                                        size: 16,
                                        "stroke-width": 2.25,
                                      },
                                    }
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.isValid
                                  ? `${_vm.FormMSG(14, "Validate")}`
                                  : `${_vm.FormMSG(15, "Reject")}`
                              )
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isValidated(this.selectedItem) &&
                  !_vm.isShowSolveAndUpdate(this.selectedItem) &&
                  _vm.currentValidationLevel !== 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center pd-1 menu-context",
                          on: {
                            click: function ($event) {
                              _vm.isValid
                                ? _vm.onMenuItemClick(2.1)
                                : _vm.isReject
                                ? _vm.onMenuItemClick(2.2)
                                : () => []
                            },
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn bg-transparent border-0",
                              attrs: { size: "sm" },
                            },
                            [
                              _vm.isValid
                                ? _c(
                                    _vm.getLucideIcon(
                                      _vm.ICONS.CHECK_SQUARE.name
                                    ),
                                    {
                                      tag: "component",
                                      attrs: {
                                        color: _vm.ICONS.CHECK_SQUARE.color,
                                        size: 16,
                                        "stroke-width": 2.25,
                                      },
                                    }
                                  )
                                : _vm._e(),
                              _vm.isReject
                                ? _c(
                                    _vm.getLucideIcon(_vm.ICONS.X_CIRCLE.name),
                                    {
                                      tag: "component",
                                      attrs: {
                                        color: _vm.ICONS.X_CIRCLE.color,
                                        size: 16,
                                        "stroke-width": 2.25,
                                      },
                                    }
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.isValid
                                  ? `${_vm.FormMSG(14, "Validate")}`
                                  : `${_vm.FormMSG(15, "Reject")}`
                              )
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  this.selectedItem !== null &&
                  this.selectedItem[0].tsDays[0].validated === 1 &&
                  _vm.isValidated(this.selectedItem) &&
                  !_vm.isShowSolveAndUpdate(this.selectedItem) &&
                  _vm.currentValidationLevel !== 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center pd-1 menu-context",
                          on: {
                            click: function ($event) {
                              return _vm.onMenuItemClick(2.1)
                            },
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn bg-transparent border-0",
                              attrs: { size: "sm" },
                            },
                            [
                              _c(
                                _vm.getLucideIcon(_vm.ICONS.CHECK_SQUARE.name),
                                {
                                  tag: "component",
                                  attrs: {
                                    color: _vm.ICONS.CHECK_SQUARE.color,
                                    size: 16,
                                    "stroke-width": 2.25,
                                  },
                                }
                              ),
                            ],
                            1
                          ),
                          _c("div", [
                            _vm._v(_vm._s(_vm.FormMSG(14, "Validate"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-start align-items-center pd-1 menu-context",
                      on: {
                        click: function ($event) {
                          return _vm.onMenuItemClick(4)
                        },
                      },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn bg-transparent border-0",
                          attrs: { size: "sm" },
                        },
                        [
                          _c(_vm.getLucideIcon(_vm.ICONS.EYE.name), {
                            tag: "component",
                            attrs: {
                              color: _vm.ICONS.EYE.color,
                              size: 16,
                              "stroke-width": 2.25,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", [_vm._v(_vm._s(_vm.FormMSG(11, "See info")))]),
                    ],
                    1
                  ),
                  _vm.isValidated(this.selectedItem)
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center pd-1 menu-context",
                          on: {
                            click: function ($event) {
                              return _vm.onMenuItemClick(5)
                            },
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn bg-transparent border-0",
                              attrs: { size: "sm" },
                            },
                            [
                              _c(_vm.getLucideIcon(_vm.ICONS.USERS.name), {
                                tag: "component",
                                attrs: {
                                  color: _vm.ICONS.USERS.color,
                                  size: 16,
                                  "stroke-width": 2.25,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "d-flex" }, [
                            _vm._v(_vm._s(this.FormMSG(20, "Replacement"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isValidated(this.selectedItem) &&
                  _vm.currentValidationLevel !== 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center pd-1 menu-context",
                          on: {
                            click: function ($event) {
                              return _vm.onMenuItemClick(6)
                            },
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn bg-transparent border-0",
                              attrs: { size: "sm" },
                            },
                            [
                              _c(_vm.getLucideIcon(_vm.ICONS.TRASH.name), {
                                tag: "component",
                                attrs: {
                                  color: _vm.ICONS.TRASH.color,
                                  size: 16,
                                  "stroke-width": 2.25,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "d-flex" }, [
                            _vm._v(_vm._s(this.FormMSG(6, "Delete"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-start align-items-center pd-1 menu-context",
                      on: {
                        click: function ($event) {
                          return _vm.hideContextMenu()
                        },
                      },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn bg-transparent border-0",
                          attrs: { size: "sm" },
                        },
                        [
                          _c(_vm.getLucideIcon("ShieldClose"), {
                            tag: "component",
                            attrs: {
                              color: _vm.ICONS.PENCIL.color,
                              size: 16,
                              "stroke-width": 2.25,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", [_vm._v(_vm._s(this.FormMSG(8, "Cancel")))]),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.isProduction && _vm.showMenuMultiple
            ? _c(
                "div",
                {
                  ref: "contextMenu",
                  staticClass: "context-menu",
                  style: { top: _vm.menuY + "px", left: _vm.menuX + "px" },
                  attrs: { id: "ContextMenu" },
                },
                [
                  _vm.checkIsSelectMultipleAndOnlyValidate()
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center pd-1 menu-context",
                          on: {
                            click: function ($event) {
                              return _vm.onMenuItemClick(1)
                            },
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn bg-transparent border-0",
                              attrs: { size: "sm" },
                            },
                            [
                              _c(
                                _vm.getLucideIcon(_vm.ICONS.CHECK_CIRCLE.name),
                                {
                                  tag: "component",
                                  attrs: {
                                    color: _vm.ICONS.CHECK_CIRCLE.color,
                                    size: 16,
                                    "stroke-width": 2.25,
                                  },
                                }
                              ),
                            ],
                            1
                          ),
                          _c("div", [_vm._v(_vm._s(_vm.FormMSG(22, "Solve")))]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentValidationLevel !== 0 &&
                  _vm.checkSelectAllPlanningToValidates()
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center pd-1 menu-context",
                          on: {
                            click: function ($event) {
                              return _vm.onMenuItemClick(9)
                            },
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn bg-transparent border-0",
                              attrs: { size: "sm" },
                            },
                            [
                              _c(
                                _vm.getLucideIcon(_vm.ICONS.CHECK_SQUARE.name),
                                {
                                  tag: "component",
                                  attrs: {
                                    color: _vm.ICONS.CHECK_SQUARE.color,
                                    size: 16,
                                    "stroke-width": 2.25,
                                  },
                                }
                              ),
                            ],
                            1
                          ),
                          _c("div", [
                            _vm._v(_vm._s(_vm.FormMSG(21, "Validate(s)"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isProduction === false
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center pd-1 menu-context",
                          on: {
                            click: function ($event) {
                              return _vm.onMenuItemClick(6)
                            },
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn bg-transparent border-0",
                              attrs: { size: "sm" },
                            },
                            [
                              _c(_vm.getLucideIcon(_vm.ICONS.TRASH.name), {
                                tag: "component",
                                attrs: {
                                  color: _vm.ICONS.TRASH.color,
                                  size: 16,
                                  "stroke-width": 2.25,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "d-flex" }, [
                            _vm._v(_vm._s(this.FormMSG(6, "Delete"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-start align-items-center pd-1 menu-context",
                      on: {
                        click: function ($event) {
                          return _vm.hideContextMenu()
                        },
                      },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn bg-transparent border-0",
                          attrs: { size: "sm" },
                        },
                        [
                          _c(_vm.getLucideIcon("ShieldClose"), {
                            tag: "component",
                            attrs: {
                              color: _vm.ICONS.PENCIL.color,
                              size: 16,
                              "stroke-width": 2.25,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", [_vm._v(_vm._s(this.FormMSG(8, "Cancel")))]),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.isProduction === false &&
          _vm.showMenuMultiple &&
          _vm.checkIsSelectMultipleAndOnly()
            ? _c(
                "div",
                {
                  ref: "contextMenu",
                  staticClass: "context-menu",
                  style: { top: _vm.menuY + "px", left: _vm.menuX + "px" },
                  attrs: { id: "ContextMenu" },
                },
                [
                  _vm.isProduction === false
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center pd-1 menu-context",
                          on: {
                            click: function ($event) {
                              return _vm.onMenuItemClick(6)
                            },
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn bg-transparent border-0",
                              attrs: { size: "sm" },
                            },
                            [
                              _c(_vm.getLucideIcon(_vm.ICONS.TRASH.name), {
                                tag: "component",
                                attrs: {
                                  color: _vm.ICONS.TRASH.color,
                                  size: 16,
                                  "stroke-width": 2.25,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "d-flex" }, [
                            _vm._v(_vm._s(this.FormMSG(6, "Delete"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-start align-items-center pd-1 menu-context",
                      on: {
                        click: function ($event) {
                          return _vm.hideContextMenu()
                        },
                      },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn bg-transparent border-0",
                          attrs: { size: "sm" },
                        },
                        [
                          _c(_vm.getLucideIcon("ShieldClose"), {
                            tag: "component",
                            attrs: {
                              color: _vm.ICONS.PENCIL.color,
                              size: 16,
                              "stroke-width": 2.25,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", [_vm._v(_vm._s(this.FormMSG(8, "Cancel")))]),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.isProduction === false && (_vm.showMenu || _vm.showMenuMultiple)
            ? _c(
                "div",
                {
                  ref: "contextMenu",
                  staticClass: "context-menu",
                  style: { top: _vm.menuY + "px", left: _vm.menuX + "px" },
                  attrs: { id: "ContextMenu" },
                },
                [
                  _vm.checkIsSelectMultipleAndOnly()
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center pd-1 menu-context",
                          on: {
                            click: function ($event) {
                              return _vm.onMenuItemClick(8)
                            },
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn bg-transparent border-0",
                              attrs: { size: "sm" },
                            },
                            [
                              _c(_vm.getLucideIcon(_vm.ICONS.SEND.name), {
                                tag: "component",
                                attrs: {
                                  color: _vm.ICONS.SEND.color,
                                  size: 16,
                                  "stroke-width": 2.25,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.FormMSG(12, "Send For Validation"))
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showMenuMultiple === false
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center pd-1 menu-context",
                          on: {
                            click: function ($event) {
                              return _vm.onMenuItemClick(2)
                            },
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn bg-transparent border-0",
                              attrs: { size: "sm" },
                            },
                            [
                              _c(_vm.getLucideIcon(_vm.ICONS.PENCIL.name), {
                                tag: "component",
                                attrs: {
                                  color: _vm.ICONS.PENCIL.color,
                                  size: 16,
                                  "stroke-width": 2.25,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", [
                            _vm._v(_vm._s(this.FormMSG(7, "Update"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isValidated(this.selectedItem)
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center pd-1 menu-context",
                          on: {
                            click: function ($event) {
                              return _vm.onMenuItemClick(6)
                            },
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn bg-transparent border-0",
                              attrs: { size: "sm" },
                            },
                            [
                              _c(_vm.getLucideIcon(_vm.ICONS.TRASH.name), {
                                tag: "component",
                                attrs: {
                                  color: _vm.ICONS.TRASH.color,
                                  size: 16,
                                  "stroke-width": 2.25,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "d-flex" }, [
                            _vm._v(_vm._s(this.FormMSG(6, "Delete"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-start align-items-center pd-1 menu-context",
                      on: {
                        click: function ($event) {
                          return _vm.hideContextMenu()
                        },
                      },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn bg-transparent border-0",
                          attrs: { size: "sm" },
                        },
                        [
                          _c(_vm.getLucideIcon("ShieldClose"), {
                            tag: "component",
                            attrs: {
                              color: _vm.ICONS.PENCIL.color,
                              size: 16,
                              "stroke-width": 2.25,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", [_vm._v(_vm._s(this.FormMSG(8, "Cancel")))]),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-success",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(16, "Informations"),
            size: "xl",
            "no-close-on-backdrop": "",
            "ok-variant": "success",
            "ok-only": "",
          },
          on: { ok: _vm.hideModal },
          model: {
            value: _vm.showDetailModalCell,
            callback: function ($$v) {
              _vm.showDetailModalCell = $$v
            },
            expression: "showDetailModalCell",
          },
        },
        [
          _c("div", { staticClass: "container-mobile animated fadeIn" }, [
            _c(
              "div",
              { staticClass: "content-detailsMyTimesheet-mobile" },
              [
                _c("show-detail-planning-cell-fimalac", {
                  attrs: { showDetailData: _vm.showDetailData },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-danger",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(17, "Refuse this day"),
            "no-close-on-backdrop": "",
            "ok-variant": "danger",
            "ok-only": "",
          },
          on: { hidden: _vm.onCloseRejectModal },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          attrs: { variant: "danger", size: "sm" },
                          on: { click: _vm.finaliseTsDayReject },
                        },
                        [_vm._v(" OK ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.successModalRejectedTsDay,
            callback: function ($$v) {
              _vm.successModalRejectedTsDay = $$v
            },
            expression: "successModalRejectedTsDay",
          },
        },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.FormMSG(18, "Enter your comment:"),
                "label-for": "comment",
                "label-cols": 4,
              },
            },
            [
              _c("b-form-textarea", {
                attrs: {
                  id: "comment",
                  placeholder: this.FormMSG(19, "Enter your remarks..."),
                  rows: "3",
                },
                on: { change: _vm.handleChangeComment },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }