<template>
	<b-modal
		v-model="isOpen"
		:title="FormMSG(69, 'Custom filter')"
		header-class="header-class-modal-doc-package"
		size="md"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@ok.prevent="handleOk"
		@cancel.prevent="emitEventClose"
		@hidden="emitEventClose"
	>
		<b-row>
			<b-col>
				<b-table-simple small caption-top responsive sticky-header="700px" hover ref="table-custom-filter-fimalac">
					<b-thead>
						<b-tr>
							<b-th class="w-35-px style-header-table-simple"> </b-th>
							<b-th class="w-125-px style-header-table-simple">
								{{ FormMSG(84, 'Index') }}
							</b-th>
							<b-th class="style-header-table-simple">
								{{ FormMSG(85, 'Function') }}
							</b-th>
						</b-tr>
					</b-thead>
					<draggable :list="functionList" tag="tbody">
						<b-tr v-for="(item, index) in functionList" :key="index" class="cursor-pointer">
							<b-td class="style-body-table-simple w-35-px">
								<button class="btn-transparent text-color-rhapsody-in-blue" type="button">
									<component :is="getLucideIcon('GripVertical')" :size="20" />
								</button>
							</b-td>
							<b-td class="style-body-table-simple text-color-rhapsody-in-blue w-125-px">{{ index + 1 }}</b-td>
							<b-td class="style-body-table-simple text-color-rhapsody-in-blue"> {{ item.message }} </b-td>
						</b-tr>
					</draggable>
				</b-table-simple>
			</b-col>
		</b-row>

		<template #modal-footer="{ ok, cancel }">
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button size="md" variant="custom-outline-gray" style="margin-top: 5px" class="w-138-px mr-3" @click="cancel" block>
					{{ FormMSG(43, 'Cancel') }}
				</b-button>
				<b-button size="md" variant="primary" class="w-138-px" @click="ok" block>
					<div class="d-flex justify-content-center align-items-center">
						<div style="margin-top: 1px">{{ FormMSG(42, 'Save') }}</div>
					</div>
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import Draggable from 'vuedraggable';
import { getFunctions } from '@/cruds/department.crud';
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'CustomFilter',

	props: {
		open: { type: Boolean, default: false, required: false },
		refreshModal: { type: Boolean, default: false, required: false }
	},

	mixins: [languageMessages, globalMixin],

	components: { Draggable },

	data() {
		return {
			functionList: []
		};
	},

	computed: {
		...mapGetters({
			functionOrders: 'fimalac/functionOrders'
		}),

		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		}
	},

	watch: {
		functionOrders: {
			handler(newVal) {
				this.functionList = _.cloneDeep(newVal);
			},
			immediate: true,
			deep: true
		},
		refreshModal: {
			async handler(newVal) {
				if (newVal === true) {
					await this.getFunctionOrders();

					this.$emit('custom-filter:refresh-end');
				}
			},
			deep: true
		}
	},

	async created() {
		// await this.getFunctionOrders();
	},

	methods: {
		...mapActions({
			setFunctionOrders: 'fimalac/setFunctionOrders',
			getFunctionOrders: 'fimalac/getFunctionOrders'
		}),

		async handleOk() {
			await this.setFunctionOrders(this.functionList);

			this.$emit('custom-filter:success');
			this.emitEventClose();
		},

		// async getFunctions() {
		// 	this.functionList = await getFunctions();
		// },
		emitEventClose() {
			this.$emit('custom-filter:close');
		}
	}
};
</script>
