<style></style>
<template>
	<div class="d-flex justify-content-center flex-grow-1">
		<b-form-checkbox size="lg" v-model="selected" :value="true" @change="handleAddFlag" :key="option.id" :aria-describedby="option.name">
			<span
				class="d-flex justify-content-center align-items-center p-1 text-black px-3"
				:style="`background-color: ${option.code}; border-radius: 4px;height: 20px;
    margin-top: 2px;`"
				>{{ option.text }}</span
			>
		</b-form-checkbox>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
export default {
	name: 'FlagItems',
	components: {},
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		option: {
			type: Object,
			required: false
		},
		data: {
			type: Array,
			required: false,
			default: () => []
		}
	},
	data: () => {
		return {
			selected: null
		};
	},
	mounted() {},
	destroyed() {},

	watch: {},
	created() {},
	computed: {},
	methods: {
		handleAddFlag(e) {
			let flag = {
				isCheck: e,
				...this.option
			};
			this.$emit('setFlags', flag);
		}
	}
};
</script>
<style scoped></style>
