<template>
	<b-modal
		v-model="isOpen"
		:title="FormMSG(86, 'Replacement')"
		header-class="header-class-modal-doc-package"
		size="md"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		ok-variant="primary"
		:ok-disabled="loadingSubmit"
		:cancel-title="FormMSG(79, 'Cancel')"
		cancel-variant="outline-primary"
		:cancel-disabled="loadingSubmit"
		@cancel="emitEventClose"
		@hidden="emitEventClose"
		@ok.prevent="handleSubmit"
	>
		<div class="form">
			<b-row>
				<b-col>
					<fieldset class="my-3 border-groove-blue-streak" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-blue-streak">
							{{ FormMSG(132, 'Person to replace') }}
						</legend>
						<div class="pt-3 pb-3">
							<b-row>
								<b-col>
									<div class="fs-14 fw-700">
										{{ userToReplace.fullName }} - {{ userToReplace.tsDay.date | formatDate('dddd DD MMMM YYYY') }}
										<span v-if="+userToReplace.location.id > 0">({{ userToReplace.location.setName }})</span>
									</div>
								</b-col>
							</b-row>
						</div>
					</fieldset>
				</b-col>
			</b-row>
			<b-row class="mt-2 mb-2">
				<b-col>
					{{ FormMSG(133, 'Select the replacement person from the list. All data for the day will be copied to the same day for this person.') }}
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<v-select
						v-model="$v.userReplacedSelected.$model"
						:options="users"
						:placeholder="FormMSG(49, 'Select a person')"
						:clearable="false"
						label="fullName"
						:class="{ 'is-invalid': $v.userReplacedSelected.$error }"
					/>
					<div v-if="$v.userReplacedSelected.$error" class="invalid-feedback">
						{{ FormMSG(69, 'User is required') }}
					</div>
				</b-col>
			</b-row>
		</div>

		<template #modal-ok>
			<div><b-spinner v-if="loadingSubmit" small /> {{ FormMSG(78, 'Validate replacement') }}</div>
		</template>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { getUsersOfProject } from '@/cruds/users.crud';
import _ from 'lodash';
import { substituteUserTsDay } from '@/cruds/timesheets-efc.crud';
import { getProjectUsersTsDaysPlanning } from '@/cruds/timesheets-efc.crud';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'Substitution',

	mixins: [languageMessages, globalMixin, validationMixin],

	props: {
		open: { type: Boolean, default: false, required: false },
		externalData: { type: Object, default: () => {}, required: false }
	},

	data() {
		return {
			loadingSubmit: false,
			users: [],
			userToReplace: {
				tsDay: {
					date: new Date()
				},
				location: {
					id: 0
				}
			},
			userReplacedSelected: null
		};
	},

	validations: {
		userReplacedSelected: { required }
	},

	watch: {
		externalData: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.userToReplace = newVal;
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		}
	},

	async created() {
		await this.getUsers();
	},

	methods: {
		async getUsers() {
			this.users = await getProjectUsersTsDaysPlanning();
		},
		async handleSubmit() {
			this.$v.$touch();
			this.loadingSubmit = true;

			if (!this.$v.$invalid) {
				await substituteUserTsDay(+this.externalData.tsDay.id, +this.userReplacedSelected.id);
				this.createToastForMobile(this.FormMSG(48, 'Success'), this.FormMSG(50, 'User substitut successfully!'));
				this.$emit('substitution:submitted');
				this.loadingSubmit = false;

				this.emitEventClose();
			} else {
				this.loadingSubmit = false;
			}
		},
		emitEventClose() {
			this.userReplacedSelected = null;
			this.$emit('substitution:close');
		}
	}
};
</script>

<style scoped></style>
