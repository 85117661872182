<template>
	<b-modal
		v-model="isOpen"
		:title="FormMSG(73, 'Add crew member')"
		header-class="header-class-modal-doc-package"
		size="xl"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		:ok-only="searchExisting === false"
		@ok.prevent="handleOk"
		@show="handleShow"
		@cancel="handleCancel"
		@hidden="emitEventClose"
	>
		<b-row class="mb-4">
			<b-col class="text-center">
				<b-button-group>
					<b-button :variant="`${!searchExisting ? '' : 'outline-'}primary`" :disabled="loadingAddSelected" @click="toggleSearchExisting">
						{{ FormMSG(27, 'Search existing') }}
					</b-button>
					<b-button :variant="`${searchExisting ? '' : 'outline-'}primary`" :disabled="loadingAddSelected" @click="toggleSearchExisting">
						{{ FormMSG(28, 'Add no-existing') }}
					</b-button>
				</b-button-group>
			</b-col>
		</b-row>

		<div style="min-height: 150px">
			<b-collapse :visible="searchExisting === false">
				<div v-if="searchExisting === false">
					<b-row class="mb-2">
						<b-col cols="6">
							<b-input-group>
								<b-form-input type="text" v-model="filter" :placeholder="FormMSG(65, 'Type to search')" @keyup.enter="handleKeyUpEnter" />
								<b-input-group-append class="cursor-pointer">
									<b-input-group-text class="btn-search">
										<component :is="getLucideIcon('Search')" color="#FFFFFF" :size="16" :stroke-width="2.5" v-if="filter.length === 0" />
										<component :is="getLucideIcon('X')" color="#FFFFFF" :size="16" :stroke-width="2.5" @click="resetFilter" v-else />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-col>
					</b-row>

					<b-row>
						<b-col id="containerInviteNewUser" ref="containerInviteNewUser">
							<b-table
								v-if="$screen.width >= 992"
								selected-variant="primary"
								hover
								selectable
								select-mode="single"
								responsive="sm"
								ref="preferencesTable"
								id="preferencesTable"
								sticky-header="500px"
								:items="dataList"
								style="text-align: left"
								:fields="tableFields"
								bordered
								striped
								small
								head-variant="dark"
								:empty-text="FormMSG(500, 'Run a search to show matching records in this list')"
								show-empty
							>
								<template #head(checked)="data">
									<b-form-checkbox
										v-if="dataList.length > 0"
										v-model="selectAll"
										size="lg"
										:value="true"
										:unchecked-value="false"
										:disabled="loadingAddSelected"
										@change="handleChangeSelectAll"
									/>
								</template>
								<template #cell(checked)="{ item, index }">
									<div>
										<b-form-checkbox
											v-model="item.checked"
											:value="true"
											:unchecked-value="false"
											size="lg"
											:disabled="loadingAddSelected"
											@change="handleChangeItemSelect($event, item, index)"
										/>
									</div>
								</template>
							</b-table>
						</b-col>
					</b-row>

					<b-row class="mt-2">
						<b-col cols="3" offset="9">
							<b-button variant="primary" :disabled="dataListChecked.length === 0 || loadingAddSelected" block @click="handleClickAddSelected">
								<b-spinner v-if="loadingAddSelected" small />
								{{ FormMSG(589, 'Add selected to project') }}
							</b-button>
						</b-col>
					</b-row>
				</div>
			</b-collapse>

			<b-collapse :visible="searchExisting === true">
				<div v-if="searchExisting === true">
					<b-row>
						<b-col cols="6">
							<b-form-group :label="FormMSG(251, 'First name')">
								<b-form-input
									v-model="$v.dataForAddNoExisting.firstName.$model"
									:placeholder="FormMSG(252, 'Enter first name')"
									:state="!$v.dataForAddNoExisting.firstName.$error"
								/>
								<div v-if="!$v.dataForAddNoExisting.firstName.minLength" class="invalid-feedback">
									{{ FormMSG(154, 'First name must be greater 3 words') }}
								</div>
								<div v-if="!$v.dataForAddNoExisting.firstName.required" class="invalid-feedback">
									{{ FormMSG(155, 'First name is required') }}
								</div>
							</b-form-group>
						</b-col>
						<b-col cols="6">
							<b-form-group :label="FormMSG(253, 'Last name')">
								<b-form-input
									v-model="$v.dataForAddNoExisting.name.$model"
									:placeholder="FormMSG(254, 'Enter last name')"
									:state="!$v.dataForAddNoExisting.name.$error"
								/>
								<div v-if="!$v.dataForAddNoExisting.name.minLength" class="invalid-feedback">
									{{ FormMSG(156, 'Name must be greater 3 words') }}
								</div>
								<div v-if="!$v.dataForAddNoExisting.name.required" class="invalid-feedback">
									{{ FormMSG(289, 'Name is required') }}
								</div>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="6">
							<b-form-group :label="FormMSG(255, 'Email')">
								<b-form-input
									v-model="$v.dataForAddNoExisting.email.$model"
									:placeholder="FormMSG(256, 'Enter email')"
									:state="!$v.dataForAddNoExisting.email.$error"
								/>
								<div v-if="!$v.dataForAddNoExisting.email.email" class="invalid-feedback">
									{{ FormMSG(293, 'Email is invalid') }}
								</div>
								<div v-if="!$v.dataForAddNoExisting.email.required" class="invalid-feedback">
									{{ FormMSG(291, 'Email is required') }}
								</div>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col cols="6">
							<b-form-group :label="FormMSG(257, 'Department')">
								<b-form-select
									v-model="$v.dataForAddNoExisting.department.$model"
									:options="allDepartments"
									:placeholder="FormMSG(258, 'Please select')"
									:state="!$v.dataForAddNoExisting.department.$error"
									@change="handleChangeDepartment"
								/>
								<div v-if="$v.dataForAddNoExisting.department.$error" class="invalid-feedback">
									{{ FormMSG(296, 'Department is required') }}
								</div>
							</b-form-group>
						</b-col>
						<b-col cols="6">
							<b-form-group :label="FormMSG(259, 'Function')">
								<b-form-select
									v-model="$v.dataForAddNoExisting.function.$model"
									:options="allFunctions"
									:placeholder="FormMSG(258, 'Please select')"
									:state="!$v.dataForAddNoExisting.function.$error"
									:disabled="dataForAddNoExisting.department === null"
								/>
								<div v-if="$v.dataForAddNoExisting.function.$error" class="invalid-feedback">
									{{ FormMSG(301, 'Function is required') }}
								</div>
							</b-form-group>
						</b-col>
					</b-row>
				</div>
			</b-collapse>
		</div>

		<template #modal-footer="{ ok, cancel }">
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button
					v-if="searchExisting === true"
					size="md"
					variant="custom-outline-gray"
					style="margin-top: 5px"
					class="w-138-px mr-3"
					:disabled="loadingSubmit"
					@click="cancel"
					block
				>
					{{ FormMSG(43, 'Cancel') }}
				</b-button>
				<b-button
					size="md"
					:variant="searchExisting === false ? 'custom-outline-gray' : 'primary'"
					class="w-138-px"
					:disabled="loadingSubmit"
					@click="ok"
					block
				>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-if="loadingSubmit" small />
						<div :class="`${loadingSubmit ? 'pl-2' : ''}`" style="margin-top: 1px">
							{{ searchExisting === false ? FormMSG(43, 'Cancel') : FormMSG(42, 'Save') }}
						</div>
					</div>
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { getUsersByLicence, addUsersToProject } from '@/cruds/users.crud';
import _ from 'lodash';
import { validationMixin } from 'vuelidate';
import { required, email, minLength, minValue } from 'vuelidate/lib/validators';
import { getDepartments } from '@/cruds/department.crud';
import { getFunctions } from '@/cruds/department.crud';

export default {
	name: 'InviteNewUser',

	props: {
		open: { type: Boolean, default: false, required: false }
	},

	mixins: [languageMessages, globalMixin, validationMixin],

	data() {
		return {
			searchExisting: false,
			filter: '',
			dataList: [],
			dataListChecked: [],
			selectAll: false,
			recordOffset: 0,
			recordLimit: 50,

			loadingSubmit: false,
			loadingAddSelected: false,

			dataForAddNoExisting: {
				name: '',
				firstName: '',
				email: '',
				department: null,
				function: null
			},

			allDepartments: [],
			allFunctions: [],
			alreadyLastData: false
		};
	},

	validations: {
		dataForAddNoExisting: {
			name: { required, minLength: minLength(4) },
			firstName: { required, minLength: minLength(4) },
			email: { required, email },
			department: { required },
			function: { required }
		}
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		tableFields() {
			return [
				{
					key: 'checked',
					label: '',
					class: 'text-center',
					sortable: false
				},
				{
					key: 'name',
					label: this.FormMSG(412, 'Last Name'),
					class: 'text-left',
					sortable: false
				},
				{
					key: 'firstName',
					label: this.FormMSG(413, 'First Name'),
					class: 'text-left',
					sortable: false
				},
				{
					key: 'email',
					label: this.FormMSG(414, 'Email'),
					class: 'text-left',
					sortable: false
				},
				{
					key: 'departmentName',
					label: this.FormMSG(416, 'Department'),
					class: 'text-left',
					sortable: false
				},
				{
					key: 'functionName',
					label: this.FormMSG(417, 'Function'),
					class: 'text-left',
					sortable: false
				}
			];
		}
	},

	async created() {
		await this.getDepartment();
		this.allFunctions = [{ value: null, text: this.FormMSG(587, 'Please select a department') }];
	},

	methods: {
		async handleChangeDepartment(payload) {
			if (payload === null) {
				this.allFunctions = [{ value: null, text: this.FormMSG(587, 'Please select a department') }];
				this.dataForAddNoExisting.function = null;
			} else {
				await this.getFunctions(payload);
			}
		},
		async getFunctions(departementID) {
			const result = await getFunctions(+departementID);
			this.allFunctions = [{ value: null, text: this.FormMSG(258, 'Please select') }];

			result.map((option) => {
				this.allFunctions.push({
					value: +option.value,
					text: option.message
				});
			});
		},
		async getDepartment() {
			this.allDepartments = [{ value: null, text: this.FormMSG(258, 'Please select') }];
			await getDepartments(null, null).then((result) => {
				result.map((option) => {
					this.allDepartments.push({
						value: option.value,
						text: option.message
					});
				});
			});
		},
		handleChangeItemSelect(payload, item, index) {
			if (payload === false) {
				const indexFromDataSelected = _.findIndex(this.dataListChecked, (option) => option.indexDataList === index);

				if (indexFromDataSelected > -1) {
					this.dataListChecked.splice(indexFromDataSelected, 1);
				}

				this.selectAll = false;
				this.dataList[index].checked = false;
			} else if (payload === true) {
				this.dataListChecked.push({
					...item,
					indexDataList: index
				});

				if (this.dataListChecked.length === this.dataList.length) {
					this.selectAll = true;
				}

				this.dataList[index].checked = true;
			}
		},
		async handleClickAddSelected() {
			const actionForLoader = async () => {
				this.loadingAddSelected = true;

				const resultPrepareDataSelected = this.prepareDataSelectedToSend();
				await addUsersToProject(resultPrepareDataSelected.dataToSend);

				this.createToastForMobile(this.FormMSG(59, 'Success'), this.FormMSG(61, 'User(s) added successfully!'));

				this.dataList = resultPrepareDataSelected.newDataList;
				this.emitEventUsersAdded();
				this.dataListChecked = [];
				this.selectAll = false;

				this.loadingAddSelected = false;
			};

			await this.showLoader(actionForLoader, 'containerInviteNewUser');
		},
		prepareDataSelectedToSend() {
			let result = [];
			let newDataList = _.cloneDeep(this.dataList);
			for (let i = 0; i < this.dataListChecked.length; i++) {
				const element = this.dataListChecked[i];
				result.push({
					firstName: element.firstName,
					name: element.name,
					email: element.email,
					function: element.function,
					department: element.department,
					functionName: element.functionName,
					departmentName: element.departmentName
				});

				newDataList = newDataList.filter((option) => +option.id !== +element.id);
			}

			return {
				dataToSend: result,
				newDataList
			};
		},
		handleChangeSelectAll(payload) {
			this.dataListChecked = [];

			if (payload === true) {
				this.dataList = this.dataList.map((option, index) => {
					this.dataListChecked.push({
						...option,
						indexDataList: index
					});

					return {
						...option,
						checked: true
					};
				});
			} else {
				this.dataList = this.dataList.map((option) => {
					return {
						...option,
						checked: false
					};
				});
			}
		},
		async handleOk() {
			if (this.searchExisting === false) {
				this.emitEventClose();
			} else {
				this.$v.$touch();

				if (!this.$v.$invalid) {
					this.loadingSubmit = true;

					const functionFinded = _.find(this.allFunctions, (option) => option.value === this.dataForAddNoExisting.function);
					const departmentFinded = _.find(this.allDepartments, (option) => option.value === this.dataForAddNoExisting.department);

					await addUsersToProject([
						{
							firstName: this.dataForAddNoExisting.firstName,
							name: this.dataForAddNoExisting.name,
							email: this.dataForAddNoExisting.email,
							department: this.dataForAddNoExisting.department,
							function: this.dataForAddNoExisting.function,
							departmentName: departmentFinded.text,
							functionName: functionFinded.text
						}
					]);

					this.createToastForMobile(this.FormMSG(812, 'Success'), this.FormMSG(813, 'User added successfully!'));
					this.loadingSubmit = false;
					this.emitEventUsersAdded();
					this.emitEventClose();
				}
			}
		},
		emitEventUsersAdded() {
			this.$emit('invite-new-user:users-added');
		},
		async handleKeyUpEnter() {
			this.recordOffset = 0;
			this.recordLimit = 50;
			this.alreadyLastData = false;

			await this.getUsers();
		},
		async getUsers(pushData = false) {
			if (!pushData) {
				this.dataList = [];
				this.dataListChecked = [];
			}

			const actionForLoader = async () => {
				if (this.alreadyLastData === false) {
					const result = await getUsersByLicence(this.filter, this.recordLimit, this.recordOffset);

					if (result.length > 0) {
						result.map((option) => {
							this.dataList.push({
								...option,
								checked: false
							});
						});
					} else {
						this.alreadyLastData = true;
					}
				}
			};

			await this.showLoader(actionForLoader, 'containerInviteNewUser');
		},
		async resetFilter() {
			this.filter = '';
			this.recordOffset = 0;
			this.alreadyLastData = false;
			this.dataList = [];

			await this.getUsers();
		},
		async handleShow() {
			setTimeout(() => {
				const element = document.querySelector('#containerInviteNewUser .b-table-sticky-header');

				element.addEventListener('scroll', async () => {
					const scrollTop = element.scrollTop;
					const scrollHeight = element.scrollHeight;
					const clientHeight = element.clientHeight;

					if (scrollTop + clientHeight >= scrollHeight) {
						if (this.alreadyLastData === false) {
							this.recordOffset += 1;
							await this.getUsers(true);
						}
					}
				});
			}, 1000);
		},
		toggleSearchExisting() {
			this.searchExisting = !this.searchExisting;
		},
		handleCancel() {
			clearTimeout();

			this.emitEventClose();
		},
		emitEventClose() {
			this.dataList = [];
			this.dataListChecked = [];
			this.filter = '';
			this.dataForAddNoExisting = {
				name: '',
				firstName: '',
				email: '',
				department: null,
				function: null
			};
			this.searchExisting = false;

			this.$emit('invite-new-user:close');
		}
	}
};
</script>
