<template>
	<div style="background-color: #f5f7f9; padding: 16px; border-radius: 8px" class="d-flex flex-row justify-content-center align-items-start">
		<b-col class="p-0">
			<b-row class="d-flex align-items-center">
				<b-col cols="10">
					<h1 class="h-text-color">{{ convertTextDate(showDetailData.date) }}</h1>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="6" class="d-flex justify-content-start align-items-center">
					<h6 v-if="!isFilmSingle" class="h-text-color p-1">
						<span>
							{{ FormMSG(257, 'Encoded by:') }}
							<b>{{ encoder(showDetailData) }}</b>
						</span>
					</h6>
				</b-col>
				<b-col cols="6" class="d-flex justify-content-end align-items-center">
					<h6 v-if="!isFilmSingle" class="p-1">
						<div class="name-title">
							{{ getUserFullName(showDetailData) }}
							<span class="divider-dot"></span>
							{{ getUserFunctionName(showDetailData) }}
						</div>
					</h6>
				</b-col>
			</b-row>
			<b-row style="margin-top: 20px">
				<b-col>
					<div class="details-timesheet">
						<ul>
							<li>
								<div class="column-flag-1">{{ FormMSG(183, 'Status') }}</div>
								<div class="column-flag-2">{{ FormMSG(8, 'Category') }}</div>
								<div class="column-flag-3">{{ FormMSG(9, 'Activity') }}</div>
								<div class="column-flag-4">
									<div class="wrap-status d-flex justify-content-end align-items-end">
										<div :class="`status ${classStatus(showDetailData.validated)}`" style="font-size: 0.675rem">
											{{ getLabelStatus(showDetailData.validated) }}
										</div>
									</div>
								</div>
							</li>
							<li
								class="timesheet-comment border-0 rounded-0"
								v-if="
									(showDetailData.validated === 4 || showDetailData.validated === 16) &&
									showDetailData.comment &&
									showDetailData.comment.length > 0
								"
							>
								<p>{{ showDetailData.comment }}</p>
							</li>
							<li v-for="(item, index) of mapInfo" :key="index">
								<div class="column-flag-1">
									<span
										class="d-flex justify-content-center align-items-center p-1 text-black px-3 h-auto"
										:style="`background-color: #${item.projectFlagItem.color}; width: auto; border-radius: 4px;height: 20px;margin-top: 2px;`"
									>
										<span :style="`color: ${isColorLight(`#${item.projectFlagItem.color}`) ? 'black' : 'white'}; font-weight: bold`">{{
											getProjectFlagToText(item)
										}}</span>
									</span>
								</div>
								<div class="column-flag-2 mr-1 ml-2">{{ setCategory(item) }}</div>
								<div class="column-flag-3 mr-1 ml-2">{{ setActivity(item) }}</div>
								<div class="column-flag-4">{{ formatCurrency(item.amount) }}</div>
							</li>
						</ul>
					</div>
				</b-col>
			</b-row>
		</b-col>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { rendCurrency, rendKgCo2 } from '~helpers';
import moment from 'moment';
import { classStatus } from '@/shared/global-status';
import { Clock10, Sprout, Info } from 'lucide-vue';
import { store } from '@/store';

export default {
	name: 'ShowDetailPlanningCellFimalac',
	components: { Clock10, Sprout, Info },
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		showDetailData: {
			type: Object,
			required: false
		}
	},
	data() {
		const setTotalOvt = (hoursOvts, minutesOvts) => {
			if (hoursOvts !== undefined && minutesOvts !== undefined) {
				let hours = hoursOvts.reduce((a, b) => +a + +b, 0);
				let minutes = minutesOvts.reduce((a, b) => +a + +b, 0);
				return `${hours.toString().padStart('2', '0')}` + ':' + `${minutes.toString().padStart('2', '0')}`;
			}
		};
		const setWorked = (hour, minute) => {
			if (hour !== undefined && minute !== undefined) {
				return `${hour.toString().padStart('2', '0')}` + ':' + `${minute.toString().padStart('2', '0')}`;
			}
		};
		const setHours = (hour) => {
			if (hour !== undefined) {
				return `${hour.toString().padStart('2', '0')}`;
			}
		};
		return {
			setTotalOvt,
			setWorked,
			setHours,
			classStatus
		};
	},
	computed: {
		mapInfo() {
			if (this.showDetailData.tsDayDetails !== undefined && this.showDetailData.tsDayDetails.length !== 0) {
				let result = this.showDetailData.tsDayDetails;
				return result;
			}
			return [];
		}
	},
	created() {},
	methods: {
		isColorLight(color) {
			const hex = color.replace('#', '');
			const red = parseInt(hex.substring(0, 0 + 2), 16);
			const green = parseInt(hex.substring(2, 2 + 2), 16);
			const blue = parseInt(hex.substring(4, 4 + 2), 16);
			const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
			return brightness > 155;
		},
		setCategory(item) {
			if (item !== undefined) {
				let category = item.costCenter;
				let shotActivity = item.activity.short;
				if (category.length === 0) {
					return 'None';
				} else {
					return category.replaceAll('$', shotActivity);
				}
			}
			return 'None';
		},
		setActivity(item) {
			if (item !== undefined) {
				let activity = item.activity.name;
				let short = item.activity.short;
				if (activity.length !== 0) {
					if (short.length !== 0) {
						return activity + ' (' + short + ')';
					}
					return activity;
				}
				return 'None';
			}
		},
		getUserFullName(showDetailData) {
			if (showDetailData.user !== undefined) {
				return `${showDetailData.user.name} ${showDetailData.user.firstName}`;
			}
		},
		getUserFunctionName(showDetailData) {
			if (showDetailData.user !== undefined) {
				return showDetailData.user.functionName;
			}
		},
		getProjectFlagToText(item) {
			if (item.projectFlagItem !== undefined || item.projectFlagItem !== null) {
				return `${item.projectFlagItem.name} ${'(' + item.projectFlagItem.short + ')'}`;
			}
		},
		encoder(data) {
			if (data.encoder !== null) {
				let firstName = data.encoder !== undefined ? data.encoder.firstName : '';
				let name = data.encoder !== undefined ? data.encoder.name : '';
				return `${firstName} ${name}`;
			}
		},
		formatCurrency(value) {
			return rendCurrency(value);
		},
		convertDate(date) {
			return moment(date).format('DD/MM/YYYY');
		},
		convertTextDate(value) {
			const langCurrent = this.currentLang;
			let result = moment(value).locale(`${langCurrent}`).format('DD MMMM YYYY');
			return result;
		},
		setFullName(item) {
			let fullName = item.fullName;
			return fullName;
		},
		getCurrency(value) {
			return rendCurrency(value);
		},
		getCO2(value) {
			return rendKgCo2(value);
		},
		getHhMmString(value) {
			return value;
		},
		getLabelStatus(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1008, value);
		},
		showHotelPerDiem() {
			return store.getCurrentProjectConfig().useHotelPerDiem;
		},
		showLunchPerDiem() {
			return store.getCurrentProjectConfig().useLunchPerDiem;
		},
		showLunch() {
			return store.getCurrentProjectConfig().useLunchTime;
		},
		showTooEarlyHours() {
			return store.getCurrentProjectConfig().useTooEarlyHours;
		},
		showNightHours() {
			return store.getCurrentProjectConfig().useNightHours;
		},
		showTransport() {
			//console.log("in show transport:",store.getCurrentProjectConfig());
			return store.getCurrentProjectConfig().useHomeToWorkTime;
		},
		showOvt2() {
			return store.getCurrentProjectConfig().useOvt2;
		},
		showOvt1() {
			return store.getCurrentProjectConfig().useOvt1;
		},
		showOvt() {
			return store.getCurrentProjectConfig().useOvertime;
		},
		showWeeklyOvt() {
			return store.getCurrentProjectConfig().useWeeklyOvt;
		},
		showWeeklyOvt1() {
			return store.getCurrentProjectConfig().useWeeklyOvt1;
		},
		showWeeklyOvt2() {
			return store.getCurrentProjectConfig().useWeeklyOvt2;
		},
		showPreCall() {
			return store.getCurrentProjectConfig().useBeforeCallTime;
		},
		tooltipTsContentAllowance(item) {
			let content = [];

			if (item.weeklyCarAllowance > 0) {
				content.push(this.FormMSG(105, 'Car: ') + this.getCurrency(item.weeklyCarAllowance));
			}

			if (item.weeklyComputerAllowance > 0) {
				content.push(this.FormMSG(106, 'Computer: ') + this.getCurrency(item.weeklyComputerAllowance));
			}

			if (item.weeklyPhoneAllowance > 0) {
				content.push(this.FormMSG(107, 'Phone: ') + this.getCurrency(item.weeklyPhoneAllowance));
			}

			if (item.weeklyProductionFeeAllowance > 0) {
				content.push(this.FormMSG(108, 'Production fee: ') + this.getCurrency(item.weeklyProductionFeeAllowance));
			}

			if (item.weeklyBoxKitAllowance > 0) {
				content.push(this.FormMSG(109, 'Box kit: ') + this.getCurrency(item.weeklyBoxKitAllowance));
			}

			if (item.dailyTotAllowances > 0) {
				content.push(this.FormMSG(118, 'Total Daily: ') + this.getCurrency(item.dailyTotAllowances));
			}

			return content.join('<br />');
		},
		tooltipContentOvertime(item) {
			let content = [];
			if (item.salaryOvertime > 0) {
				content.push(
					this.FormMSG(360, 'Overtime') +
						': ' +
						this.setWorked(item.hoursOvertime, item.minutesOvertime) +
						' | ' +
						this.formatCurrency(item.salaryOvertime)
				);
			}

			if (item.salaryOvertime1 > 0) {
				content.push(
					this.FormMSG(361, 'Overtime 1') +
						': ' +
						this.setWorked(item.hoursOvertime1, item.minutesOvertime1) +
						' | ' +
						this.formatCurrency(item.salaryOvertime1)
				);
			}

			if (item.salaryOvertime2 > 0) {
				content.push(
					this.FormMSG(363, 'Overtime 2') +
						': ' +
						this.setWorked(item.hoursOvertime2, item.minutesOvertime2) +
						' | ' +
						this.formatCurrency(item.salaryOvertime2)
				);
			}

			return content.join('<br />');
		},
		tooltipContentPerIdem(item) {
			let content = [];
			if (item.hotel && item.hotelPerDiem > 0) {
				content.push(this.FormMSG(103, 'Hotel: ') + this.getCurrency(item.hotelPerDiem));
			}

			if (item.lunch && item.lunchPerDiem > 0) {
				content.push(this.FormMSG(104, 'Lunch: ') + this.getCurrency(item.lunchPerDiem));
			}

			if (item.useDinner && item.dinnerPerDiem > 0) {
				content.push(this.FormMSG(473, 'Dinner: ') + this.getCurrency(item.dinnerPerDiem));
			}

			if (item.useAbroad && item.abroadPerDiem > 0) {
				content.push(this.FormMSG(474, 'Abroad: ') + this.getCurrency(item.abroadPerDiem));
			}

			return content.join('<br />');
		},
		formatTime(value) {
			return moment(value).format('HH:mm');
		},
		rendAllowanceType(type) {
			if (type == 0) {
				return this.FormMSG(112, 'D');
			} else if (type == 1) {
				return this.FormMSG(113, 'W');
			} else if (type == 2) {
				return this.FormMSG(111, 'H');
			}
		},
		tooltipContentAllowance(item) {
			let content = [];
			if (item.carAllowance > 0) {
				content.push(
					this.FormMSG(1110, 'Car ') + '(' + this.rendAllowanceType(item.contract.carAllowanceRate) + '): ' + this.getCurrency(item.carAllowance)
				);
			}

			if (item.computerAllowance > 0) {
				content.push(
					this.FormMSG(1114, 'Computer ') +
						'(' +
						this.rendAllowanceType(item.contract.computerAllowanceRate) +
						'): ' +
						this.getCurrency(item.computerAllowance)
				);
			}

			if (item.phoneAllowance > 0) {
				content.push(
					this.FormMSG(1115, 'Phone ') +
						'(' +
						this.rendAllowanceType(item.contract.phoneAllowanceRate) +
						'): ' +
						this.getCurrency(item.phoneAllowance)
				);
			}

			if (item.productionFeeAllowance > 0) {
				content.push(
					this.FormMSG(1116, 'Production fee ') +
						'(' +
						this.rendAllowanceType(item.contract.productionFeeAllowanceRate) +
						'): ' +
						this.getCurrency(item.productionFeeAllowance)
				);
			}

			if (item.boxKitAllowance > 0) {
				content.push(
					this.FormMSG(1117, 'Box kit ') +
						'(' +
						this.rendAllowanceType(item.contract.boxKitAllowanceRate) +
						'): ' +
						this.getCurrency(item.boxKitAllowance)
				);
			}

			return content.join('<br />');
		}
	}
};
</script>
<style lang="scss" scoped>
.h-text-color {
	color: rgba(6, 38, 62, 0.84);
}
.name-title {
	font-size: 14px;
	font-weight: 500;
	color: #06263e;
}
.column-flag-1 {
	font-size: 0.875rem !important;
	font-weight: 600;
	color: #06263e;
	width: 25%;
}
.column-flag-2 {
	font-size: 0.875rem !important;
	font-weight: 600;
	color: #06263e;
	width: 25%;
	text-align: center;
}
.column-flag-3 {
	font-size: 0.875rem !important;
	font-weight: 600;
	color: #06263e;
	width: 25%;
	text-align: center;
}
.column-flag-4 {
	font-weight: 400;
	font-size: 0.875rem !important;
	color: #06263e;
	width: 25%;
	text-align: right;
}
</style>
