<template>
	<div>
		<div class="w-100">
			<b-table-simple
				v-if="tsfields.length !== 0"
				small
				caption-top
				responsive
				:sticky-header="`${getHeaderHeight}px`"
				:style="{ 'min-height': minHeight + 'px' }"
				sticky-column="first"
				ref="my-table"
			>
				<b-thead>
					<b-tr>
						<b-th
							class="text-center middle"
							style="position: sticky; left: 0; z-index: 3; background-color: #00a09c; border: 1px solid #dee2e6; color: #ffff"
						>
							<div class="w-100">
								<div class="d-flex flex-row align-items-center justify-content-center h-50 gap-10">
									<div>{{ FormMSG(87, 'Member of team') }}</div>
									<div>
										<button
											class="btn-transparent text-color-rhapsody-in-blue"
											v-b-tooltip.hover.bottom.html="`${FormMSG(100, 'Add new user')}`"
											@click="handleAddNewUser"
										>
											<component :is="getLucideIcon(ICONS.PLUS_CIRCLE_USER.name)" :color="ICONS.CHEVRONS_LEFT.color" :size="18" />
										</button>
									</div>
								</div>
								<div class="d-flex flex-row align-items-center justify-content-center h-50">
									<div class="active-filter-custom" style="width: 150px">
										<treeselect
											:options="mapCustomFilter"
											:value="selectedFilter"
											:placeholder="FormMSG(102, 'Select filter')"
											@input="handleChangeFilter"
										>
											<div slot="value-label" slot-scope="{ node }" :title="node.label">
												<div class="treeselect-label">{{ showLimitText(node.label) }}</div>
											</div>
											<div class="d-flex align-items-center justify-content-center" slot="option-label" slot-scope="{ node }">
												<div class="label-select treeselect-label" :title="node.label">{{ node.label }}</div>
											</div>
										</treeselect>
									</div>
								</div>
							</div>
						</b-th>
						<b-th
							class="text-center middle"
							style="background-color: #00a09c; border: 1px solid #dee2e6; color: #ffff"
							v-for="item of tsfields.slice(1)"
							:key="generateSecureId(`${item.key}`)"
						>
							<div style="width: 150px">
								<div>{{ item.label }}</div>
								<div class="d-flex flex-row justify-content-center cursor-active">
									<div class="header-flag-status" v-b-tooltip.hover.bottom.html="handleShowLocationTooltip(item)">
										<span>{{ setTimeLocation(item) }}</span>
										<div class="show-edit-flag" @click="handleOpenFlagHeader(item)">
											<component :is="getLucideIcon(ICONS.CHECK_SQUARE.name)" color="#06263e" :size="16" />
										</div>
									</div>
								</div>
							</div>
						</b-th>
						<b-th
							v-if="isProduction"
							class="text-center middle"
							style="position: sticky; right: 0; z-index: 3; background-color: #00a09c; border: 1px solid #dee2e6; color: #ffff"
						>
							<div v-if="showVerified" class="d-flex flex-column justify-content-center transition-collapse-rt-lf">
								<div class="d-flex flex-row justify-content-center">{{ FormMSG(99, 'Somme') }}</div>
								<div v-if="showVerified" class="d-flex flex-column justify-content-center">
									<div class="d-flex flex-row justify-content-center">
										<button class="btn-transparent text-color-rhapsody-in-blue" @click="handleShowContentVerified">
											<component :is="getLucideIcon(ICONS.CHEVRONS_RIGHT.name)" :color="ICONS.CHEVRONS_RIGHT.color" :size="16" />
										</button>
									</div>
								</div>
								<div v-else class="d-flex flex-row justify-content-center">
									<button class="btn-transparent text-color-rhapsody-in-blue" @click="handleShowContentVerified">
										<component :is="getLucideIcon(ICONS.CHEVRONS_LEFT.name)" :color="ICONS.CHEVRONS_LEFT.color" :size="16" />
									</button>
								</div>
							</div>
							<div v-else class="d-flex flex-column transition-collapse-lf-rt" style="display: flex; background-color: #78807a">
								<div class="d-flex flex-row justify-content-start">
									<button class="btn-transparent text-color-rhapsody-in-blue" @click="handleShowContentVerified">
										<component :is="getLucideIcon(ICONS.CHEVRONS_LEFT.name)" :color="ICONS.CHEVRONS_LEFT.color" :size="16" />
									</button>
								</div>
							</div>
						</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="table-body" ref="tableRef" @mousedown="startScrollingLeft" @mouseup="stopScrollingLeft">
					<b-tr v-if="setAllTimesheets.length === 0">
						<b-td
							:colspan="tsfields.length === 0 ? 2 : tsfields.length + 1"
							class="text-center border-left init-background"
							style="border: 0.005rem solid rgba(226, 230, 235, 0.85); height: 50px"
						>
							<div>
								<div class="text-muted fs-14 w-100">{{ this.FormMSG(2, 'No data available') }}</div>
							</div>
						</b-td>
					</b-tr>
					<b-tr
						style="background: #f3f4f5"
						v-if="setAllTimesheets.length !== 0"
						v-for="item of setAllTimesheets"
						:key="generateSecureId(`${Math.random()}`)"
					>
						<b-td class="sticky-column-body-left" style="border: 0.005rem solid rgba(226, 230, 235, 0.85)">
							<div class="d-flex flex-row justify-content-start align-items-center">
								<div class="d-flex flex-column justify-content-start p-1" style="width: 150px; height: 60px">
									<div class="font-weight-bold fs-14">{{ item.fullName }}</div>
									<div>{{ item.functionName }}</div>
								</div>
								<div v-if="isProduction" class="d-flex flex-column justify-content-start align-items-center pd-1">
									<div class="d-flex flex-row w-100 show-hover">
										<b-button
											:disabled="checkValidatedAll(item)"
											@click="validateAllTsDays(item)"
											size="sm"
											class="btn bg-transparent border-0"
											v-b-tooltip.hover.bottom.html="`${FormMSG(153, 'Valid all')}`"
										>
											<component
												:is="getLucideIcon(ICONS.CHECK_SQUARE.name)"
												:color="ICONS.CHECK_SQUARE.color"
												:size="16"
												:stroke-width="2.25"
											/>
										</b-button>
									</div>
								</div>
								<div v-if="isProduction === false" class="d-flex flex-column justify-content-start align-items-center pd-1">
									<div class="d-flex flex-row w-100 show-hover">
										<b-button
											:disabled="checkNotSubmitted(item)"
											@click="sendAllForValidation(item)"
											size="sm"
											class="btn bg-transparent border-0"
											v-b-tooltip.hover.bottom.html="`${FormMSG(13, 'Send all for validation')}`"
										>
											<component :is="getLucideIcon(ICONS.SEND.name)" :color="ICONS.SEND.color" :size="16" :stroke-width="2.25" />
										</b-button>
									</div>
								</div>
							</div>
						</b-td>
						<b-td
							v-for="data of tsfields.slice(1)"
							:key="generateSecureId(`${data.key}`)"
							:class="checkCell(data.key, item.tsDays) ? 'init-background hover-not-empty' : 'edit-background hover-is-empty'"
							style="border: 0.005rem solid rgba(226, 230, 235, 0.85)"
							@contextmenu.prevent="
								checkCell(data.key, item.tsDays) && isSelectMultiple === false
									? showContextMenuOne($event, item, getDataOnCell(data.key, item, item.id))
									: showContextMenuMultiple($event, item, getDataOnCell(data.key, item, item.id))
							"
							@click.stop.prevent="(e) => handleAllActionPlanning(e, item, data.key, data)"
							@mousedown="(e) => startDrag(e)"
							@mouseover="(e) => selectCell(e, item, data.key, data)"
							@mouseup="(e) => endDrag(e)"
							:id="generateSecureId(`${item.id + item.fullName + item.departmentName + item.functionName + data.key}`)"
						>
							<div
								class="d-flex flex-column content-item-cell-fixed"
								:class="{
									'content-item-cell-is-prod': isProduction && checkCell(data.key, item.tsDays) && isShowBarStatus(data.key, item.tsDays)
								}"
							>
								<div v-if="checkCell(data.key, item.tsDays)" class="d-flex flex-column justify-content-center">
									<div class="d-flex flex-row">
										<div class="d-flex flex-row justify-content-start ml-1 mr-1 w-50">
											<div
												v-if="isRest(fimalacCellData(data.key, item.tsDays).slice(2)) !== '+0'"
												class="mr-1"
												v-b-tooltip.hover.left.html="tooltipContentOvertime(data.key, item.tsDays)"
												style="font-size: 12px"
											>
												<span class="badge badge-info">{{ isRest(fimalacCellData(data.key, item.tsDays).slice(2)) }}</span>
											</div>
										</div>
										<div class="d-flex flex-row justify-content-end w-50">
											<button
												class="btn-transparent text-color-rhapsody-in-blue"
												v-b-tooltip.hover.left.html="`${getLabelStatus(getStatus(data.key, item.tsDays))}`"
											>
												<div :class="`status-${getStatusColor(getStatus(data.key, item.tsDays))}`" style="font-size: 0.7rem"></div>
											</button>
										</div>
									</div>
									<div v-if="isPerDiem">
										<div class="d-flex flex-column mt-1 pr-1 pl-1" style="gap: 3px">
											<div
												v-for="(item, index) of fimalacCellData(data.key, item.tsDays).slice(0, 2)"
												:key="index"
												class="d-flex justify-content-center align-items-center pr-1 pl-1 text-black"
												:style="`background-color: ${item.color}; border-radius: 4px;height: 20px;font-size: 10px`"
											>
												<span :style="`color: ${isColorLight(item.color) ? 'black' : 'white'}; font-weight: bold`">{{
													getValue(item)
												}}</span>
											</div>
										</div>
									</div>
									<div v-if="isAnalyTicalCode">
										<div class="d-flex flex-column mt-1 pr-1 pl-1" style="gap: 3px">
											<div
												v-for="(item, index) of fimalacCellData(data.key, item.tsDays).slice(0, 2)"
												:key="index"
												class="d-flex justify-content-center align-items-center pr-1 pl-1 text-black"
												:style="`background-color: ${item.color}; border-radius: 4px;height: 20px;font-size: 10px`"
												v-b-tooltip.hover.left.html="`${getAnaliticalLabel(item)}`"
											>
												<span :style="`color: ${isColorLight(item.color) ? 'black' : 'white'}; font-weight: bold`">{{
													getAnalitical(item)
												}}</span>
											</div>
										</div>
									</div>
									<div v-if="isPrestations">
										<div class="d-flex flex-column mt-1 pr-1 pl-1" style="gap: 3px">
											<div
												v-for="(item, index) of fimalacCellData(data.key, item.tsDays).slice(0, 2)"
												:key="index"
												class="d-flex justify-content-center align-items-center pr-1 pl-1 text-black"
												:style="`background-color: ${item.color}; border-radius: 4px;height: 20px;font-size: 10px`"
												v-b-tooltip.hover.left.html="`${getPrestationsLabel(item)}`"
											>
												<span :style="`color: ${isColorLight(item.color) ? 'black' : 'white'}; font-weight: bold;`">{{
													getPrestations(item)
												}}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</b-td>
						<b-td
							v-if="isProduction && showVerified"
							class="sticky-column-body-right transition-collapse-rt-lf"
							style="background: rgb(202, 208, 215); width: 14px; height: 76px; border: 0.005rem solid rgba(226, 230, 235, 0.85)"
						>
							<div class="d-flex flex-row justify-content-center align-items-center">
								<div class="d-flex flex-column justify-content-center align-items-center pd-1" style="width: 100px; height: 70px">
									<div class="d-flex flex-row justify-content-center align-items-center w-100 show-hover">
										<b-button
											:disabled="checkSomme(totalSomme(item))"
											size="sm"
											class="btn bg-transparent border-0"
											v-b-tooltip.hover.left.html="isProduction && handleShowVerifiedTooltip(item)"
										>
											<component :is="getLucideIcon(ICONS.INFO.name)" :color="ICONS.INFO.color" :size="16" :stroke-width="2.25" />
										</b-button>
										<div class="font-weight-bold fs-14 mt-1">{{ totalSomme(item) }}</div>
									</div>
								</div>
							</div>
						</b-td>
						<b-td
							v-if="isProduction && showVerified === false"
							class="sticky-column-body-right-init transition-collapse-lf-rt"
							style="
								background: #cad0d7;
								width: auto;
								height: 76px;
								border-radius: 8px 0px 0px 8px;
								border: 0.005rem solid rgba(226, 230, 235, 0.85);
								width: 14px;
								margin-left: 22px;
							"
							@click="handleShowContentVerified"
						>
							<div class="d-flex flex-row justify-content-center align-items-center"></div>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
			<div v-else class="d-flex flex-row justify-content-center align-items-center card-gray text-muted fs-14 w-100">
				{{ this.FormMSG(2, 'No data available') }}
			</div>
			<div v-if="tsfields.length !== 0 && isBusy" class="d-flex justify-content-center align-items-center" style="height: 20px; gap: 5px">
				<div class="loading">{{ this.FormMSG(9, 'Loading') }}</div>
				<div class="bars-5"></div>
			</div>
			<div v-else-if="tsfields.length !== 0" class="d-flex flex-row justify-content-center align-items-center" style="height: 20px">
				<div class="end-of-list">{{ this.FormMSG(10, 'End of list') }}</div>
			</div>

			<div id="ContextMenu" class="context-menu" v-if="isProduction && showMenu" :style="{ top: menuY + 'px', left: menuX + 'px' }" ref="contextMenu">
				<div
					v-if="isShowSolveAndUpdate(this.selectedItem) && isShowMenuItem(this.selectedItem)"
					@click="onMenuItemClick(1)"
					class="d-flex justify-content-start align-items-center pd-1 menu-context"
				>
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon(ICONS.CHECK_CIRCLE.name)" :color="ICONS.CHECK_CIRCLE.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div>{{ FormMSG(22, 'Solve') }}</div>
				</div>
				<div
					v-if="isValidated(this.selectedItem) && !isShowSolveAndUpdate(this.selectedItem)"
					@click="onMenuItemClick(2)"
					class="d-flex justify-content-start align-items-center pd-1 menu-context"
				>
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon(ICONS.PENCIL.name)" :color="ICONS.PENCIL.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div>{{ this.FormMSG(7, 'Update') }}</div>
				</div>
				<div
					v-if="isValidated(this.selectedItem) && isShowSolveAndUpdate(this.selectedItem) && currentValidationLevel !== 0"
					@click="isValid ? onMenuItemClick(2.1) : isReject ? onMenuItemClick(2.2) : () => []"
					class="d-flex justify-content-start align-items-center pd-1 menu-context"
				>
					<b-button size="sm" class="btn bg-transparent border-0">
						<component
							v-if="isValid"
							:is="getLucideIcon(ICONS.CHECK_SQUARE.name)"
							:color="ICONS.CHECK_SQUARE.color"
							:size="16"
							:stroke-width="2.25"
						/>
						<component v-if="isReject" :is="getLucideIcon(ICONS.X_CIRCLE.name)" :color="ICONS.X_CIRCLE.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div>{{ isValid ? `${FormMSG(14, 'Validate')}` : `${FormMSG(15, 'Reject')}` }}</div>
				</div>
				<div
					v-if="isValidated(this.selectedItem) && !isShowSolveAndUpdate(this.selectedItem) && currentValidationLevel !== 0"
					@click="isValid ? onMenuItemClick(2.1) : isReject ? onMenuItemClick(2.2) : () => []"
					class="d-flex justify-content-start align-items-center pd-1 menu-context"
				>
					<b-button size="sm" class="btn bg-transparent border-0">
						<component
							v-if="isValid"
							:is="getLucideIcon(ICONS.CHECK_SQUARE.name)"
							:color="ICONS.CHECK_SQUARE.color"
							:size="16"
							:stroke-width="2.25"
						/>
						<component v-if="isReject" :is="getLucideIcon(ICONS.X_CIRCLE.name)" :color="ICONS.X_CIRCLE.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div>{{ isValid ? `${FormMSG(14, 'Validate')}` : `${FormMSG(15, 'Reject')}` }}</div>
				</div>
				<div
					v-if="
						this.selectedItem !== null &&
						this.selectedItem[0].tsDays[0].validated === 1 &&
						isValidated(this.selectedItem) &&
						!isShowSolveAndUpdate(this.selectedItem) &&
						currentValidationLevel !== 0
					"
					@click="onMenuItemClick(2.1)"
					class="d-flex justify-content-start align-items-center pd-1 menu-context"
				>
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon(ICONS.CHECK_SQUARE.name)" :color="ICONS.CHECK_SQUARE.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div>{{ FormMSG(14, 'Validate') }}</div>
				</div>
				<div @click="onMenuItemClick(4)" class="d-flex justify-content-start align-items-center pd-1 menu-context">
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon(ICONS.EYE.name)" :color="ICONS.EYE.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div>{{ FormMSG(11, 'See info') }}</div>
				</div>
				<div
					v-if="isValidated(this.selectedItem)"
					@click="onMenuItemClick(5)"
					class="d-flex justify-content-start align-items-center pd-1 menu-context"
				>
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon(ICONS.USERS.name)" :color="ICONS.USERS.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div class="d-flex">{{ this.FormMSG(20, 'Replacement') }}</div>
				</div>
				<div
					v-if="isValidated(this.selectedItem) && currentValidationLevel !== 0"
					@click="onMenuItemClick(6)"
					class="d-flex justify-content-start align-items-center pd-1 menu-context"
				>
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div class="d-flex">{{ this.FormMSG(6, 'Delete') }}</div>
				</div>
				<div @click="hideContextMenu()" class="d-flex justify-content-start align-items-center pd-1 menu-context">
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon('ShieldClose')" :color="ICONS.PENCIL.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div>{{ this.FormMSG(8, 'Cancel') }}</div>
				</div>
			</div>
			<div
				id="ContextMenu"
				class="context-menu"
				v-if="isProduction && showMenuMultiple"
				:style="{ top: menuY + 'px', left: menuX + 'px' }"
				ref="contextMenu"
			>
				<div
					v-if="checkIsSelectMultipleAndOnlyValidate()"
					@click="onMenuItemClick(1)"
					class="d-flex justify-content-start align-items-center pd-1 menu-context"
				>
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon(ICONS.CHECK_CIRCLE.name)" :color="ICONS.CHECK_CIRCLE.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div>{{ FormMSG(22, 'Solve') }}</div>
				</div>
				<div
					v-if="currentValidationLevel !== 0 && checkSelectAllPlanningToValidates()"
					@click="onMenuItemClick(9)"
					class="d-flex justify-content-start align-items-center pd-1 menu-context"
				>
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon(ICONS.CHECK_SQUARE.name)" :color="ICONS.CHECK_SQUARE.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div>{{ FormMSG(21, 'Validate(s)') }}</div>
				</div>
				<div v-if="isProduction === false" @click="onMenuItemClick(6)" class="d-flex justify-content-start align-items-center pd-1 menu-context">
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div class="d-flex">{{ this.FormMSG(6, 'Delete') }}</div>
				</div>
				<div @click="hideContextMenu()" class="d-flex justify-content-start align-items-center pd-1 menu-context">
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon('ShieldClose')" :color="ICONS.PENCIL.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div>{{ this.FormMSG(8, 'Cancel') }}</div>
				</div>
			</div>
			<div
				id="ContextMenu"
				class="context-menu"
				v-if="isProduction === false && showMenuMultiple && checkIsSelectMultipleAndOnly()"
				:style="{ top: menuY + 'px', left: menuX + 'px' }"
				ref="contextMenu"
			>
				<div v-if="isProduction === false" @click="onMenuItemClick(6)" class="d-flex justify-content-start align-items-center pd-1 menu-context">
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div class="d-flex">{{ this.FormMSG(6, 'Delete') }}</div>
				</div>
				<div @click="hideContextMenu()" class="d-flex justify-content-start align-items-center pd-1 menu-context">
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon('ShieldClose')" :color="ICONS.PENCIL.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div>{{ this.FormMSG(8, 'Cancel') }}</div>
				</div>
			</div>
			<div
				id="ContextMenu"
				class="context-menu"
				v-if="isProduction === false && (showMenu || showMenuMultiple)"
				:style="{ top: menuY + 'px', left: menuX + 'px' }"
				ref="contextMenu"
			>
				<div
					v-if="checkIsSelectMultipleAndOnly()"
					@click="onMenuItemClick(8)"
					class="d-flex justify-content-start align-items-center pd-1 menu-context"
				>
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon(ICONS.SEND.name)" :color="ICONS.SEND.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div>{{ FormMSG(12, 'Send For Validation') }}</div>
				</div>
				<div v-if="showMenuMultiple === false" @click="onMenuItemClick(2)" class="d-flex justify-content-start align-items-center pd-1 menu-context">
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon(ICONS.PENCIL.name)" :color="ICONS.PENCIL.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div>{{ this.FormMSG(7, 'Update') }}</div>
				</div>
				<div
					@click="onMenuItemClick(6)"
					v-if="isValidated(this.selectedItem)"
					class="d-flex justify-content-start align-items-center pd-1 menu-context"
				>
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div class="d-flex">{{ this.FormMSG(6, 'Delete') }}</div>
				</div>
				<div @click="hideContextMenu()" class="d-flex justify-content-start align-items-center pd-1 menu-context">
					<b-button size="sm" class="btn bg-transparent border-0">
						<component :is="getLucideIcon('ShieldClose')" :color="ICONS.PENCIL.color" :size="16" :stroke-width="2.25" />
					</b-button>
					<div>{{ this.FormMSG(8, 'Cancel') }}</div>
				</div>
			</div>
		</div>
		<b-modal
			header-class="header-class-modal-doc-package"
			:title="FormMSG(16, 'Informations')"
			class="modal-success"
			size="xl"
			v-model="showDetailModalCell"
			no-close-on-backdrop
			@ok="hideModal"
			ok-variant="success"
			ok-only
		>
			<div class="container-mobile animated fadeIn">
				<div class="content-detailsMyTimesheet-mobile">
					<show-detail-planning-cell-fimalac :showDetailData="showDetailData" />
				</div>
			</div>
		</b-modal>
		<b-modal
			header-class="header-class-modal-doc-package"
			:title="FormMSG(17, 'Refuse this day')"
			class="modal-danger"
			v-model="successModalRejectedTsDay"
			@hidden="onCloseRejectModal"
			no-close-on-backdrop
			ok-variant="danger"
			ok-only
		>
			<b-form-group :label="FormMSG(18, 'Enter your comment:')" label-for="comment" :label-cols="4">
				<b-form-textarea id="comment" @change="handleChangeComment" :placeholder="this.FormMSG(19, 'Enter your remarks...')" rows="3"></b-form-textarea>
			</b-form-group>
			<template #modal-footer>
				<div class="w-100">
					<b-button @click="finaliseTsDayReject" variant="danger" size="sm" class="float-right"> OK </b-button>
				</div>
			</template>
		</b-modal>
	</div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect';
import FlagItems from './FlagItems.vue';
import MonthSelect from '@/modules/fimalac/components/planning/MonthSelect';
import ShowDetailPlanningCellFimalac from '@/modules/fimalac/components/planning/ShowDetailPlanningCell';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { rejectTsDay } from '@/cruds/timesheets-efc.crud';
import { getAdminValidations } from '@/cruds/validations.crud';
import { ACTION_EFC_TIMESHEET } from '@/utils/utils';
import { rendCurrency } from '~helpers';
import { generateSecureId } from '@/shared/utils';
import { classStatus } from '@/shared/global-status';
import { store } from '@/store';
import moment from 'moment';

export default {
	name: 'PlanningViewTablesFimalac',
	components: { MonthSelect, ShowDetailPlanningCellFimalac, FlagItems, Treeselect },
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		id: {
			type: String,
			required: false
		},
		setFilter: {
			type: Function,
			required: false
		},
		setPages: {
			type: Function,
			required: false
		},
		onSearch: {
			type: Function,
			required: false
		},
		selectDate: {
			type: Array,
			required: false
		},
		formChanged: {
			type: Function,
			required: false
		},
		hover: {
			type: Boolean,
			required: false
		},
		timesheets: {
			type: Array,
			required: false
		},
		tsfields: {
			type: Array,
			required: false
		},
		currentpage: {
			type: Number,
			required: false
		},
		perpage: {
			type: Number,
			required: false
		},
		hv: {
			type: String,
			required: false
		},
		DateDefaultSelect: {
			type: Object,
			required: false,
			immediate: true
		},
		open: {
			type: Boolean,
			required: false
		},
		isPersistDataPlanning: {
			type: Boolean,
			required: false
		},
		isActionCrud: {
			type: String,
			required: false
		},
		initCustomFilter: {
			type: Boolean,
			required: false
		},
		hideContextMenuPlanning: {
			type: Boolean,
			required: false
		},
		initPlanningSelect: {
			type: Boolean,
			required: false
		},
		isAnalyTicalCode: {
			type: Boolean,
			required: false
		},
		isPrestations: {
			type: Boolean,
			required: false
		},
		isPerDiem: {
			type: Boolean,
			required: false
		}
	},
	data: () => {
		const { addData, updateData, deleteData } = ACTION_EFC_TIMESHEET;
		return {
			filter: '',
			currentForm: {
				value: 0,
				text: 'Select Month'
			},
			addData,
			updateData,
			deleteData,
			selectedFilter: 0,
			setPage: 0,
			reset: true,
			notReset: false,
			rendCurrency,
			isNoCtrolSelect: false,
			isKeyDate: '',
			dataActifs: [],
			dataActifsUpdate: [],
			generateSecureId,
			isBusy: false,
			showMenu: false,
			showMenuMultiple: false,
			menuX: 0,
			menuY: 0,
			selectedItem: null,
			MyFunctionHideContextMenu: false,
			scrollPosition: 0,
			isActiveClickCell: 1,
			sendDataToModalPlanning: [],
			isDragging: false,
			scrollLeftStart: 0,
			startX: 0,
			selectedCells: [],
			stateGlobalData: [],
			indexActif: 0,
			dateSelectedCells: [],
			stateGlobalDates: [],
			startIndex: 0,
			endIndex: 0,
			itemPersist: [],
			datePersist: [],
			arraysId: [],
			dataArraysStores: [],
			ctrlDataPersist: [],
			dataActifsActions: [],
			activeStart: false,
			dragging: false,
			classStatus,
			dataToValidate: [],
			dataToValidate: [],
			isValid: false,
			isReject: false,
			showDetailModalCell: false,
			successModalRejectedTsDay: false,
			rejectComment: '',
			showDetailData: {},
			rejectData: [],
			showVerified: false,
			arraysPlanningData: [],
			isSelectMultiple: false,
			copyItem: null,
			currentValidationLevel: 0,
			userValidationLevelData: [],
			minHeight: 300
		};
	},
	watch: {
		initCustomFilter: {
			handler(newVal) {
				if (newVal) {
					this.selectedFilter = 0;
					this.$emit('reset-init-custom-filter', false, this.selectedFilter);
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		timesheets: {
			async handler(newVal, oldVal) {
				if (this.isBusy) {
					return newVal;
				} else {
					return oldVal;
				}
			},
			immediate: true,
			deep: true
		},
		open: {
			handler(newVal) {
				if (newVal !== false) {
					if (this.isActionCrud === this.addData) {
						this.sendDataToModalPlanning = [...this.dataActifs, ...this.dataActifsUpdate];
						this.$emit('sendDataModalPlanning', this.sendDataToModalPlanning);
					}
				} else {
					if (this.isPersistDataPlanning) {
						this.sendDataToModalPlanning = [...this.dataActifs, ...this.dataActifsUpdate];
						this.$emit('sendDataModalPlanning', this.sendDataToModalPlanning);
					} else {
						this.dataActifs = [];
						this.dataActifsUpdate = [];
						this.sendDataToModalPlanning = [];
						this.$emit('sendDataModalPlanning', this.sendDataToModalPlanning);
						this.$emit('showModalPlanningContextUpdate', this.sendDataToModalPlanning);
					}
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		initPlanningSelect: {
			handler(newVal) {
				if (newVal) {
					// this.dataActifs = [];
					// this.dataActifsUpdate = [];
					// this.sendDataToModalPlanning = [];
					// this.$emit('sendDataModalPlanning', this.sendDataToModalPlanning);
					this.$emit('restore-init-planning-select');
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		dataActifs: {
			handler(newVal) {
				let data = newVal;
				if (data.length > 1) {
					this.isSelectMultiple = true;
				} else {
					this.isSelectMultiple = false;
				}
				this.$emit('setDataSizeModalPlanning', data);
				return newVal;
			},
			immediate: true,
			deep: true
		},
		showMenu: {
			handler(newVal) {
				if (newVal === false) {
					this.menuX = 0;
					this.menuY = 0;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		showMenuMultiple: {
			handler(newVal) {
				if (newVal === false) {
					this.menuX = 0;
					this.menuY = 0;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		hideContextMenuPlanning: {
			handler(newVal) {
				if (newVal) {
					this.hideContextMenu();
					this.showMenu = false;
					this.showMenuMultiple = false;
					this.dataActifsUpdate = [];
					this.dataActifs = [];
					this.$emit('hideContextMenuPlanningInit', false);
				}
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	created() {
		// this.GetTimesheetsToValidate();
		this.getValidationLevel();
	},
	mounted() {
		//init table scroll
		if (this.$refs['my-table'] !== undefined) {
			const tableScrollBody = this.$refs['my-table'].$el;
			tableScrollBody.addEventListener('mousemove', this.detectDirection);
			tableScrollBody.addEventListener('mouseup', this.stopDrag);
			tableScrollBody.addEventListener('scroll', this.onScroll);
		}
	},
	beforeDestroy() {
		if (this.$refs['my-table'] !== undefined) {
			const tableScrollBody = this.$refs['my-table'].$el;
			tableScrollBody.removeEventListener('scroll', this.onScroll);
		}
	},
	computed: {
		getHeaderHeight() {
			let WindowInnerHeight = window.innerHeight;
			let screenHeightPlanning = Math.round((WindowInnerHeight * 86.58) / 100);
			return screenHeightPlanning;
		},
		mapCustomFilter() {
			return [
				{ id: 0, label: this.FormMSG(370, 'Select filter'), isDisabled: true },
				{ id: 1, label: this.FormMSG(98, 'Filter by name') },
				{ id: 2, label: this.FormMSG(152, 'Filter by function') },
				{ id: 3, label: this.FormMSG(101, 'Custom filter') }
			];
		},
		setDataTableFields() {
			return this.dataTable;
		},
		setAllTimesheets() {
			if (this.$refs['my-table'] !== undefined) {
				const tableScrollBody = this.$refs['my-table'].$el;
				tableScrollBody.addEventListener('scroll', this.onScroll);
			}
			return this.timesheets;
		},
		isProduction() {
			return store.canViewGlobalBudget();
		},
		isRegisseur() {
			return !store.canViewGlobalBudget();
		},
		isToValidate() {
			return false;
		}
	},
	methods: {
		handleChangeComment(value) {
			this.rejectComment = value;
		},
		handleEditFlag() {
			console.log('active focus');
		},
		isColorLight(color) {
			const hex = color.replace('#', '');
			const red = parseInt(hex.substring(0, 0 + 2), 16);
			const green = parseInt(hex.substring(2, 2 + 2), 16);
			const blue = parseInt(hex.substring(4, 4 + 2), 16);
			const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
			return brightness > 155;
		},
		handleAddNewUser() {
			this.$emit('on-open-modal-new-user', true);
		},
		showLimitText(text) {
			if (text.length > 18) {
				return text.toString().substr(0, 15) + ' ...';
			}
			return text;
		},
		handleChangeFilter(value) {
			if (value !== undefined) {
				this.selectedFilter = value;
			} else {
				this.selectedFilter = 0;
			}
			this.$emit('filter-custom-user', this.selectedFilter);
		},
		isRest(item) {
			if (item !== null || item !== undefined) {
				return '+' + item.length;
			}
		},
		getValue(item) {
			if (item !== null || item !== undefined) {
				return (
					item.projectFlagItem.short.toString().toUpperCase() +
					(this.isProduction && +item.activity.id !== 0 && +item.category !== 0 ? ' (' + rendCurrency(item.amount) + ')' : '')
				);
			}
		},
		getAnalitical(item) {
			if (item !== null || item !== undefined) {
				let projet = item.costCenter.split`$`[0];
				let activity = item.activity.short;
				let short = item.projectFlagItem.short;
				let message = projet + activity + short;
				if (message.length > 18) {
					return message.substr(0, 15) + ' ...' + ' ' + rendCurrency(item.amount);
				}
				return message + ' ' + rendCurrency(item.amount);
			}
		},
		getAnaliticalCode(item) {
			if (item !== null || item !== undefined) {
				let projet = item.costCenter.split`$`[0];
				let activity = item.activity.short;
				let short = item.projectFlagItem.short;
				let message = projet + activity + short;
				return message;
			}
		},
		getAnaliticalLabel(item) {
			if (item !== null || item !== undefined) {
				let projet = item.costCenter.split`$`[0];
				let activity = item.activity.short;
				let short = item.projectFlagItem.short;
				let message = projet + activity + short + ' ' + rendCurrency(item.amount);
				return message;
			}
		},
		getPrestations(item) {
			if (item !== null || item !== undefined) {
				let projet = item.costCenter.split`$`[0];
				let short = item.projectFlagItem.short;
				let name = item.projectFlagItem.name;
				let message = projet + name + short;
				if (message.length > 18) {
					return message.substr(0, 15) + ' ...' + ' ' + rendCurrency(item.amount);
				}
				return message + ' ' + rendCurrency(item.amount);
			}
		},
		getPrestationsLabel(item) {
			if (item !== null || item !== undefined) {
				let projet = item.costCenter.split`$`[0];
				let short = item.projectFlagItem.short;
				let name = item.projectFlagItem.name;
				let message = projet + name + short + ' ' + rendCurrency(item.amount);
				return message;
			}
		},
		async getValidationLevel() {
			const userId = store.userID();
			let data = await getAdminValidations();
			let result = data.find((user) => +user.id === +userId);
			if (result !== undefined) {
				let validations = result.validations;
				if (validations.length !== 0) {
					// check first all departments
					let res = validations.find((item) => item.department === -1);
					if (res !== undefined) {
						this.currentValidationLevel = res.validationLevelTs;
					} else {
						let responses = validations.map((item) => item.validationLevelTs);
						if (responses.some((val) => val === 1)) {
							this.currentValidationLevel = 1;
						} else {
							this.currentValidationLevel = 2;
						}
					}
				} else {
					this.currentValidationLevel = 0;
				}
			} else {
				this.currentValidationLevel = 0;
			}
			this.$emit('setCurrentValidationLevel', this.currentValidationLevel);
		},
		totalSomme(item) {
			if (this.isProduction) {
				let sum = [...item.tsDays]
					.filter((item) => item.validated !== 0)
					.map((item) => {
						let flag_data = item.tsDayDetails;
						if (flag_data.length !== 0) {
							let flag_valid = flag_data.filter((item) => +item.category !== 0 && +item.activity.id !== 0);
							let flag_amount = flag_valid.map((x) => +x.amount).reduce((a, b) => a + b, 0);
							return flag_amount;
						}
						return 0;
					})
					.reduce((a, b) => a + b, 0);
				return `${rendCurrency(sum)}`;
			} else {
				return `${rendCurrency(0)}`;
			}
		},
		checkSomme(somme) {
			let checked = +somme.split` `[0] !== 0 ? false : true;
			return checked;
		},
		handleOpenFlagHeader(item) {
			this.$emit('planning-view-tables:edit-location', item);
		},
		setTimeLocation(item) {
			if (item.name.toString().length !== 0) {
				if (item.name.length <= 15) {
					return item.name;
				}
				return item.name.substr(0, 13) + ' ...';
			}
			return this.FormMSG(153, 'OFF');
		},
		getStatus(key, arrays) {
			let _data = arrays;
			if (_data !== undefined) {
				let result = _data.filter((item) => {
					if (moment(item.date).format('DD/MM/YYYY') === key) {
						return true;
					}
					return false;
				});
				if (result.length !== 0) {
					return result[0].validated;
				}
			}
		},
		getStatusColor(validated) {
			let retval = 'not-submitted';
			if (validated == 1) {
				retval = 'info';
			} else if (validated == 2) {
				retval = 'pending';
			} else if (validated == 8) {
				retval = 'validated';
			} else if (validated == 4 || validated == 16) {
				retval = 'refused';
			}
			return retval;
		},
		getLabelStatus(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1008, value);
		},
		onCloseRejectModal() {
			this.successModalRejectedTsDay = false;
			setTimeout(() => {
				let data = [...this.dataActifs, ...this.dataActifsUpdate];
				if (data.length !== 0) {
					this.setDataActionAnim(data);
				}
			}, [1]);
		},
		async finaliseTsDayReject() {
			let TsDayId = +this.rejectData[0].id;
			let Comment = this.rejectComment;
			this.successModalRejectedTsDay = true;
			if (Comment.length !== 0) {
				let result = await rejectTsDay(TsDayId, Comment)
					.then(async (result) => {
						if (result) {
							this.successModalRejectedTsDay = false;
							this.$emit('handleRefreshReject');
							// this.GetTimesheetsToValidate();
							this.rejectComment = '';
							this.rejectData = [];
						}
					})
					.catch((error) => {
						throw new error();
					});
				return result;
			} else {
				this.successModalRejectedTsDay = true;
			}
		},
		getStatus(key, arrays) {
			let _data = arrays;
			if (_data !== undefined) {
				let result = _data.filter((item) => {
					if (moment(item.date).format('DD/MM/YYYY') === key) {
						return true;
					}
					return false;
				});
				if (result.length !== 0) {
					return result[0].validated;
				}
			}
		},
		handleShowVerifiedTooltip(item) {
			if (this.isProduction) {
				let data = item.tsDays
					.filter((item) => item.validated !== 0)
					.map((item) => item.tsDayDetails)
					.flat(Infinity)
					.filter((item) => +item.category !== 0 && +item.activity.id !== 0);
				if (data !== undefined) {
					let groupeFlags = data.reduce((accumulator, current) => {
						let existFlag = accumulator.find((item) => item.flagItem.short === current.projectFlagItem.short);
						if (existFlag) {
							existFlag.data.push(current);
						} else {
							accumulator.push({ flagItem: current.projectFlagItem, data: [current] });
						}
						return accumulator;
					}, []);
					let arrays = [];
					if (groupeFlags.length !== 0) {
						for (let i = 0; i < groupeFlags.length; i++) {
							let shortName = groupeFlags[i].flagItem.short;
							let color = groupeFlags[i].flagItem.color;
							let somme = groupeFlags[i].data.map((item) => item.amount).reduce((a, b) => a + b, 0);
							let _data = groupeFlags[i].data
								.map((item) => {
									return { amount: item.amount, name: this.getAnaliticalCode(item), color: '#' + item.projectFlagItem.color };
								})
								.reduce((accumulator, current) => {
									let existFlag = accumulator.find((item) => item.name === current.name);
									if (existFlag) {
										existFlag.data.push(current);
										existFlag.amount += current.amount;
									} else {
										accumulator.push({ color: current.color, name: current.name, data: [current], amount: current.amount || 0 });
									}
									return accumulator;
								}, []);
							arrays.push({ shortName: shortName, currency: rendCurrency(somme), color: '#' + color, data: _data });
						}
					}
					arrays = arrays.map((item) => {
						let data = item.data.map((item) => {
							let html = `<div class="d-flex flex-row align-items-center p-1" style="gap: 10px">
											<div style="width: 5px; height: 5px; background-color: ${item.color}; border-radius: 50%"></div>
											<div>${item.name} ${rendCurrency(item.amount)}</div>								
										</div>`;
							return html;
						});
						let html = `<div class="d-flex flex-row align-items-center p-1" style="gap: 10px">
										<div style="width: 5px; height: 5px; background-color: ${item.color}; border-radius: 50%"></div>
										<div>
											${item.shortName} - ${item.currency}
										</div>
									</div>`;
						return this.isAnalyTicalCode ? data : html;
					}).join``;
					let html = `
						<div class="d-flex justify-content-center align-items-center flex-column">
							${arrays}
						</div>`;
					return html;
				}
			} else {
				let data = item.tsDays
					.filter((item) => item.validated === 0)
					.map((item) => item.tsDayDetails)
					.flat(Infinity);
				if (data !== undefined) {
					let groupeFlags = data.reduce((accumulator, current) => {
						let existFlag = accumulator.find((item) => item.flagItem.short === current.projectFlagItem.short);
						if (existFlag) {
							existFlag.data.push(current);
						} else {
							accumulator.push({ flagItem: current.projectFlagItem, data: [current] });
						}
						return accumulator;
					}, []);
					let arrays = [];
					if (groupeFlags.length !== 0) {
						for (let i = 0; i < groupeFlags.length; i++) {
							let shortName = groupeFlags[i].flagItem.short;
							let somme = groupeFlags[i].data.map((item) => item.amount).reduce((a, b) => a + b, 0);
							arrays.push({ shortName: shortName, currency: rendCurrency(somme) });
						}
					}
					arrays = arrays
						.map((item) => {
							return `<div>Total of ${item.shortName}- <span>${item.currency}</span></div>`;
						})
						.join('\n');
					let html = `
				<div class="d-flex flex-column">
					<div>
						<div>Data of pay</div>
						${arrays}
					</div>
				</div>`;
					return html;
				}
			}
		},
		handleShowLocationTooltip(item) {
			return item.name;
		},
		handleShowContentVerified() {
			this.showVerified = !this.showVerified;
			setTimeout(() => {
				this.setDataActionAnim(this.dataActifs);
			}, [1]);
		},
		getUniqueCombination(data) {
			let uniqueCombinations = new Set();
			let uniqueData = data.filter((item) => {
				let combination = `${item.date}-${item.userId}`;
				if (!uniqueCombinations.has(combination)) {
					uniqueCombinations.add(combination);
					return true;
				}
				return false;
			});
			return uniqueData;
		},
		getWorked(key, arrays) {
			let _data = arrays;
			if (_data !== undefined) {
				let result = _data.filter((item) => {
					if (moment(item.date).format('DD/MM/YYYY') === key) {
						return true;
					}
					return false;
				});
				if (result.length !== 0) {
					let hours = result[0].hours.toString().padStart('2', '0');
					let minutes = result[0].minutes.toString().padStart('2', '0');
					return `${hours}:${minutes}`;
				}
			}
		},
		checkCell(key, arrays) {
			let _data = arrays;
			if (_data !== undefined) {
				let result = _data.filter((item) => {
					if (moment(item.date).format('DD/MM/YYYY') === key) {
						return true;
					}
					return false;
				});
				if (result.length !== 0) {
					if (result[0].tsDayDetails.length !== 0) {
						if (this.isProduction) {
							if (result[0].validated === 0) {
								return false;
							}
							return true;
						} else {
							return true;
						}
					}
					return false;
				}
				return false;
			}
		},
		isValidationStatus(key, arrays) {
			let _data = arrays;
			if (_data !== undefined) {
				let result = _data.filter((item) => {
					if (moment(item.date).format('DD/MM/YYYY') === key) {
						return true;
					}
					return false;
				});
				if (result.length !== 0) {
					let validation = result[0].validated;
					if (validation === 0) {
						return true;
					}
					return false;
				}
				return true;
			}
		},
		isShowSolveAndUpdate(item) {
			if (item !== undefined && item[0].tsDays[0].tsDayDetails !== undefined) {
				let data = item[0].tsDays[0].tsDayDetails.map((item) => {
					if (+item.activity.id === 0 && item.category === 0) {
						return true;
					}
					return false;
				});
				let response = data.some((status) => status === true);
				if (response) {
					return true;
				}
				return false;
			}
			return false;
		},
		isShowBarStatus(key, arrays) {
			let _data = arrays;
			if (_data !== undefined) {
				let result = _data.filter((item) => {
					if (moment(item.date).format('DD/MM/YYYY') === key) {
						return true;
					}
					return false;
				});
				if (result.length !== 0 && result[0] !== undefined) {
					let checkBarStatus = result[0].tsDayDetails.map((item) => {
						if (+item.activity.id === 0 && item.category === 0) {
							return true;
						}
						return false;
					});
					let response = checkBarStatus.some((status) => status === true);
					if (response) {
						return true;
					}
					return false;
				}
				return true;
			}
		},
		fimalacCellData(key, arrays) {
			let _data = arrays;
			if (_data !== undefined) {
				let result = _data.filter((item) => {
					if (moment(item.date).format('DD/MM/YYYY') === key) {
						return true;
					}
					return false;
				});
				if (result[0] !== undefined) {
					let response = result[0].tsDayDetails.map((option) => {
						return { ...option, color: '#' + option.projectFlagItem.color };
					});
					return response;
				}
			}
		},
		getDataOnCell(key, arrays, userId) {
			let _data = arrays.tsDays;
			if (_data !== undefined) {
				let result = _data.filter((item) => {
					if (moment(item.date).format('DD/MM/YYYY') === key) {
						return true;
					}
					return false;
				});
				return [{ ...arrays, tsDays: [{ ...result[0], userId }] }];
			}
		},
		getSalary(key, arrays) {
			let _data = arrays;
			if (_data !== undefined) {
				let result = _data.filter((item) => {
					if (moment(item.date).format('DD/MM/YYYY') === key) {
						return true;
					}
					return false;
				});
				if (result.length !== 0) {
					return `${rendCurrency(result[0].salary)}`;
				}
			}
		},
		tooltipContentOvertime(key, arrays) {
			let flagProject = this.fimalacCellData(key, arrays)
				.slice(2)
				.map((item) => {
					if (this.isPerDiem) {
						return `<div class="d-flex flex-row align-items-center p-1" style="gap: 10px">
									<div style="width: 5px; height: 5px; background-color: ${item.color}; border-radius: 50%"></div>
									<div>${this.getValue(item)}</div>
								</div>`;
					}
					if (this.isAnalyTicalCode) {
						return `<div class="d-flex flex-row align-items-center p-1" style="gap: 10px">
									<div style="width: 5px; height: 5px; background-color: ${item.color}; border-radius: 50%"></div>
									<div>${this.getAnalitical(item)}</div>
								</div>`;
					}
					if (this.isPrestations) {
						return `<div class="d-flex flex-row align-items-center p-1" style="gap: 10px">
									<div style="width: 5px; height: 5px; background-color: ${item.color}; border-radius: 50%"></div>
									<div>${this.getPrestationsLabel(item)}</div>
								</div>`;
					}
				}).join``;
			return `${flagProject}`;
		},
		setDataActionAnim(data) {
			if (data !== undefined) {
				for (let i = 0; i < data.length; i++) {
					const style = document.getElementById(data[i].securedId).style;
					const classActive = document.getElementById(data[i].securedId).children[0].classList;
					let isShowBarStatus = this.isShowBarStatus(data[i].date, data[i].dataAdd.tsDays);
					let validationStatus = this.isValidationStatus(data[i].date, data[i].dataAdd.tsDays);
					let checkCell = this.checkCell(data[i].date, data[i].dataAdd.tsDays);
					if (this.isProduction && checkCell && isShowBarStatus && validationStatus) {
						classActive.add('content-item-cell-is-prod');
					}
					// if (this.isProduction && checkCell && !isShowBarStatus && validationStatus) {
					// 	classActive.add('content-item-cell-is-to-validate');
					// }
					document.getElementById(data[i].securedId).style = style;
					document.getElementById(data[i].securedId).style.backgroundColor = '#00a09c';
					document.getElementById(data[i].securedId).style.color = '#fff';
					document.getElementById(data[i].securedId).style.border = '0.005rem solid rgba(226, 230, 235, 0.85)';
					document.getElementById(data[i].securedId).style.height = '50px';
				}

				this.dataActifs = this.getUniqueCombination([...this.dataActifs, ...data]);
			}
		},
		setDataActionAnimInit(data) {
			if (data !== undefined) {
				for (let i = 0; i < data.length; i++) {
					const style = document.getElementById(data[i].securedId).style;
					const classActive = document.getElementById(data[i].securedId).children[0].classList;
					if (document.getElementById(data[i].securedId).children[0].firstElementChild !== null) {
						let isShowBarStatus = this.isShowBarStatus(data[i].date, data[i].dataAdd.tsDays);
						let validationStatus = this.isValidationStatus(data[i].date, data[i].dataAdd.tsDays);
						let checkCell = this.checkCell(data[i].date, data[i].dataAdd.tsDays);
						if (this.isProduction && checkCell && isShowBarStatus && validationStatus) {
							classActive.add('content-item-cell-is-prod');
						}
						// if (this.isProduction && checkCell && !isShowBarStatus && validationStatus) {
						// 	classActive.add('content-item-cell-is-to-validate');
						// }
					}
					document.getElementById(data[i].securedId).style = style;
					document.getElementById(data[i].securedId).style.border = '0.005rem solid rgba(226, 230, 235, 0.85)';
					document.getElementById(data[i].securedId).style.height = '50px';
				}
			}
		},
		resetClass(id, key, item) {
			const parentElement = document.getElementById(id);
			const style = document.getElementById(id).style;
			const classActive = document.getElementById(id).children[0].classList;
			let isShowBarStatus = this.isShowBarStatus(key, item);
			let validationStatus = this.isValidationStatus(key, item);
			let checkCell = this.checkCell(key, item);
			if (document.getElementById(id).children[0].firstElementChild !== null) {
				if (this.isProduction && checkCell && isShowBarStatus && validationStatus) {
					classActive.add('content-item-cell-is-prod');
				}
				// if (this.isProduction && checkCell && !isShowBarStatus && validationStatus) {
				// 	classActive.add('content-item-cell-is-to-validate');
				// }
			}
			document.getElementById(id).style = style;
			document.getElementById(id).style.border = '0.005rem solid rgba(226, 230, 235, 0.85)';
			document.getElementById(id).style.height = '50px';
		},
		activeClass(id, key, item) {
			const classActive = document.getElementById(id).children[0].classList;
			const style = document.getElementById(id).style;
			document.getElementById(id).style = style;
			document.getElementById(id).style.backgroundColor = '#00a09c';
			document.getElementById(id).style.color = '#fff';
			let isShowBarStatus = this.isShowBarStatus(key, item);
			let validationStatus = this.isValidationStatus(key, item);
			let checkCell = this.checkCell(key, item);
			if (this.isProduction && checkCell && isShowBarStatus && validationStatus) {
				classActive.add('content-item-cell-is-prod');
			}
			// if (this.isProduction && checkCell && !isShowBarStatus && validationStatus) {
			// 	classActive.add('content-item-cell-is-to-validate');
			// }
			if (document.getElementById(id).querySelectorAll('svg > *')[0] !== undefined) {
				document.getElementById(id).querySelectorAll('svg > *')[0].style.fill = '#fff';
			}
			document.getElementById(id).style.border = '0.005rem solid rgba(226, 230, 235, 0.85)';
			document.getElementById(id).style.height = '50px';
		},
		startDrag(event) {
			if (event.button === 0) {
				this.dragging = true;
			}
		},
		selectCell(event, item, key, data) {
			if (event.button === 0) {
				if (this.dragging) {
					this.handleShiftWithClick(event, item, key, data);
				}
			}
		},
		endDrag(event) {
			if (event.button === 0) {
				this.dragging = false;
			}
		},
		getTsDayOneCell(item, key) {
			if (item !== undefined || item !== null) {
				let tsDay = item.tsDays.find((item) => moment(item.date).format('DD/MM/YYYY') === key);
				return tsDay;
			}
		},
		handleCtrlClick(event, item, key, headerLocation) {
			let id = generateSecureId(`${item.id + item.fullName + item.departmentName + item.functionName + key}`);
			this.dataActifsActions = this.getUniqueCombination([...this.dataActifsActions, ...this.dataActifs]);
			if (this.dataActifs.length === 0) {
				this.activeClass(id, key, item.tsDays);
				let tsDay = this.getTsDayOneCell(item, key);
				this.dataActifs = [
					...this.dataActifs,
					{ securedId: id, userId: +item.id, fullName: item.fullName, date: key, dataAdd: item, locations: headerLocation.locations, tsDay: tsDay }
				];
			} else {
				let isFind = this.dataActifs.find((e) => e.securedId === id);

				if (isFind !== undefined) {
					this.resetClass(id, key, item.tsDays);
					// data actifs max arrays number 2
					this.dataActifs = this.dataActifs.filter((item) => item.securedId !== id);
					this.dateSelectedCells = this.dateSelectedCells.filter((date) => date !== key);
					// data actifs actions no max number of arrays
					this.dataActifsActions = this.dataActifsActions.filter((item) => item.securedId !== id);
					this.dataArraysStores = this.dataActifs;
				} else {
					this.activeClass(id, key, item.tsDays);
					this.setDataActionAnim(this.dataActifsActions);
					if (this.selectedCells.length !== 0) {
						this.stateGlobalData = [...this.stateGlobalData, this.selectedCells];
						this.stateGlobalDates = [...this.stateGlobalDates, this.dateSelectedCells];
					}
					this.indexActif = 1;
					let tsDay = this.getTsDayOneCell(item, key);
					this.dataActifs = [
						...this.dataActifs,
						{
							securedId: id,
							userId: +item.id,
							fullName: item.fullName,
							date: key,
							dataAdd: item,
							locations: headerLocation.locations,
							tsDay: tsDay
						}
					];
					this.selectedCells = [
						{
							securedId: id,
							userId: +item.id,
							fullName: item.fullName,
							index: this.indexActif,
							date: key,
							indexOfItem: [...this.setAllTimesheets].indexOf(item),
							dataAdd: item,
							locations: headerLocation.locations,
							tsDay: tsDay
						}
					];
				}
			}
		},
		handleClickOnly(event, item, key, headerLocation) {
			let id = generateSecureId(`${item.id + item.fullName + item.departmentName + item.functionName + key}`);
			let dataActifs = this.dataActifs;
			let dataActifsUpdate = this.dataActifsUpdate;
			this.indexActif = 0;
			this.selectedCells = [];
			this.setDataActionAnimInit(this.stateGlobalData[0]);
			this.stateGlobalData = [];
			this.dataActifsActions = [];
			if (this.itemPersist[this.itemPersist.length - 1] !== undefined) {
				for (let i = 0; i < this.itemPersist[this.itemPersist.length - 1].length; i++) {
					for (let j = 0; j < this.dateSelectedCells.length; j++) {
						let key = this.dateSelectedCells[j];
						let id = generateSecureId(
							`${
								this.itemPersist[this.itemPersist.length - 1][i].id +
								this.itemPersist[this.itemPersist.length - 1][i].fullName +
								this.itemPersist[this.itemPersist.length - 1][i].departmentName +
								this.itemPersist[this.itemPersist.length - 1][i].functionName +
								key
							}`
						);
						if (document.getElementById(id) !== null) {
							this.resetClass(id, key, this.itemPersist[this.itemPersist.length - 1][i].tsDays);
						}
					}
				}
			}
			if (this.dataActifs.length !== 0 && this.dataActifs[0].securedId === id) {
				this.resetClass(id, key, item.tsDays);
				this.dataActifs = [];
				this.dataActifsUpdate = [];
				this.handleSendDataPlanningToModal();
			} else {
				let indexOfItem = [...this.setAllTimesheets].indexOf(item);
				dataActifs.forEach((item) => {
					if (document.getElementById(item.securedId) !== null) {
						this.resetClass(item.securedId);
					}
				});
				dataActifsUpdate.forEach((item) => {
					if (document.getElementById(item.securedId) !== null) {
						document.getElementById(item.securedId).style.backgroundColor = '#ffff';
					}
				});
				this.arraysId.forEach((id) => {
					if (document.getElementById(id) !== null) {
						this.resetClass(id);
					}
				});
				this.dataActifs = [];
				this.dataActifsUpdate = [];
				this.arraysId = [];
				this.dataArraysStores = [];
				this.selectedCells = [];
				this.dateSelectedCells = [];
				this.stateGlobalData = [];
				this.stateGlobalDates = [];
				this.handleSendDataPlanningToModal();
				const color = document.getElementById(id).style.color;
				document.getElementById(id).style.backgroundColor = '#00a09c';
				if (!this.getWorked(key, item.tsDays)) {
					document.getElementById(id).style.color = color;
				} else {
					const classActive = document.getElementById(id).children[0].classList;
					let isShowBarStatus = this.isShowBarStatus(key, item.tsDays);
					let validationStatus = this.isValidationStatus(key, item.tsDays);
					let checkCell = this.checkCell(key, item.tsDays);
					if (this.isProduction && checkCell && isShowBarStatus && validationStatus) {
						classActive.add('content-item-cell-is-prod');
					}
					// if (this.isProduction && checkCell && !isShowBarStatus && validationStatus) {
					// 	classActive.add('content-item-cell-is-to-validate');
					// }
					document.getElementById(id).style.color = '#ffff';
					document.getElementById(id).style.fontWeight = 'bold';
					document.getElementById(id).querySelectorAll('svg > *')[0] !== undefined &&
						(document.getElementById(id).querySelectorAll('svg > *')[0].style.fill = '#fff');
				}
				this.activeStart = true;
				let tsDay = this.getTsDayOneCell(item, key);
				this.dataActifs = [
					{
						securedId: id,
						userId: +item.id,
						fullName: item.fullName,
						index: 1,
						indexOfItem: indexOfItem,
						date: key,
						dataAdd: item,
						locations: headerLocation.locations,
						tsDay: tsDay
					}
				];
				this.ctrlDataPersist = this.dataActifs;
			}
		},
		handleShiftWithClick(event, item, key, headerLocation) {
			let id = generateSecureId(`${item.id + item.fullName + item.departmentName + item.functionName + key}`);
			this.indexActif += 1;
			let indexOfItem = [...this.setAllTimesheets].indexOf(item);

			if (this.indexActif > 1) {
				this.indexActif = 2;
				let id = generateSecureId(`${item.id + item.fullName + item.departmentName + item.functionName + key}`);
				let tsDay = this.getTsDayOneCell(item, key);
				this.selectedCells = [
					this.selectedCells[0],
					{
						securedId: id,
						userId: +item.id,
						fullName: item.fullName,
						index: this.indexActif,
						date: key,
						indexOfItem: indexOfItem,
						dataAdd: item,
						locations: headerLocation.locations,
						tsDay: tsDay
					}
				];
				this.dataActifs = this.getUniqueCombination([...this.dataActifs, ...this.selectedCells]);
				this.setDataActionAnim(this.dataActifsActions);
			} else {
				if (this.activeStart && this.dataActifs.length !== 0) {
					this.indexActif = 2;
					let id = generateSecureId(`${item.id + item.fullName + item.departmentName + item.functionName + key}`);
					let lastId = generateSecureId(
						`${
							this.dataActifs[0].dataAdd.id +
							this.dataActifs[0].dataAdd.fullName +
							this.dataActifs[0].dataAdd.departmentName +
							this.dataActifs[0].dataAdd.functionName +
							this.dataActifs[0].date
						}`
					);
					let tsDay = this.getTsDayOneCell(item, key);
					this.selectedCells = [
						{
							securedId: lastId,
							userId: +this.dataActifs[0].dataAdd.id,
							fullName: this.dataActifs[0].fullName,
							index: 1,
							date: this.dataActifs[0].date,
							indexOfItem: [...this.setAllTimesheets].indexOf(this.dataActifs[0].dataAdd),
							dataAdd: this.dataActifs[0].dataAdd,
							locations: this.dataActifs[0].locations,
							tsDay: this.dataActifs[0].tsDay
						},
						{
							securedId: id,
							userId: +item.id,
							fullName: item.fullName,
							index: this.indexActif,
							date: key,
							indexOfItem: indexOfItem,
							dataAdd: item,
							locations: headerLocation.locations,
							tsDay: tsDay
						}
					];
					this.activeStart = false;
				} else {
					let id = generateSecureId(`${item.id + item.fullName + item.departmentName + item.functionName + key}`);
					let tsDay = this.getTsDayOneCell(item, key);
					this.selectedCells = [
						{
							securedId: id,
							userId: +item.id,
							fullName: item.fullName,
							index: this.indexActif,
							date: key,
							indexOfItem: indexOfItem,
							dataAdd: item,
							locations: headerLocation.locations,
							tsDay: tsDay
						}
					];
					this.itemPersist = [...this.itemPersist, [item]];
					this.dataActifs = this.getUniqueCombination([...this.dataActifs, ...this.selectedCells]);
				}
			}

			let data = [...this.setAllTimesheets];
			if (this.selectedCells.length < 2) {
				let item = data.slice(this.selectedCells[0].indexOfItem, this.selectedCells[0].indexOfItem + 1);
				let id = generateSecureId(`${item[0].id + item[0].fullName + item[0].departmentName + item[0].functionName + key}`);
				this.activeClass(id);
			} else {
				let item = [];
				if (this.selectedCells[0].indexOfItem < this.selectedCells[1].indexOfItem + 1) {
					item = data.slice(this.selectedCells[0].indexOfItem, this.selectedCells[1].indexOfItem + 1);
				} else {
					item = data.slice(this.selectedCells[1].indexOfItem, this.selectedCells[0].indexOfItem + 1);
				}
				this.itemPersist = [...this.itemPersist, item];
				if (this.itemPersist[this.itemPersist.length - 2] !== undefined) {
					for (let i = 0; i < this.itemPersist[this.itemPersist.length - 2].length; i++) {
						for (let j = 0; j < this.dateSelectedCells.length; j++) {
							let key = this.dateSelectedCells[j];
							let id = generateSecureId(
								`${
									this.itemPersist[this.itemPersist.length - 2][i].id +
									this.itemPersist[this.itemPersist.length - 2][i].fullName +
									this.itemPersist[this.itemPersist.length - 2][i].departmentName +
									this.itemPersist[this.itemPersist.length - 2][i].functionName +
									key
								}`
							);
							if (document.getElementById(id) !== null) {
								this.resetClass(id);
							}
						}
					}
					setTimeout(() => {
						this.setDataActionAnim(this.dataActifsActions);
					}, 0);
				}
				this.setDataActionAnim(this.dataActifsActions);
				let dayStart = +this.selectedCells[0].date.split`/`[0];
				let dayEnd = +this.selectedCells[1].date.split`/`[0];
				let monthYear = this.selectedCells[0].date.substr(2);
				// vierifié les autres event avant le click persist et initialisé la valeur
				if (this.dateSelectedCells.length !== 0) {
					for (let i = 0; i < item.length; i++) {
						for (let j = 0; j < this.dateSelectedCells.length; j++) {
							let key = this.dateSelectedCells[j];
							let id = generateSecureId(`${item[i].id + item[i].fullName + item[i].departmentName + item[i].functionName + key}`);
							if (document.getElementById(id) !== null) {
								this.resetClass(id, key, item[i].tsDays);
							}
						}
					}
				}
				//change color primary quand on click sur ctrl and que les données ne sont pas encore vide suivi du shift
				for (let i = 0; i < this.arraysId.length; i++) {
					let id = this.arraysId[i];
					this.activeClass(id);
				}
				this.dateSelectedCells = [];
				if (dayStart < dayEnd) {
					while (dayStart <= dayEnd) {
						this.dateSelectedCells.push(dayStart.toString().padStart('2', '0') + monthYear);
						dayStart++;
					}
				} else {
					while (dayStart >= dayEnd) {
						this.dateSelectedCells.push(dayEnd.toString().padStart('2', '0') + monthYear);
						dayEnd++;
					}
				}
				for (let i = 0; i < item.length; i++) {
					for (let j = 0; j < this.dateSelectedCells.length; j++) {
						let key = this.dateSelectedCells[j];
						let id = generateSecureId(`${item[i].id + item[i].fullName + item[i].departmentName + item[i].functionName + key}`);
						const color = document.getElementById(id).style.color;
						const classActive = document.getElementById(id).children[0].classList;
						let isShowBarStatus = this.isShowBarStatus(key, item.tsDays);
						let isValidationStatus = this.isValidationStatus(key, item.tsDays);
						if (this.isProduction && isShowBarStatus && isValidationStatus) {
							classActive.remove('content-item-cell-is-prod');
						}
						// if (this.isProduction && !isShowBarStatus && isValidationStatus) {
						// 	classActive.remove('content-item-cell-is-to-validate');
						// }

						document.getElementById(id) !== null && (document.getElementById(id).style.backgroundColor = '#00a09c');
						!this.getWorked(key, item[i].tsDays)
							? (document.getElementById(id).style.color = color)
							: ((document.getElementById(id).style.color = '#ffff'),
							  (document.getElementById(id).style.fontWeight = 'bold'),
							  document.getElementById(id).querySelectorAll('svg > *')[0] !== undefined &&
									(document.getElementById(id).querySelectorAll('svg > *')[0].style.fill = '#fff'));
					}
				}
			}

			let allUser = [...new Set(this.itemPersist[this.itemPersist.length - 1])];
			let dateOnly = [...new Set(this.dateSelectedCells)];
			let arrays = [];
			for (let i = 0; i < allUser.length; i++) {
				for (let j = 0; j < dateOnly.length; j++) {
					let id = generateSecureId(`${allUser[i].id + allUser[i].fullName + allUser[i].departmentName + allUser[i].functionName + dateOnly[j]}`);
					let tsDay = this.getTsDayOneCell(allUser[i], dateOnly[j]);
					arrays.push({
						securedId: id,
						userId: +allUser[i].id,
						fullName: allUser[i].fullName,
						date: dateOnly[j],
						dataAdd: item,
						locations: headerLocation.locations,
						tsDay: tsDay
					});
				}
			}
			this.dataActifs = this.getUniqueCombination([...this.ctrlDataPersist, ...this.dataArraysStores, ...arrays]);
		},
		handleAllActionPlanning(event, item, key, headerLocation) {
			if (this.showMenu) {
				this.showMenu = false;
			}
			if (this.showMenuMultiple) {
				this.showMenuMultiple = false;
			}
			setTimeout(() => {
				if (event.ctrlKey) {
					this.handleCtrlClick(event, item, key, headerLocation);
				} else if (event.shiftKey) {
					this.handleShiftWithClick(event, item, key, headerLocation);
				} else {
					this.handleClickOnly(event, item, key, headerLocation);
				}
			}, 1);
		},
		handleSendDataPlanningToModal() {
			let data = [...this.dataActifs, ...this.dataActifsUpdate];
			this.$emit('setDataSizeModalPlanning', data);
		},
		setDateDefaultSelect(_date) {
			this.$emit('changeDateDefaultSelect', _date);
			this.dataActifs = [];
			this.selectedCells = [];
			this.indexActif = 0;
			this.handleSendDataPlanningToModal();
		},
		showContextMenuOne(event, item, worked) {
			//context menu change position
			this.showMenu = false;
			this.showMenu = true;
			this.showMenuMultiple = false;
			let screenWidth = window.innerWidth;
			let screenHeight = window.innerHeight;
			event.preventDefault();
			const menuWidth = 200;
			const menuHeight = 200;
			const clickX = event.clientX;
			const clickY = event.clientY;
			const maxLeft = screenWidth - menuWidth;
			this.menuY = event.clientY;
			const maxTop = screenHeight - menuHeight;
			if (clickX > maxLeft) {
				this.menuX = Math.max(clickX - menuWidth, 0);
			} else {
				this.menuX = clickX;
			}
			if (clickY > maxTop) {
				this.menuY = Math.max(clickY - menuHeight, 0);
			} else {
				this.menuY = clickY;
			}
			this.showMenu = true;
			this.selectedItem = worked;
			setTimeout(() => {
				if (this.dataActifs[0] !== undefined) {
					this.activeClass(this.dataActifs[0].securedId);
				}
			}, 1);
		},
		showContextMenuMultiple(event, item, worked) {
			//context menu change position
			this.showMenuMultiple = false;
			this.showMenuMultiple = true;
			this.showMenu = false;
			let screenWidth = window.innerWidth;
			let screenHeight = window.innerHeight;
			event.preventDefault();
			const menuWidth = 200;
			const menuHeight = 150;
			const clickX = event.clientX;
			const clickY = event.clientY;
			const maxLeft = screenWidth - menuWidth;
			this.menuY = event.clientY;
			const maxTop = screenHeight - menuHeight;
			if (clickX > maxLeft) {
				this.menuX = Math.max(clickX - menuWidth, 0);
			} else {
				this.menuX = clickX;
			}
			if (clickY > maxTop) {
				this.menuY = Math.max(clickY - menuHeight, 0);
			} else {
				this.menuY = clickY;
			}
			this.showMenuMultiple = true;
			this.selectedItem = worked;
			setTimeout(() => {
				for (let i = 0; i < this.dataActifs.length; i++) {
					this.activeClass(this.dataActifs[i].securedId);
				}
			}, 1);
		},
		hideContextMenu() {
			this.showMenu = false;
			this.showMenuMultiple = false;
			this.selectedItem = null;
			this.dataActifs = [];
		},
		isValidated(item) {
			if (item !== null) {
				let status = +item[0].tsDays[0].validated;
				if (!this.isShowSolveAndUpdate(item) && (status === 0 || status === 4 || status === 16) && this.isProduction) {
					this.isValid = true;
					this.isReject = false;
					return true;
				}
				if (this.currentValidationLevel === 1 && status === 2 && this.isProduction) {
					this.isReject = true;
					this.isValid = false;
					return true;
				}
				if ((this.currentValidationLevel === 1 || this.currentValidationLevel === 2) && status === 1 && this.isProduction) {
					this.isReject = true;
					this.isValid = false;
					return true;
				}
				if (this.currentValidationLevel === 2 && status === 2 && this.isProduction) {
					this.isReject = false;
					this.isValid = true;
					return true;
				}
				if (status === 1 && this.isProduction) {
					this.isReject = false;
					this.isValid = true;
					return true;
				}
				if ((status !== 0 || status !== 4 || status !== 16) && this.isProduction === false) {
					if ((status === 2 || status === 8) && this.currentValidationLevel === 0) {
						this.isReject = false;
						this.isValid = false;
						return false;
					}
					if (status === 2 && this.currentValidationLevel === 1) {
						this.isReject = true;
						this.isValid = false;
						return false;
					}
					if (status === 8 && this.currentValidationLevel === 1) {
						this.isReject = false;
						this.isValid = false;
						return false;
					}
					if (status === 8 && this.currentValidationLevel === 2) {
						this.isReject = true;
						this.isValid = false;
						return false;
					}
					if (status === 2 && this.currentValidationLevel === 2) {
						this.isReject = true;
						this.isValid = false;
						return false;
					}
					return true;
				}
				if ((status === 0 || status === 4 || status === 16) && this.isProduction === false) {
					this.isReject = false;
					this.isValid = true;
					return true;
				}
				this.isValid = false;
				this.isReject = false;
				return false;
			}
			return false;
		},
		isNotValitedItem(item) {
			if (item !== null) {
				let status = +item[0].tsDays[0].validated;
				if (status === 0 || status === 4 || status === 16) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		},
		checkIsSelectMultipleAndOnly() {
			// let isChecked = () => {
			// 	let planning = this.dataActifs;
			// 	let arrays = [];
			// 	for (let i = 0; i < planning.length; i++) {
			// 		let userId = +planning[i].userId;
			// 		let key = planning[i].date;
			// 		let _arrays = planning[i].dataAdd;
			// 		let _data = [...this.getDataOnCell(key, _arrays, userId)]
			// 			.map((item) => {
			// 				let _item = item.tsDays;
			// 				if (_item.length !== 0) {
			// 					return _item.map((item) => {
			// 						if (item.tsDayDetails !== undefined && item.tsDayDetails.length > 0) {
			// 							return true;
			// 						}
			// 						return false;
			// 					});
			// 				}
			// 			})
			// 			.flat();
			// 		arrays.push(..._data);
			// 	}
			// 	let checkArrays = arrays.some((item) => item === true);
			// 	if (arrays.length !== 0 && checkArrays) {
			// 		return true;
			// 	}
			// 	return false;
			// };
			// return isChecked;
			let status = null;
			if (this.selectedItem !== null) {
				status = this.selectedItem[0].tsDays[0].validated;
			}
			if (this.isProduction && this.currentValidationLevel !== 0) {
				if (this.currentValidationLevel === 1 || this.currentValidationLevel === 2) {
					if (status === 1 || status === 2 || status === 8) {
						return false;
					}
				}
				return true;
			}
			if (this.isProduction && this.currentValidationLevel === 0) {
				if (status === 1 || status === 2 || status === 8) {
					return false;
				}
				return true;
			}
			if (this.isProduction === false && this.currentValidationLevel === 0) {
				if (status === 1 || status === 2 || status === 8) {
					return false;
				}
				return true;
			}
			if (this.isProduction === false && this.currentValidationLevel !== 0) {
				if (status === 1 || status === 2 || status === 8) {
					return false;
				}
				return true;
			} else {
				return false;
			}
		},
		checkIsSelectMultipleAndOnlyReject() {
			let planning = this.dataActifs;
			let arrays = [];
			for (let i = 0; i < planning.length; i++) {
				let userId = +planning[i].userId;
				let key = planning[i].date;
				let _arrays = planning[i].dataAdd;
				let _data = [...this.getDataOnCell(key, _arrays, userId)]
					.map((item) => {
						let _item = item.tsDays;
						if (_item.length !== 0) {
							return _item.map((item) => {
								if (item.tsDayDetails !== undefined && item.tsDayDetails.length > 0) {
									return item.tsDayDetails;
								}
								return [];
							});
						}
					})
					.flat(Infinity);
				arrays.push(..._data);
			}
			if (arrays.length !== 0) {
				let data = arrays.map((item) => {
					if (+item.activity.id === 0 && item.category === 0) {
						return true;
					}
					return false;
				});
				let response = data.some((status) => status === true);
				if (response) {
					return true;
				}
				return false;
			}
			return false;
		},
		checkIsSelectMultipleAndOnlyValidate() {
			let planning = this.dataActifs;
			let arrays = [];
			for (let i = 0; i < planning.length; i++) {
				let userId = +planning[i].userId;
				let key = planning[i].date;
				let _arrays = planning[i].dataAdd;
				let _data = [...this.getDataOnCell(key, _arrays, userId)]
					.map((item) => {
						let _item = item.tsDays;
						if (_item.length !== 0) {
							return _item.map((item) => {
								if (item.tsDayDetails !== undefined && item.tsDayDetails.length > 0) {
									return item.tsDayDetails;
								}
								return [];
							});
						}
					})
					.flat(Infinity);
				arrays.push(..._data);
			}
			if (arrays.length !== 0) {
				let data = arrays.map((item) => {
					if (+item.activity.id === 0 && item.category === 0) {
						return true;
					}
					return false;
				});
				let response = data.some((status) => status === true);
				if (response) {
					return true;
				}
				return false;
			}
			return false;
		},
		isShowMenuItem(item) {
			if (item !== null) {
				let data = item[0].tsDays[0].tsDayDetails;
				if (data !== undefined) {
					if (data.length !== 0) {
						return true;
					}
					return false;
				}
			}
		},
		isCheckTsDetails(item) {
			if (item !== undefined) {
				let data = item.map((item) => {
					if (+item.activity.id === 0 && item.category === 0) {
						return true;
					}
					return false;
				});
				let response = data.some((status) => status === true);
				if (response) {
					return true;
				}
				return false;
			}
			return false;
		},
		async validateAllTsDays(item) {
			let data = [];
			if (this.isProduction && this.currentValidationLevel === 1) {
				data = item.tsDays
					.map((item) => {
						if (item.tsDayDetails.length !== 0) {
							return {
								id: item.id,
								validated: item.validated,
								tsDayDetails: item.tsDayDetails,
								solveStatus: this.isCheckTsDetails(item.tsDayDetails)
							};
						}
						return undefined;
					})
					.filter((item) => item !== undefined && item.validated !== 0 && item.validated !== 2 && item.validated !== 8 && item.solveStatus !== true);
			}
			if (this.isProduction && this.currentValidationLevel === 2) {
				data = item.tsDays
					.map((item) => {
						if (item.tsDayDetails.length !== 0) {
							return {
								id: item.id,
								validated: item.validated,
								tsDayDetails: item.tsDayDetails,
								solveStatus: this.isCheckTsDetails(item.tsDayDetails)
							};
						}
						return undefined;
					})
					.filter((item) => item !== undefined && item.validated !== 0 && item.validated !== 8 && item.solveStatus !== true);
			}
			if (data.length !== 0) {
				let tsDaysId = data.map((item) => +item.id);
				this.$emit('validateAllTsDays', tsDaysId);
			}
			this.hideContextMenu();
		},
		checkValidatedAll(item) {
			let data = [];
			if (this.isProduction && this.currentValidationLevel === 1) {
				data = item.tsDays
					.map((item) => {
						if (item.tsDayDetails.length !== 0) {
							return {
								id: item.id,
								validated: item.validated,
								tsDayDetails: item.tsDayDetails,
								solveStatus: this.isCheckTsDetails(item.tsDayDetails)
							};
						}
						return undefined;
					})
					.filter((item) => item !== undefined && item.validated !== 0 && item.validated !== 2 && item.validated !== 8 && item.solveStatus !== true);
			}
			if (this.isProduction && this.currentValidationLevel === 2) {
				data = item.tsDays
					.map((item) => {
						if (item.tsDayDetails.length !== 0) {
							return {
								id: item.id,
								validated: item.validated,
								tsDayDetails: item.tsDayDetails,
								solveStatus: this.isCheckTsDetails(item.tsDayDetails)
							};
						}
						return undefined;
					})
					.filter((item) => item !== undefined && item.validated !== 0 && item.validated !== 8 && item.solveStatus !== true);
			}
			if (data.length !== 0) {
				let validatedAllLevel_One = data.some((status) => status.validated === 1 || status.validated === 4);
				let validatedAllLevel_Two = data.some((status) => status.validated === 2 || status.validated === 4);
				if (this.isProduction && this.currentValidationLevel === 1 && validatedAllLevel_One) {
					return false;
				}
				if (this.isProduction && this.currentValidationLevel === 2 && validatedAllLevel_Two) {
					return false;
				}
			} else {
				return true;
			}
		},
		async onMenuItemClick(option) {
			if (option === 1) {
				let item = this.selectedItem[0];
				let headerLocation = this.tsfields.find((_item) => _item.key === moment(item.tsDays[0].date).format('DD/MM/YYYY'));
				let id = generateSecureId(
					`${item.tsDays[0].id + item.tsDays[0].fullName + item.tsDays[0].departmentName + item.tsDays[0].functionName + item.tsDays[0].date}`
				);
				let date = moment(item.tsDays[0].date).format('DD/MM/YYYY');
				let data = [
					{
						securedId: id,
						userId: +item.tsDays[0].userId,
						fullName: item.fullName,
						date: date,
						dataAdd: item,
						locations: headerLocation.locations,
						tsDay: item.tsDays[0]
					}
				];
				let dataToSend = [];
				if (this.dataActifs.length !== 0) {
					dataToSend = [...this.dataActifs, ...this.dataActifsUpdate];
				} else {
					dataToSend = data;
				}
				let _data = dataToSend.filter((item) => item.tsDay !== undefined);
				this.$emit('showModalAdvancedEdition', _data);
				setTimeout(() => {
					let data = [...this.dataActifs, ...this.dataActifsUpdate];
					if (data.length !== 0) {
						this.setDataActionAnim(data);
					}
				}, [1]);
				return;
			}
			if (option === 2) {
				let item = this.selectedItem[0];
				let headerLocation = this.tsfields.find((_item) => _item.key === moment(item.tsDays[0].date).format('DD/MM/YYYY'));
				let id = generateSecureId(
					`${item.tsDays[0].id + item.tsDays[0].fullName + item.tsDays[0].departmentName + item.tsDays[0].functionName + item.tsDays[0].date}`
				);
				let date = moment(item.tsDays[0].date).format('DD/MM/YYYY');
				let data = [
					{ securedId: id, userId: +item.tsDays[0].userId, fullName: item.fullName, date: date, data: item, locations: headerLocation.locations }
				];
				this.dataActifsUpdate = data;
				this.$emit('showModalPlanningContextUpdate', data);
				setTimeout(() => {
					let data = [...this.dataActifs];
					if (data.length !== 0) {
						this.setDataActionAnim(data);
					}
				}, [1]);
				return;
			}
			if (option === 2.1) {
				let item = this.selectedItem[0].tsDays;
				this.$emit('validateTsDays', item);
				setTimeout(() => {
					let data = [...this.dataActifs, ...this.dataActifsUpdate];
					if (data.length !== 0) {
						this.setDataActionAnim(data);
					}
				}, [1]);
				return;
			}
			if (option === 2.2) {
				this.successModalRejectedTsDay = true;
				this.rejectData = this.selectedItem[0].tsDays;
				setTimeout(() => {
					let data = [...this.dataActifs, ...this.dataActifsUpdate];
					if (data.length !== 0) {
						this.setDataActionAnim(data);
					}
				}, [1]);
				return;
			}
			if (option === 3) {
				this.copyItem = this.selectedItem[0].tsDays;
			}
			if (option === 4) {
				let item = this.selectedItem[0].tsDays;
				this.showDetailData = { ...item[0], fullName: this.selectedItem[0].fullName };
				this.showDetailModalCell = true;
				setTimeout(() => {
					let data = [...this.dataActifs, ...this.dataActifsUpdate];
					if (data.length !== 0) {
						this.setDataActionAnim(data);
					}
				}, [1]);
			}
			if (option === 5) {
				let item = this.selectedItem[0];
				let headerLocation = this.tsfields.find((_item) => _item.key === moment(item.tsDays[0].date).format('DD/MM/YYYY'));
				let id = generateSecureId(
					`${item.tsDays[0].id + item.tsDays[0].fullName + item.tsDays[0].departmentName + item.tsDays[0].functionName + item.tsDays[0].date}`
				);
				let date = moment(item.tsDays[0].date).format('DD/MM/YYYY');
				let payload = [
					{ securedId: id, userId: +item.tsDays[0].userId, fullName: item.fullName, date: date, data: item, locations: headerLocation.locations }
				];
				this.$emit('replacement', payload);
				setTimeout(() => {
					let data = [...this.dataActifs, ...this.dataActifsUpdate];
					if (data.length !== 0) {
						this.setDataActionAnim(data);
					}
				}, [1]);
				return;
			}
			if (option === 6) {
				let isPlanningData = [...this.dataActifs, ...this.dataActifsUpdate];
				if (isPlanningData.length > 1) {
					this.$emit('deleteItemPlanning', isPlanningData);
				} else {
					this.$emit('deleteItemPlanning', this.selectedItem);
				}
				// setTimeout(() => {
				// 	let data = [...this.dataActifs, ...this.dataActifsUpdate];
				// 	if (data.length !== 0) {
				// 		this.setDataActionAnim(data);
				// 	}
				// }, [1]);
				return;
			}
			if (option === 8) {
				let item = this.selectedItem[0].tsDays;
				let isPlanningData = [...this.dataActifs, ...this.dataActifsUpdate]
					.map((item) => item.tsDay)
					.filter((item) => item !== undefined)
					.filter((item) => item.validated === 0);
				if (isPlanningData.length !== 0) {
					let data = [...this.dataActifs, ...this.dataActifsUpdate];
					this.$emit('sendForValidationPlanning', isPlanningData);
					setTimeout(() => {
						this.setDataActionAnim(data);
					}, [1]);
				} else {
					this.$emit('sendForValidationPlanning', item);
				}
				setTimeout(() => {
					let data = [...this.dataActifs, ...this.dataActifsUpdate];
					if (data.length !== 0) {
						this.setDataActionAnim(data);
					}
				}, [1]);
				return;
			}
			if (option === 9) {
				let isPlanningData = [];
				if (this.isProduction && this.currentValidationLevel === 1) {
					isPlanningData = [...this.dataActifs, ...this.dataActifsUpdate]
						.map((item) => item.tsDay)
						.filter((item) => item !== undefined)
						.map((item) => {
							if (item.tsDayDetails.length !== 0) {
								return {
									id: item.id,
									validated: item.validated,
									tsDayDetails: item.tsDayDetails,
									solveStatus: this.isCheckTsDetails(item.tsDayDetails)
								};
							}
							return undefined;
						})
						.filter(
							(item) => item !== undefined && item.validated !== 0 && item.validated !== 2 && item.validated !== 8 && item.solveStatus !== true
						);
				}
				if (this.isProduction && this.currentValidationLevel === 2) {
					isPlanningData = [...this.dataActifs, ...this.dataActifsUpdate]
						.map((item) => item.tsDay)
						.filter((item) => item !== undefined)
						.map((item) => {
							if (item.tsDayDetails.length !== 0) {
								return {
									id: item.id,
									validated: item.validated,
									tsDayDetails: item.tsDayDetails,
									solveStatus: this.isCheckTsDetails(item.tsDayDetails)
								};
							}
							return undefined;
						})
						.filter((item) => item !== undefined && item.validated !== 0 && item.validated !== 8 && item.solveStatus !== true);
				}
				if (isPlanningData.length !== 0) {
					let tsDaysId = isPlanningData.map((item) => +item.id);
					this.$emit('validateAllTsDays', tsDaysId);
				}
				return;
			}
		},
		checkSelectAllPlanningToValidates() {
			let isPlanningData = [];
			if (this.isProduction && this.currentValidationLevel === 1) {
				isPlanningData = [...this.dataActifs, ...this.dataActifsUpdate]
					.map((item) => item.tsDay)
					.filter((item) => item !== undefined)
					.map((item) => {
						if (item.tsDayDetails.length !== 0) {
							return {
								id: item.id,
								validated: item.validated,
								tsDayDetails: item.tsDayDetails,
								solveStatus: this.isCheckTsDetails(item.tsDayDetails)
							};
						}
						return undefined;
					})
					.filter((item) => item !== undefined && item.validated !== 0 && item.validated !== 2 && item.validated !== 8 && item.solveStatus !== true);
			}
			if (this.isProduction && this.currentValidationLevel === 2) {
				isPlanningData = [...this.dataActifs, ...this.dataActifsUpdate]
					.map((item) => item.tsDay)
					.filter((item) => item !== undefined)
					.map((item) => {
						if (item.tsDayDetails.length !== 0) {
							return {
								id: item.id,
								validated: item.validated,
								tsDayDetails: item.tsDayDetails,
								solveStatus: this.isCheckTsDetails(item.tsDayDetails)
							};
						}
						return undefined;
					})
					.filter((item) => item !== undefined && item.validated !== 0 && item.validated !== 8 && item.solveStatus !== true);
			}
			if (isPlanningData.length !== 0) {
				return true;
			}
			return false;
		},
		hideModal() {
			this.showDetailModalCell = false;
			this.showDetailData = {};
			setTimeout(() => {
				let data = [...this.dataActifs, ...this.dataActifsUpdate];
				if (data.length !== 0) {
					this.setDataActionAnim(data);
				}
			}, [1]);
		},
		sendAllForValidation(item) {
			let data = item.tsDays.filter((item) => item.validated === 0 || item.validated === 4);
			this.$emit('sendForValidationPlanning', data);
			this.hideContextMenu();
		},
		checkNotSubmitted(item) {
			let data = item.tsDays
				.map((item) => {
					if (item.tsDayDetails.length !== 0) {
						return {
							id: item.id,
							validated: item.validated,
							tsDayDetails: item.tsDayDetails,
							solveStatus: this.isCheckTsDetails(item.tsDayDetails)
						};
					}
					return undefined;
				})
				.filter((item) => item !== undefined && (item.validated === 0 || item.validated === 4));
			if (data.length !== 0) {
				if (this.isProduction === false && this.currentValidationLevel === 0) return false;
				if (this.isProduction && this.currentValidationLevel !== 0) return false;
				if (this.isProduction === false && this.currentValidationLevel !== 0) return false;
				return true;
			}
			return true;
		},
		checkNotSubmittedNotSubmitted(item) {
			let _data = item.tsDays.map((item) => ({ validated: item.validated, tsDayDetails: item.tsDayDetails }));
			let data = _data.filter((item) => item.validated !== 0 && item.tsDayDetails.length !== 0);
			if (data.length === 0) {
				return true;
			} else {
				return false;
			}
		},
		onScroll(event) {
			if (event.target.scrollTop) {
				if (Math.ceil(event.target.scrollTop + event.target.clientHeight) >= event.target.scrollHeight) {
					if (!this.isBusy) {
						if (event.target.scrollLeft) {
							this.isBusy = false;
						} else {
							this.isBusy = true;
						}
						let recordOffSet = 1;
						setTimeout(() => {
							this.$emit('getDataFromPagePlanning', recordOffSet);
							this.isBusy = false;
						}, 1200);
					}
				}
			}
		},
		startScrollingLeft(event) {
			event.preventDefault();
			const scrollContainer = this.$refs['my-table'].$el;
			this.isDragging = true;
			this.startX = event.clientX;
			this.scrollLeftStart = scrollContainer.scrollLeft;
		},
		stopScrollingLeft(event) {
			event.preventDefault();
			const scrollContainer = this.$refs['my-table'].$el;
			scrollContainer.removeEventListener('mousedown', this.startScrollingLeft);
		},
		detectDirection(event) {
			event.preventDefault();
			if (this.isDragging) {
				const mouseX = event.clientX;
				const scrollContainer = this.$refs['my-table'].$el;
				if (mouseX > this.startX) {
					scrollContainer.scrollLeft = this.scrollLeftStart + (mouseX - this.startX);
				} else if (mouseX < this.startX) {
					scrollContainer.scrollLeft = this.scrollLeftStart - (this.startX - mouseX);
				}
			}
		},
		stopDrag() {
			this.isDragging = false;
		}
	}
};
</script>
<style scoped lang="scss">
.cursor-active {
	.show-edit-flag {
		display: none;
		// transition: opacity 200ms ease-in-out;
		// opacity: 0%;
	}
	&:hover {
		.show-edit-flag {
			display: block;
			// opacity: 100%;
		}
	}
}
.middle {
	vertical-align: middle;
}
.show-hover:hover {
	background-color: #cad0d7;
	border-radius: 5px;
	cursor: pointer;
}
.edit-background {
	/* vertical-align: middle; */
	background-color: #f3f4f5;
}
.init-background {
	/*vertical-align: middle;*/
	background-color: #ffff;
}

.content-item-cell-is-prod {
	width: 95px;
	height: 60px;
	border-radius: 4px;
	gap: 2px;
	border: 2px solid #ea4e2c;
}
.content-item-cell-is-to-validate {
	width: 95px;
	height: 60px;
	border-radius: 4px;
	gap: 2px;
	border: 2px solid #cd9a16;
}
.content-item-cell-is-prod:hover {
	border: none;
}
.content-item-cell-is-to-validate:hover {
	border: none;
}
.content-item-cell-fixed {
	display: flex;
	justify-content: start;
	width: 150px;
	height: 70px;
	border-radius: 2px;
	gap: 2px;
}
.is-not-validate {
	border: 1px solid red !important;
}
.sticky-column-body-left {
	position: sticky;
	left: 0;
	z-index: 1;
	background-color: #fff;
}
.sticky-column-body-right {
	position: sticky;
	right: 0;
	z-index: 1;
	background-color: rgba(226, 230, 235, 0.85);
	border-left: 1px solid #06263e !important;
	height: auto;
	/* border: 0.005rem solid rgba(226, 230, 235, 0.85); */
}
.sticky-column-body-right-init {
	position: sticky;
	right: 0;
	z-index: 1;
	background-color: #fff;
	width: 10xp;
}
.hover-not-empty:hover {
	background-color: hsla(206, 96%, 90%, 0.5);
}
.hover-is-empty:hover {
	background-color: rgba(71, 199, 191, 0.5);
}
.context-menu {
	position: fixed;
	z-index: 2;
	background-color: #fff;
	border: 1px solid #ccc;
	padding: 8px;
	border-radius: 10px;
}
.context-menu div {
	cursor: pointer;
	padding: 8px;
}
.menu-context {
	height: 30px;
}
.menu-context:hover {
	background-color: rgba(71, 199, 191, 0.5);
	border-radius: 10px;
}

@keyframes c4 {
	to {
		clip-path: inset(0 -1ch 0 0);
	}
}
.loading,
.end-of-list {
	color: rgba(6, 38, 62, 0.84);
	font-weight: 900;
	font-size: 0.95rem;
}
.bars-5 {
	width: 20px;
	height: 10px;
	aspect-ratio: 0.75;
	--c: no-repeat linear-gradient(rgba(6, 38, 62, 0.84) 0 0);
	background: var(--c) 0% 100%, var(--c) 50% 100%, var(--c) 100% 100%;
	background-size: 20% 65%;
	animation: b5 1s infinite linear;
}
@keyframes b5 {
	20% {
		background-position: 0% 50%, 50% 100%, 100% 100%;
	}
	40% {
		background-position: 0% 0%, 50% 50%, 100% 100%;
	}
	60% {
		background-position: 0% 100%, 50% 0%, 100% 50%;
	}
	80% {
		background-position: 0% 100%, 50% 100%, 100% 0%;
	}
}
.overflow-hidden {
	overflow: hidden;
}
::-webkit-scrollbar {
	width: 5px;
	background: #eee;
}
::-webkit-scrollbar:horizontal {
	height: 10px;
}
::-webkit-scrollbar-button {
	width: 5px;
	height: 5px;
	background: #666;
}
::-webkit-scrollbar-piece {
	background: #eee;
}
::-webkit-scrollbar-piece:start {
	background: #eee;
}
::-webkit-scrollbar-thumb {
	background: #333;
}
::-webkit-scrollbar-corner {
	background: #333;
}
@keyframes sonar {
	0% {
		transform: scale(0.6);
		opacity: 1;
	}
	100% {
		transform: scale(1.2);
		opacity: 0;
	}
}
@keyframes pulse {
	0% {
		transform: scale(1);
	}
	20% {
		transform: scale(0.9);
	}
	50% {
		transform: scale(1);
	}
	80% {
		transform: scale(0.6);
	}
	100% {
		transform: scale(1);
	}
}
.status-not-submitted {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #fcede9;
	border: 1px solid #ea4e2c;
	animation: pulse 1.5s infinite;
}
.status-not-submitted:after {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #ea4e2c;
	animation: sonar 1.5s infinite;
}
.status-info {
	width: 10px;
	height: 10px;
	border-radius: 2px;
	background-color: #e7f2ff;
	border: 1px solid #225cbd;
}
.status-pending {
	width: 10px;
	height: 10px;
	border-radius: 2px;
	background-color: #fbf5e5;
	border: 1px solid #cd9a16;
}
.status-validated {
	width: 10px;
	height: 10px;
	border-radius: 2px;
	background-color: #ecf6e8;
	border: 1px solid #3c7442;
}
.status-refused {
	width: 10px;
	height: 10px;
	border-radius: 2px;
	background-color: #ffb0b0;
	border: 1px solid #980000;
}
.status-sent {
	width: 10px;
	height: 10px;
	border-radius: 2px;
	background-color: #d5d6d7;
	border: 1px solid #687887;
}

.header-flag-status {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #e4dfec;
	border-radius: 4px;
	padding: 8px 0px 8px 0px;
	color: #06263e;
	width: 142px;
	height: 21px;
	font-size: 12px;
	gap: 4px;
}
.btn-custom-check {
	display: flex;
	justify-content: center;
	background-color: #ffff;
	height: 25px;
	font-size: 0.8rem;
	width: auto;
}
.btn-custom-check:hover {
	color: #00a09c;
}
.transition-collapse-rt-lf {
	animation-duration: 0.2s;
	animation-name: slideInRightToleft;
}
.transition-collapse-lf-rt {
	animation-duration: 0.2s;
	animation-name: slideInleftToRight;
}

@keyframes slideInRightToleft {
	from {
		margin-left: 100%;
	}

	to {
		margin-left: 0%;
	}
}
@keyframes slideInleftToRight {
	from {
		margin-left: 0%;
	}

	to {
		margin-left: 100%;
	}
}
.custom-option {
	display: flex !important;
	height: 60px !important;
	padding: 10px !important;
	font-size: 14px;
	align-items: center;
}
.btn-custom {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}
.w-18 {
	width: 18%;
}
.w-82 {
	width: 82%;
}

.divider-vertical {
	border-left: 2px solid #ffffff;
}
.gap-10 {
	gap: 10px;
}
.card-gray {
	padding: 16px;
	background: #f5f7f9;
}
.label-select {
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
	height: 30px;
}
.table-body {
}
</style>
<style lang="scss">
.active-filter-custom {
	.vue-treeselect {
		.vue-treeselect__control {
			height: 26px !important;
			border-radius: 8px !important;
			border: 1px solid #00a09c !important;
			.vue-treeselect__value-container {
				display: contents;
				.vue-treeselect__placeholder,
				.vue-treeselect__single-value {
					top: -4px;
				}
				.vue-treeselect__multi-value {
					.vue-treeselect__input-container {
						.vue-treeselect__input {
							height: 10px !important;
						}
					}
				}
			}
			.vue-treeselect__x-container {
				margin-right: 2px;
			}
		}
	}
}
</style>
