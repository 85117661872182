var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: _vm.FormMSG(73, "Add crew member"),
        "header-class": "header-class-modal-doc-package",
        size: "xl",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "ok-only": _vm.searchExisting === false,
      },
      on: {
        ok: function ($event) {
          $event.preventDefault()
          return _vm.handleOk.apply(null, arguments)
        },
        show: _vm.handleShow,
        cancel: _vm.handleCancel,
        hidden: _vm.emitEventClose,
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ ok, cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex justify-content-end align-items-center",
                },
                [
                  _vm.searchExisting === true
                    ? _c(
                        "b-button",
                        {
                          staticClass: "w-138-px mr-3",
                          staticStyle: { "margin-top": "5px" },
                          attrs: {
                            size: "md",
                            variant: "custom-outline-gray",
                            disabled: _vm.loadingSubmit,
                            block: "",
                          },
                          on: { click: cancel },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t" +
                              _vm._s(_vm.FormMSG(43, "Cancel")) +
                              "\n\t\t\t"
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px",
                      attrs: {
                        size: "md",
                        variant:
                          _vm.searchExisting === false
                            ? "custom-outline-gray"
                            : "primary",
                        disabled: _vm.loadingSubmit,
                        block: "",
                      },
                      on: { click: ok },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-center align-items-center",
                        },
                        [
                          _vm.loadingSubmit
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              class: `${_vm.loadingSubmit ? "pl-2" : ""}`,
                              staticStyle: { "margin-top": "1px" },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.searchExisting === false
                                      ? _vm.FormMSG(43, "Cancel")
                                      : _vm.FormMSG(42, "Save")
                                  ) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c(
            "b-col",
            { staticClass: "text-center" },
            [
              _c(
                "b-button-group",
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: `${
                          !_vm.searchExisting ? "" : "outline-"
                        }primary`,
                        disabled: _vm.loadingAddSelected,
                      },
                      on: { click: _vm.toggleSearchExisting },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(27, "Search existing")) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: `${
                          _vm.searchExisting ? "" : "outline-"
                        }primary`,
                        disabled: _vm.loadingAddSelected,
                      },
                      on: { click: _vm.toggleSearchExisting },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(28, "Add no-existing")) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "min-height": "150px" } },
        [
          _c(
            "b-collapse",
            { attrs: { visible: _vm.searchExisting === false } },
            [
              _vm.searchExisting === false
                ? _c(
                    "div",
                    [
                      _c(
                        "b-row",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.FormMSG(
                                        65,
                                        "Type to search"
                                      ),
                                    },
                                    on: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.handleKeyUpEnter.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.filter,
                                      callback: function ($$v) {
                                        _vm.filter = $$v
                                      },
                                      expression: "filter",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    { staticClass: "cursor-pointer" },
                                    [
                                      _c(
                                        "b-input-group-text",
                                        { staticClass: "btn-search" },
                                        [
                                          _vm.filter.length === 0
                                            ? _c(_vm.getLucideIcon("Search"), {
                                                tag: "component",
                                                attrs: {
                                                  color: "#FFFFFF",
                                                  size: 16,
                                                  "stroke-width": 2.5,
                                                },
                                              })
                                            : _c(_vm.getLucideIcon("X"), {
                                                tag: "component",
                                                attrs: {
                                                  color: "#FFFFFF",
                                                  size: 16,
                                                  "stroke-width": 2.5,
                                                },
                                                on: { click: _vm.resetFilter },
                                              }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              ref: "containerInviteNewUser",
                              attrs: { id: "containerInviteNewUser" },
                            },
                            [
                              _vm.$screen.width >= 992
                                ? _c("b-table", {
                                    ref: "preferencesTable",
                                    staticStyle: { "text-align": "left" },
                                    attrs: {
                                      "selected-variant": "primary",
                                      hover: "",
                                      selectable: "",
                                      "select-mode": "single",
                                      responsive: "sm",
                                      id: "preferencesTable",
                                      "sticky-header": "500px",
                                      items: _vm.dataList,
                                      fields: _vm.tableFields,
                                      bordered: "",
                                      striped: "",
                                      small: "",
                                      "head-variant": "dark",
                                      "empty-text": _vm.FormMSG(
                                        500,
                                        "Run a search to show matching records in this list"
                                      ),
                                      "show-empty": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "head(checked)",
                                          fn: function (data) {
                                            return [
                                              _vm.dataList.length > 0
                                                ? _c("b-form-checkbox", {
                                                    attrs: {
                                                      size: "lg",
                                                      value: true,
                                                      "unchecked-value": false,
                                                      disabled:
                                                        _vm.loadingAddSelected,
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.handleChangeSelectAll,
                                                    },
                                                    model: {
                                                      value: _vm.selectAll,
                                                      callback: function ($$v) {
                                                        _vm.selectAll = $$v
                                                      },
                                                      expression: "selectAll",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                        {
                                          key: "cell(checked)",
                                          fn: function ({ item, index }) {
                                            return [
                                              _c(
                                                "div",
                                                [
                                                  _c("b-form-checkbox", {
                                                    attrs: {
                                                      value: true,
                                                      "unchecked-value": false,
                                                      size: "lg",
                                                      disabled:
                                                        _vm.loadingAddSelected,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.handleChangeItemSelect(
                                                          $event,
                                                          item,
                                                          index
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: item.checked,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "checked",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.checked",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1473693286
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "3", offset: "9" } },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "primary",
                                    disabled:
                                      _vm.dataListChecked.length === 0 ||
                                      _vm.loadingAddSelected,
                                    block: "",
                                  },
                                  on: { click: _vm.handleClickAddSelected },
                                },
                                [
                                  _vm.loadingAddSelected
                                    ? _c("b-spinner", { attrs: { small: "" } })
                                    : _vm._e(),
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(
                                          589,
                                          "Add selected to project"
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "b-collapse",
            { attrs: { visible: _vm.searchExisting === true } },
            [
              _vm.searchExisting === true
                ? _c(
                    "div",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(251, "First name"),
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: _vm.FormMSG(
                                        252,
                                        "Enter first name"
                                      ),
                                      state:
                                        !_vm.$v.dataForAddNoExisting.firstName
                                          .$error,
                                    },
                                    model: {
                                      value:
                                        _vm.$v.dataForAddNoExisting.firstName
                                          .$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.dataForAddNoExisting.firstName,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.dataForAddNoExisting.firstName.$model",
                                    },
                                  }),
                                  !_vm.$v.dataForAddNoExisting.firstName
                                    .minLength
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  154,
                                                  "First name must be greater 3 words"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.$v.dataForAddNoExisting.firstName
                                    .required
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  155,
                                                  "First name is required"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(253, "Last name"),
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: _vm.FormMSG(
                                        254,
                                        "Enter last name"
                                      ),
                                      state:
                                        !_vm.$v.dataForAddNoExisting.name
                                          .$error,
                                    },
                                    model: {
                                      value:
                                        _vm.$v.dataForAddNoExisting.name.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.dataForAddNoExisting.name,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.dataForAddNoExisting.name.$model",
                                    },
                                  }),
                                  !_vm.$v.dataForAddNoExisting.name.minLength
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  156,
                                                  "Name must be greater 3 words"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.$v.dataForAddNoExisting.name.required
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  289,
                                                  "Name is required"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: _vm.FormMSG(255, "Email") } },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: _vm.FormMSG(
                                        256,
                                        "Enter email"
                                      ),
                                      state:
                                        !_vm.$v.dataForAddNoExisting.email
                                          .$error,
                                    },
                                    model: {
                                      value:
                                        _vm.$v.dataForAddNoExisting.email
                                          .$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.dataForAddNoExisting.email,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.dataForAddNoExisting.email.$model",
                                    },
                                  }),
                                  !_vm.$v.dataForAddNoExisting.email.email
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  293,
                                                  "Email is invalid"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.$v.dataForAddNoExisting.email.required
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  291,
                                                  "Email is required"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(257, "Department"),
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      options: _vm.allDepartments,
                                      placeholder: _vm.FormMSG(
                                        258,
                                        "Please select"
                                      ),
                                      state:
                                        !_vm.$v.dataForAddNoExisting.department
                                          .$error,
                                    },
                                    on: { change: _vm.handleChangeDepartment },
                                    model: {
                                      value:
                                        _vm.$v.dataForAddNoExisting.department
                                          .$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.dataForAddNoExisting
                                            .department,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.dataForAddNoExisting.department.$model",
                                    },
                                  }),
                                  _vm.$v.dataForAddNoExisting.department.$error
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  296,
                                                  "Department is required"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(259, "Function"),
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      options: _vm.allFunctions,
                                      placeholder: _vm.FormMSG(
                                        258,
                                        "Please select"
                                      ),
                                      state:
                                        !_vm.$v.dataForAddNoExisting.function
                                          .$error,
                                      disabled:
                                        _vm.dataForAddNoExisting.department ===
                                        null,
                                    },
                                    model: {
                                      value:
                                        _vm.$v.dataForAddNoExisting.function
                                          .$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.dataForAddNoExisting.function,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.dataForAddNoExisting.function.$model",
                                    },
                                  }),
                                  _vm.$v.dataForAddNoExisting.function.$error
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  301,
                                                  "Function is required"
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }